import { EXCEPTION_TYPE_LIST, AUTH_CODE } from 'utils/constants';
import moment from 'moment';
import sortBy from 'lodash/collection/sortBy';
import isArray from 'lodash/lang/isArray';
import trim from 'lodash/string/trim';
import { findIndex, indexOf } from 'lodash/array';
import { Map, fromJS } from 'immutable';
import * as platformUtils from 'utils/platform';
import i18n from 'i18n.js';
import omit from 'lodash/object/omit';
import { isNotNullish, isNullish } from 'utils/nullish';
import { isAndroidOrHarmony, PlatformUtil } from 'utils/platform';
import { makeAndroidDetailObjectFromStackLine, makeDetailObjectFromMinidumpStackLine, StackUtil } from 'utils/stack';
import { VersionUtil } from 'utils/version-util';
import isString from 'lodash/lang/isString';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { ze } from 'utils/zhEn';

/** bundleId过滤选项排序用 */
export function compareBundleId(bundleIdA, bundleIdB) {
  const a = bundleIdA || '';
  const b = bundleIdB || '';
  if (a.length !== b.length) {
    return a.length - b.length; // 短的排前面
  }
  return a.localeCompare(b, 'en-US', { caseFirst: 'lower' }); // 小写字母排在前面
}

export const LOG_ABBR_LEVEL_TO_NUMBER = {
  V: 0,
  D: 1,
  I: 2,
  W: 3,
  A: 4,
  E: 5,
  Ex: 6,
};

// 根据level，关键字，是否ide格式处理log
export function handleLog(lines, logLevel, keyWord, isIde) {
  // 获得每行level数组,level最低是0(verbose)
  let levels = lines.map((line) => {
    const words = line.split(/\s+/).filter(x => x);
    const matchedAbbrLevel = words.find(x => isNotNullish(LOG_ABBR_LEVEL_TO_NUMBER[x]));
    const numberLevel = matchedAbbrLevel ? LOG_ABBR_LEVEL_TO_NUMBER[matchedAbbrLevel] : 0;
    return {
      line,
      level: numberLevel,
    };
  });

  // 根据logLevel筛选log
  let curLevel = LOG_ABBR_LEVEL_TO_NUMBER.V;
  switch (logLevel) {
    case 'debug':
      curLevel = LOG_ABBR_LEVEL_TO_NUMBER.D;
      break;
    case 'info':
      curLevel = LOG_ABBR_LEVEL_TO_NUMBER.I;
      break;
    case 'warn':
      curLevel = LOG_ABBR_LEVEL_TO_NUMBER.W;
      break;
    case 'assert':
      curLevel = LOG_ABBR_LEVEL_TO_NUMBER.A;
      break;
    case 'error':
      curLevel = LOG_ABBR_LEVEL_TO_NUMBER.E;
      break;
    case 'exception':
      curLevel = LOG_ABBR_LEVEL_TO_NUMBER.Ex;
      break;
  }

  // 处理keyword
  const word = keyWord ? keyWord.toLowerCase().trim() : '';

  levels = levels.filter((level) => {
    let filterLevel = true;
    if (isIde) {
      filterLevel = level.level >= curLevel;
    } else {
      filterLevel = level.level === curLevel;
    }
    let filterKeyword = true;
    if (word.length > 0 && level.line.toLowerCase().indexOf(word) === -1) {
      filterKeyword = false;
    }
    return filterKeyword && filterLevel;
  });
  return levels;
}

/** 返回decodeURIComponent的结果，如果发生decode错误，直接返回原值 */
export function decodeURIComponentIgnoreError(v) {
  try {
    return decodeURIComponent(v);
  } catch (e) {
    return v;
  }
}

/**
 * 解码SDK encode过的字段值。（比如版本号，机型之类的）
 * SDK encode过的字段需要先将加号换成%20，然后decodeURIComponent
 * */
export function decodeCsSdkEncodedValue(v) {
  if (!v) {
    return v;
  }
  try {
    v = v.replace(/\+/g, '%20');
    return decodeURIComponent(v);
  } catch (e) {
    return v;
  }
}

/**
 *  判断当前路由是否是异常处理的路由
 */
export function isExceptionRoute(exceptionType) {
  return Object.keys(EXCEPTION_TYPE_LIST).indexOf(exceptionType) !== -1;
}

/**
 * 获取crash/anr/error三种异常的类型
 * @param {string} pathname
 */
export function getExceptionType(pathname) {
  if (!pathname) return '';
  let matches = pathname.match(/crash-reporting\/(crashes|blocks|errors)/i);
  if (!matches) {
    matches = pathname.match(/(crash|anr|error)\/apps\/\w+/i);
  }
  if (!matches) return '';
  return matches[1];
}

/**
 * 从路由的pathname中获取到对应异常类型列表
 */
export function getExceptionTypeList(pathname) {
  const exceptionType = getExceptionType(pathname);
  return exceptionType ? EXCEPTION_TYPE_LIST[exceptionType] : [];
}

export function loadScript(src, cb) {
  /* eslint-disable no-underscore-dangle */
  const scriptLoading = window._loading || [];
  !window._loading && (window._loading = scriptLoading);
  // TODO 处理多次加载同一个js的情况

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', src);
  script.addEventListener('load', function handler() {
    cb && cb();
    script.removeEventListener('load', handler);
  });
  document.head.appendChild(script);
}

// 判断是否是空对象
export function isEmptyObject(value) {
  // undefined or null would return true
  return !value || Object.keys(value).length === 0;
}

// 检查数组中是否包含某一个值,用法如contains(["foo", "bar"], "baz"); // => false
export function isContains(arr, value) {
  // const contains = (() =>
  //   Array.prototype.includes
  //     ? (arr, value) => arr.includes(value)
  //     : (arr, value) => arr.some((el) => el === value))();
  // return contains(arr, value);
  if (Array.prototype.includes) {
    return arr.includes(value);
  } else {
    return arr.some((el) => el === value);
  }
}

// 数字加千分号,也适合小数
/** @deprecated 实现有问题，改用format-number.js里的formatEnNumber */
export function formatNum(num) {
  if (num && num !== -1) {
    const parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } else {
    return '0';
  }
}

/**
 * 给所有的事件监听器添加阻止冒泡和默认行为的逻辑
 */
export function noopEventHandler(handler, event) {
  event.stopPropagation();
  event.preventDefault();
  handler(event);
}

/*
 *
 */
export function isWeixin() {
  return /micromessenger(\/[\d\.]+)*/.test(navigator.userAgent.toLowerCase());
}

export function isQQ() {
  const ua = navigator.userAgent.toLowerCase();
  return (/qzone\//.test(ua) || /qq\/(\/[\d\.]+)*/.test(ua));
}

// 把接口返回的-1转化为0
export function convertToDisplay(num) {
  return `${num}` === '-1' ? 0 : num;
}

// 获取比例值
export function getRate(a, b, fix) {
  if (a < 0 || b <= 0) {
    return '-';
  }
  const rate = a / b;
  return rate > 1 ? '100%' : `${(rate * 100).toFixed(fix)}%`;
}

export function getPercent(a, b, fix) {
  if (a < 0 || b <= 0) {
    return '-';
  }
  const rate = a / b;
  return rate > 1 ? '100' : (rate * 100).toFixed(fix);
}

export function getRatio(a, b, fix) {
  if (b <= 0 || a === '-' || b === '-') {
    return '-';
  }
  const rate = a / b;
  return (rate * 100).toFixed(fix);
}


// 获取比例值
export function getAvg(a, b, fix) {
  if (a < 0 || b <= 0) {
    return '-';
  }
  const rate = a / b;
  return rate.toFixed(fix);
}

/**/
export function getTotalSum(v, arr) {
  let sum = 0;
  if (!isEmptyObject(arr) && v) {
    Object.keys(arr).forEach((key) => {
      sum += getArraySum(v, arr[key]);
    });
  }
  return sum;
}

/**/
export function getArraySum(v, arr) {
  let sum = 0;
  if (arr && arr.length) {
    arr.forEach((obj) => {
      sum += (obj[v] >= 0 ? obj[v] : 0);
    });
  }
  return sum;
}

/**/
export function getNumFound(arr) {
  let num = 0;
  if (!isEmptyObject(arr)) {
    num = Object.keys(arr).length;
  }
  return num;
}

/**
 * 获取top5版本
 * @param 可选参数， withoutAll 不带上全版本
 */
export function getTop5(key, dataList, option) {
  const arr = [];
  let result = '';
  const _symbol = option && option._symbol || ';';
  if (dataList && isEmptyObject(dataList)) {
    return '';
  }
  // 根据key 得到相关数据(排除了所有版本，key为-1)
  Object.keys(dataList).forEach((data) => {
    data !== '-1' && isArray(dataList[data]) && arr.push({
      key: data,
      value: getArraySum(key, dataList[data]),
    });
  });
  // order 按value排序
  let newArr = sortBy(arr, (o) => o.value);
  // sortBy 得到的升序排列的数组，取最后5个 返回
  newArr = newArr.splice(-5, 5);
  newArr.forEach((temp) => {
    result += temp.key + _symbol;
  });

  // 如果没有withoutAll,或者值为 !withoutAll 为true 的时候
  if (!option || !option.withoutAll) {
    result += `-1${_symbol}`;
  }
  result = result && result.length && result.substr(0, result.length - 1);
  return result;
}

export function getVersionStr(ver) {
  const result = ver && ver.length && ver.split(';').slice(0, 6).join(';');
  return result;
}

export function getTopStr(arr, num, separator) {
  const result = arr && arr.length && arr.split(separator).slice(0, num).join(separator);
  return result;
}

export function formatTime(ms) {
  if (isNaN(ms) || ms < 0) {
    return '-';
  }
  if (ms < 60000) { // 一分钟内
    const second = Math.floor(ms / 1000);
    return i18n.t('timePeriod.秒', {second});
  }
  if (ms < 60000 * 60) { // 一小时内
    const minute = Math.floor(ms / 60000);
    const second = Math.floor((ms - minute * 60000) / 1000);
    return i18n.t('timePeriod.分秒', {minute, second});
  }
  if (ms < 1000 * 3600 * 24) { // 一天内
    const hour = Math.floor(ms / (1000 * 3600));
    const minute = Math.floor((ms - hour * 1000 * 3600) / (60000));
    const second = Math.floor((ms - hour * 1000 * 3600 - minute * 60000) / 1000);
    return i18n.t('timePeriod.时分秒', {hour, minute, second});
  }
  const day = Math.floor(ms / (1000 * 3600 * 24));
  const hour = Math.floor((ms - day * 1000 * 3600 * 24) / (1000 * 3600));
  const minute = Math.floor((ms - day * 1000 * 3600 * 24 - hour * 1000 * 3600) / (60000));
  const second = Math.floor((ms - day * 1000 * 3600 * 24 - hour * 1000 * 3600 - minute * 60000) / 1000);
  return i18n.t('timePeriod.天时分秒', {day, hour, minute, second});
}


// 过滤空格 回车换行符
export function formatStr(str) {
  const newStr = str && str.replace(/ +/g, '').replace(/[ ]/g, '').replace(/[\r\n]/g, '').replace(/；/g, ';').replace(/\(.+?\)/g, '');
  return newStr;
}

// 获取字符串长度
export function getStringLength(str) {
  const target = str && str.trim();
  let length = 0;
  for (let i = 0; i < target.length; i++) {
    if (target.charCodeAt(i) >= 0 && target.charCodeAt(i) <= 128) {
      length++;
    } else {
      length += 2;
    }
  }
  return length;
}

// 计算字符串长度
export function cutWord(str, maxLength) {
  let target = trim(str);
  let i = 0;
  let length = 0;
  for (; i < target.length; i++) {
    if (target.charCodeAt(i) > 0 && target.charCodeAt(i) < 128) {
      length++;
    } else {
      length += 2;
    }
    if (length > maxLength) {
      target = target.substring(0, i);
      break;
    }
  }
  return target;
}

// 找出数组中重复的元素
export function findSame(arr) {
  // console.log("arr ======",arr);
  const result = [];
  const hash = {};
  if (arr && arr.length) {
    for (const i in arr) {
      if (hash[arr[i]]) {
        result.push(arr[i]);
      } else {
        hash[arr[i]] = 1;
      }
    }
  }
  return result;
}

// 获取处理人
export function getNickName(obj) {
  // console.log("getNickName ===",obj);
  if (obj) {
    if (obj.name) {
      return obj.name;
    } else if (obj.qqNickName) {
      return obj.qqNickName;
    } else if (obj.userId) {
      return obj.userId;
    } else {
      return '';
    }
  }
  return '';
}

// 获取UserId
export function getUserId(obj) {
  // console.log("getUserId ===",obj);
  if (obj) {
    if (obj.userId) {
      return obj.userId;
    }
  }
  return '';
}

// 告警页面找出对应的昵称
export function findNickName(arr, str, spliter) {
  const result = [];
  if (str && arr && arr.length) {
    let uinArr = str.split(spliter || ';');
    uinArr = uinArr.filter((uin) => uin !== '');
    for (let i = 0; i < uinArr.length; i++) {
      const index = findIndex(arr, (o) => {
        return o.value === uinArr[i];
      });
      if (index >= 0) {
        result.push(arr[index].label);
      }
    }
    if (result && result.length) {
      return result.join(';');
    } else {
      return str;
    }
  } else {
    return str;
  }
}

/**
 * 将问题处理人数组，转换为显示文案
 * @param assigneeList {{ name: string, localUserId: string, wetestUin: string }[]}
 */
export function convertIssueAssigneeListToDisplayText(assigneeList) {
  if (isNullish(assigneeList) || !Array.isArray(assigneeList) || assigneeList.length === 0) {
    return '';
  }

  const nicknameList = assigneeList.map(x => x.name);
  const MAX_NAMES = 2;
  if (nicknameList.length > MAX_NAMES) {
    const names = nicknameList.slice(0, MAX_NAMES).join(', ');
    const len = nicknameList.length;
    const remainLen = len - MAX_NAMES;
    return ze(`${names} 等${len}人`, `${names} and ${remainLen} more`);
  }
  return nicknameList.join(', ');
}


// 判断是否是demo产品
export function checkIsDemo(appId) {
  return '900035977,900035975'.split(',').indexOf(appId) > -1;
}

// 判断元素是否在数组里面
export function isInArray(arr, target) {
  return indexOf(arr, target) !== -1;
}

// 获取当前授权产品的信息
export function getCurrentAuthAppInfo(arr, id) {
  let result = '';
  if (arr && arr.length && id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].thirdpartyId === id) {
        result = arr[i];
      }
    }
  }
  return result;
}

// 获取授权的错误码
export function getAuthCode(code) {
  let result = AUTH_CODE.NETWORK_ERROR;
  if (code === -2) {
    result = AUTH_CODE.UNKNOWN_PLATFORM;
  } else if (code === -3) {
    result = AUTH_CODE.APPID_IS_NOT_EXIST;
  } else if (code === -4) {
    result = AUTH_CODE.URL_ILLEGAL;
  } else if (code === -401) {
    result = AUTH_CODE.HAS_NO_RIGHT;
  }
  return result;
}

// 序列化参数
export function serialize(obj) {
  let result = '';
  if (obj) {
    Object.keys(obj).forEach((k) => {
      result += `${k}=${encodeURIComponent(obj[k])}&`;
    });
  }
  result = (result && result.substring(0, result.length - 1));
  return result;
}

// 解除绑定的时候获取最新的授权第三方列表
export function getNewAppAuthArr(id, arr) {
  let result = arr;
  if (arr && arr.length && id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].thirdpartyId === id) {
        arr[i].authorized = 0;
      }
    }
    result = arr;// 重新赋值
  }
  return result;
}

/**
 * @param str {string}
 * @param maxLenBeforeEllipsis {number}
 * @return {string}
 */
function truncateLongStringWithEllipsis(str, maxLenBeforeEllipsis) {
  if (!str || !str.length || str.length <= maxLenBeforeEllipsis) {
    return str;
  }
  return String(str).slice(0, maxLenBeforeEllipsis) + '...';
}

export function getVersionRangeArray(versionList) {
  const VERSION_MAX_LENGTH = 50;

  if (!Array.isArray(versionList) || versionList.length === 0) {
    return [null, null];
  }
  const sorted = [...versionList].sort((a, b) => VersionUtil.compareVersion(a, b));
  if (sorted.length === 1) {
    return [sorted[0], sorted[0]];
  }

  const begin = truncateLongStringWithEllipsis(sorted[0], VERSION_MAX_LENGTH);
  const end = truncateLongStringWithEllipsis(sorted[sorted.length - 1], VERSION_MAX_LENGTH);

  return [begin, end];
}

// 获取合并版本的最低版本和最高版本
export function getVersionRangeString(versionList) {
  const [begin, end] = getVersionRangeArray(versionList);
  if (!begin && !end) {
    return '';
  }
  if (begin === end) {
    return begin;
  }
  return `${begin} ~ ${end}`;
}

//
export function transformMemData(data) {
  const kbData = data / 1024;
  if (kbData > 1048576) {
    return formatNum((kbData / 1048576).toFixed(2));
  } else if (kbData > 1024) {
    return formatNum((kbData / 1024).toFixed(2));
  } else {
    return formatNum(kbData.toFixed(2));
  }
}

// 堆栈格式化
export function makeDetailObjectFromStackLine(line, rawType, pid, extraInfo) {
  const hexReg = /\s0[xX][0-9a-fA-F]+\s/;
  const numReg = /^\d+/g;
  // TODO: 业务里面exceptionType目前不统一，在这里统一转一下
  const type = {
    crash: 'crash',
    crashes: 'crash',
    anr: 'anr',
    blocks: 'anr',
    error: 'error',
    errors: 'error',
  }[rawType];

  const {
    isMinidumpStack = false,
  } = extraInfo || {};

  if (!line) {
    return {};
  }

  try {
    let lineObj = {};
    if ((PlatformUtil.isIosOrMac(pid))) {
      const HexadecimalArr = line.match(hexReg);// 取出16进制数
      const hexIndex = HexadecimalArr && HexadecimalArr.index;
      const lineNumStr = line.substr(0, hexIndex);
      const addrStr = HexadecimalArr && HexadecimalArr[0] && line.substr(hexIndex + HexadecimalArr[0].length, line.length - 1);
      const lineNum = lineNumStr && lineNumStr.match(numReg);// 取出行号
      const numArr = lineNumStr && lineNumStr.split(numReg);// 然后再根据数字分割
      const addrArr = addrStr && addrStr.split(/\s[+]\s/);
      lineObj.lineNum = lineNum && lineNum.length && lineNum[0];
      lineObj.libname = numArr && numArr.length && numArr[1] && numArr[1].trim();
      lineObj.soNameWithoutPath = lineObj.libname;
      lineObj.hexadecimal = ((HexadecimalArr && HexadecimalArr[0]) || '').trim();
      lineObj.stackContent = addrStr || '';
      lineObj.addr = addrArr && addrArr.length && addrArr[0] && addrArr[0].trim();
      lineObj.stackTraceNum = addrArr && addrArr.length && addrArr[1] && addrArr[1].trim();
      lineObj.line = line.split(numReg).length && line.split(numReg)[1] && line.split(numReg)[1].trim();// ios其他类型还要替换掉replace file:0  .replace('unknown file:0',"");
      if (!HexadecimalArr || !lineNum) {
        lineObj.line = line;
      }
      // 分割出文件路径
      const filePathSplitRegex = /(\(\S+?\.\S+?:\d+.*\))/;
      const [
        stackContentBeforeFilePath,
        filePathWithParentheses,
        ...stackContentListAfterFilePath
      ] = lineObj.stackContent.split(filePathSplitRegex);
      lineObj.stackContentBeforeFilePath = stackContentBeforeFilePath;
      lineObj.stackContentAfterFilePath = (stackContentListAfterFilePath || []).join('');
      // 文件路径中间可能有inline行
      StackUtil.fillFilePathAndInlineLines(lineObj, filePathWithParentheses);
    } else if (isAndroidOrHarmony(pid)) {
      if (type === 'crash' || type === 'error') {
        try {
          lineObj = makeAndroidDetailObjectFromStackLine(line, isMinidumpStack);
        } catch (e) {
          lineObj.line = line;
          console.error('makeAndroidDetailObjectFromStackLine error: ', e);
        }
      } else {
        lineObj.line = line;
      }
    } else if (platformUtils.isPcOrLinux(pid)) {
      if (ExceptionCategoryUtil.isCrash(type)) {
        try {
          lineObj = makeDetailObjectFromMinidumpStackLine(line);
        } catch (e) {
          lineObj.line = line;
          console.error('makeAndroidDetailObjectFromStackLine error: ', e);
        }
      } else {
        lineObj.line = line;
      }
    } else {
      lineObj.line = line;
    }
    if (extraInfo) {
      lineObj.extraInfo = extraInfo;
    }
    lineObj.rawLine = line;
    return lineObj;
  } catch (e) {
    console.error('makeDetailObjectFromStackLine throws exception !', line, rawType, pid, extraInfo);
    console.error(e);
    return { line, rawLine: line };
  }
}

// 判断是否需要高亮
export function highlight(obj, issue, pid) {
  let flag = false;
  if (!isEmptyObject(issue.toJS())) {
    const { detailInfo } = issue.toJS();
    if (detailInfo) {
      const { processName, bundleId } = detailInfo;
      const regProcessName = new RegExp(`(${processName})`, 'gi');
      const regBundleId = new RegExp(`(${bundleId})`, 'gi');
      if (parseInt(pid) === 1) { // 安卓,进程和bunleId来匹配
        flag = regProcessName.test(obj.line) || regBundleId.test(obj.line);
      }
      if (parseInt(pid) === 2 && obj) {
        flag = regProcessName.test(obj.libname);
      }
      // 添加兼容pid等于4的情况
      if (parseInt(pid) === 4 && obj) {
        flag = regProcessName.test(obj.libname);
      }
    }
  }
  return flag;
}

// getLastSeries
export function getLastSeries(datas) {
  const result = {};
  if (!isEmptyObject(datas)) {
    Object.keys(datas).forEach((key) => {
      result[key] = [datas[key][datas[key].length - 1]];
    });
  }
  return fromJS(result);
}

// getKeyLength
export function getKeyLength(datas) {
  let no = 0;
  if (!isEmptyObject(datas)) {
    no = Object.keys(datas).length;
  }
  return no;
}

// 获取Ft详情组装好的数组
export function getFtArr(datas) { // 所有的数据
  const result = [];
  let index = 1;
  if (!isEmptyObject(datas)) {
    Object.keys(datas).forEach((key) => {
      const crashRate = getPercent(datas[key][0].crashUser, datas[key][0].accessUser, 2);// 用户崩溃率
      const accessRate = getPercent(datas[key][0].crashNum, datas[key][0].accessNum, 2);// accessRate
      result.push({
        index: index++,
        key,
        crashRate: crashRate === '-' ? '0' : crashRate,
        accessRate: accessRate === '-' ? '0' : accessRate,
        crashUser: datas[key][0].crashUser,
        crashNum: datas[key][0].crashNum,
      });
    });
  }
  return result;
}


// 从数据中生存下拉框的选择
export function getSelectOption(dataList) {
  if (isEmptyObject(dataList)) {
    return [];
  }
  return dataList.map((data, index) => ({
    label: decodeURIComponent(index), value: index,
  }));
}


export function getValueWithLabel(options) {
  if (!isArray(options)) {
    throw new Error('Tab 组件的tabs是一个数组，请检查该组件的props！');
  }
  return (label) => {
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].label === label) {
        return options[i].value;
      }
    }
    return '';
  };
}

export function routeReclick(nextLocation, oldLocation) {
  const query = parseSearch(oldLocation);
  const nextQuery = parseSearch(nextLocation);
  return Object.keys(nextQuery).length === 1
    && nextLocation.key !== oldLocation.key
    && oldLocation.pathname.indexOf(nextLocation.pathname) !== -1
    && location.href.indexOf('#') === -1;
}

// 数组转码
export function covertArrByDecode(arr) {
  const result = [];
  if (arr && arr.length) {
    arr.forEach((item) => {
      result.push(decodeURIComponent(item));
    });
  }
  return result;
}

// 获取指定时间的时间戳,但是是毫秒，参数的格式：2016-10-11 00:00:00
export function transdate(time) {
  const datetest = new Date();
  const Y = time.substring(0, 4);
  const M = time.substring(5, 7) - 1;
  const D = time.substring(8, 10);
  const h = time.substring(11, 13);
  const m = time.substring(14, 16);
  const s = time.substring(17, 19);
  datetest.setFullYear(Y);
  datetest.setMonth(M);
  datetest.setDate(D);
  datetest.setHours(h);
  datetest.setMinutes(m);
  datetest.setSeconds(s);
  return Date.parse(datetest);
}

export function getStatus(status) {
  return isNullish(status) ? status : Number(status);
}

export function getIssueDetailUrlPrefix(exceptionType) {
  let issueDetailUrlPrefix = 'crashes';
  if (ExceptionCategoryUtil.isAnr(exceptionType)) {
    issueDetailUrlPrefix = 'blocks';
  } else if (ExceptionCategoryUtil.isError(exceptionType)) {
    issueDetailUrlPrefix = 'errors';
  }
  return `crash-reporting/${issueDetailUrlPrefix}`;
}

/**
 * @param arr {array} 源数据
 * @param prop {string} 按prop属性进行对比排序
 * @param asc {boolean} 升降序
 */
export function sortList(arr, prop, asc) {
  const list = fromJS(arr);
  return list.sort((a, b) => {
    return (a.get(prop) < b.get(prop)) === !!asc;
  }).toJS();
}


/**
 * 补齐接口返回确实的数据，数据点默认为0
 *
 */

const item = {
  addUserAmount: 0,
  startUpAmount: 0,
  useTimeAvg: 0,
  useUserAccumulation: 0,
  useUserAmount: 0,
  useUserAmount7: 0,
  useUserAmount30: 0,
};

export function getHoursFillData(dataList) {
  // 为datalist 补齐23小时数据
  const date = (dataList && dataList[0] && dataList[0].date) || moment().format('YYYYMMDDHH');
  const arr = [];
  for (let i = 0, l = 24; i < l; i++) {
    if (dataList[i]) {
      arr.push(dataList[i]);
    } else {
      arr.push({
        addUserAmount: -1,
        date: `${parseInt(date) + i}`,
        startUpAmount: -1,
        useTimeAvg: -1,
        useUserAccumulation: -1,
        useUserAmount: -1,
      });
    }
  }
  return arr;
}

export function getDayFillData(dataList, param) {
  const params = Object.assign({}, param);
  return fillData(params, getDateList(dataList), dataList);
}

export function nextHour(currentHour) {
  return moment(currentHour).add(1, 'd').format('YYYYMMDD');
}

export function getLastNHour(hour, n) {
  return moment(hour).subtract(n, 'd').format('YYYYMMDD');
}

function getDateList(dataList) {
  return dataList.map((data) => {
    return data.date;
  });
}

function fillData(params, dateList, dataList) {
  if (!dataList || dataList.length === 0) {
    return;
  }
  const { endHour } = params;
  let { startHour } = params;
  const tempList = [];
  while (!moment(startHour).isAfter(endHour)) {
    const index = dateList.indexOf(startHour);
    if (index > -1) {
      tempList.push(dataList[index]);
    } else {
      tempList.push(Object.assign({}, item, { date: startHour }));
    }
    startHour = nextHour(startHour);
  }
  return tempList;
}

export function versionComparation(version1 = '', version2 = '') {
  const arr1 = `${version1}`.split('.');
  const arr2 = `${version2}`.split('.');
  const len = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < len; i++) {
    const ver1 = parseInt(arr1[i]) || 0;
    const ver2 = parseInt(arr2[i]) || 0;
    console.log(ver1, ver2);
    if (ver1 > ver2) {
      return -1;
    } else if (ver1 < ver2) {
      return 1;
    }
  }
  return 0;
}

export function versionSort(versions) {
  if (!isArray(versions)) {
    throw new Error('versionSort\'s param should be an array');
  }
  return versions.sort(versionComparation);
}

export function ravenPostError(e) {
  Raven && Raven.captureException(e);
}

export function parseSearch({ search, pathname }) {
  let s = search;
  if (typeof search !== 'string' || search.charAt(0) !== '?') {
    if (pathname && pathname.includes('?')) {
      s = pathname.split('?')[1];
    } else {
      return {};
    }
  }

  // FIXME: fromEntries目前还未polyfill，要求浏览器版本太高，先用别的取代
  // return Object.fromEntries(new URLSearchParams(search));
  return Array.from(new URLSearchParams(search)).reduce((o, i) => ({ ...o, [i[0]]: i[1] }), {});
}

/**
 * @param {string} oldSearch location.search, like `?a=b&c=d`
 * @param {Object | null} query new search param
 * @param {boolean} removeNullish 如果设置为true，则会从oldSearch里面移除query中值是nullish的key
 */
export function generateSearch(oldSearch, query, removeNullish = false) {
  const oldQuery = parseSearch({
    search: oldSearch,
  });
  const nullishKeys = Object.entries(query || {})
    .filter(([, v]) => isNullish(v))
    .map(([k]) => k);
  let newQuery = Object.assign({}, oldQuery, query);
  if (removeNullish) {
    newQuery = omit(newQuery, nullishKeys);
  }
  return `?${new URLSearchParams(newQuery).toString()}`;
}

const routesFollowByAppId = [
  'distribution',
  'accurate-analysis',
  'dashboard-filters',
  'dashboard',
  'version-dashboard',
  'insight',
  'daily-report-screenshot',
  'versions',
  'retention',
  'sessions',
  'blocks',
  'crashes',
  'errors',
  'client-report-id',
  'mmap',
  'oom',
  'advanced-search',
  'notification',
  'tags',
  'dsyms',
  'tapd',
  'apps',
  'guide',
  'nintendo-switch',
  'obfuscation-file-setting',
  'issue-classification-setting',
  'user-id-description-config',
  'sdk-strategy-config',
  'web-console-config',
  'sdk-strategy',
  'efficiency',
  'feature-config',
  'member-management',
  'audit-log',
  'product-info',
  'versions-management',
  'delete-project',
  'super-admin-server-settings',
  'super-admin-notice-config',
  'feature-stat-result',
  'country-group-config',
  'tutorial',
  'online-retrace',
  'online-send-crash',
  'trend-compare',
];

// FIXME: 路由设计的什么垃圾玩意，竟然得用这个方式获取app id
export function tryToGetAppIdFromPathname(pathname) {
  let match = [];
  routesFollowByAppId.some((route) => {
    if (pathname.includes(route)) {
      const reg = new RegExp(`${route}\/(\\w+)\/?`);
      match = pathname.match(reg);
      return true;
    }
    return false;
  });
  if (match && match.length === 2) {
    return match[1];
  } else {
    console.error('tryToGetAppIdFromPathname无法匹配到路由！');
    return '';
  };
}
