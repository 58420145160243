import { fromJS, Map } from 'immutable';
import moment from 'moment';
import { DATE_OPTIONS } from 'utils/constants';
import { getStartEndDateFromStr } from 'utils/StringUtils';
import i18n from 'i18n.js';
import { PlatformUtil } from 'utils/platform';
import { DEFAULT_TIME_ZONE } from 'utils/time-zone-util';
import { SummaryHeadDataType } from 'utils/constants/summary-head-data-type';
import { getAllInOneReduxState } from 'index';
import { ExceptionCategory } from 'utils/exception-category';
import { VmTypeInt } from 'utils/constants/vm-type';

export const summaryInitialSelectOptions = new Map({
  trendVersion: {
    options: [{
      label: i18n.t('versionOptions.全版本'),
      value: '-1',
    }],
  },
  rankVersion: {
    options: [{
      label: '所有版本',
      value: '-1',
    }],
  },
  trendDate: {// 所有上报时间
    options: ['今天', '最近7天', '最近15天', '最近30天', '自定义时间段'],
  },
  integratedAppOptions: [],
  integratedAppIdToVersionOptions: {},
  integratedAppIdToVersionTree: {},
  compareDate: DATE_OPTIONS,
  rankDate: DATE_OPTIONS,
  subModuleIdOptions: [],
  userSceneTagOptions: [],
  appTag: 'appRate',
  systemTag: 'systemRate',
  modelTag: 'modelRate',
  tagTag: 'tagRate',
  gpuTag: 'gpuRate',
  cpuTag: 'cpuRate',
  isShowAllTopIssue: false,
  pageType: '崩溃',
});

export function makeSummaryInitialSelectOptions(pid) {
  let res = summaryInitialSelectOptions;
  if (!PlatformUtil.isCrashAvailable(pid)) {
    if (PlatformUtil.isAnrAvailable(pid)) {
      res = res.set('pageType', '卡顿');
    } else {
      res = res.set('pageType', '错误');
    }
  }
  return res;
}

function makeSummaryInitialSearchParams(defaultVmType) {
  defaultVmType = defaultVmType || VmTypeInt.ALL;

  const trendDate = getStartEndDateFromStr('today');
  const compareDate = getStartEndDateFromStr('last_1_day');

  const rankDate = getStartEndDateFromStr('today');
  const topIssueDate = getStartEndDateFromStr('today');

  return new Map({
  isIntegratedAppTrend: false, // 是否是查看公共组件接入app的大盘视图
  integratedAppId: null,

  subModuleId: '-1', // 控制所有大盘指标的全局subModuleId

  trendTagName: 'EXCP_OVERVIEW.userCrashRate',
  trendVersion: '-1',
  vmSelectValue: defaultVmType,
  date1: 'today', // trendDate
  startDate1: trendDate.startDate,
  endDate1: trendDate.endDate,

  enableRealtimePrevDayTrend: false,
  enableRealtimePrevWeekTrend: false,

  compareVersion: 'no_compare',
  date2: 'last_1_day', // compareDate
  startDate2: compareDate.startDate,
  endDate2: compareDate.endDate,

  rankVersion: '-1',
  rankVmSelectValue: defaultVmType,
  rankLoading: false,
  startDate3: rankDate.startDate, // rankMinDate
  rankMaxDate: null,
  //
  topIssueLoading: false,
  topIssueSortField: null,
  topIssueCountry: '-1',
  topIssueVersion: '-1',
  topIssueSubModuleId: '-1',
  topIssueVmSelectValue: defaultVmType,
  topIssueIntegratedAppId: null,
  topIssueDate: topIssueDate.startDate,
  topIssueMaxDate: null,
  hour: '00',
  timeRange: [
    '00','01','02','03','04','05',
    '06','07','08','09','10','11',
    '12','13','14','15','16','17',
    '18','19','20','21','22','23'
  ],

  isRealTime: 1,
  realTimeTopIssueLoading: false,
  realTimeTopIssueSortField: null,
  realTimeTopIssueVersion: '-1',
  realTimeTopIssueCountry: '-1',
  realTimeTopIssueSubModuleId: '-1',
  realTimeTopIssueVmSelectValue: defaultVmType,
  realTimeTopIssueHour: moment().format('YYYYMMDDHH'),
  realTimeTopIssueLimit: 5,
  headDataType: SummaryHeadDataType.userRate,

  exceptionCategory: ExceptionCategory.CRASH,

  dataType: 'hourly', // 趋势图  按小时 或 累计标识
  topIssueDataType: 'unSystemExit', // top20 issue类型 全部显示 异常 非异常
});
}

export function makeInitialSummarySearchParamsByPid(pid, defaultVmType) {
  defaultVmType = defaultVmType || VmTypeInt.ALL;
  const state = getAllInOneReduxState();
  const storage = state.global.get('localStorage').toJS();
  const { summaryHeadDataType: storagedHeadDataType } = storage;

  let searchParams = makeSummaryInitialSearchParams(defaultVmType);
  if (storagedHeadDataType) {
    searchParams = searchParams.set('headDataType', storagedHeadDataType);
  }
  const currentHeadDataType = searchParams.get('headDataType');
  const isCurrentHeadDataTypeAccessRelated = currentHeadDataType === SummaryHeadDataType.userRate
    || currentHeadDataType === SummaryHeadDataType.sessionCrashRate;
  // ps和xbox目前没有联网，无法展示联网相关指标
  if (!PlatformUtil.hasAccessStats(pid) && isCurrentHeadDataTypeAccessRelated) {
    searchParams = searchParams.set('headDataType', SummaryHeadDataType.crashNum)
      .set('trendTagName', 'EXCP_OVERVIEW.occurNumber');
  }

  if (!PlatformUtil.isCrashAvailable(pid)) {
    if (PlatformUtil.isAnrAvailable(pid)) {
      searchParams = searchParams.set('exceptionCategory', ExceptionCategory.ANR);
    } else {
      searchParams = searchParams.set('exceptionCategory', ExceptionCategory.ERROR);
    }
  }

  return searchParams;
}


// 初始化state
export const initialState = fromJS({
  summaryPagePrevLocationChangePathname: '',
  selectOptions: summaryInitialSelectOptions,
  trendData: [],
  compareData: [],
  realTimeTrendData: [],
  realTimeCompareData: [],
  realTimePrevDayTrendData: [],
  realTimePrevWeekTrendData: [],
  searchParams: makeSummaryInitialSearchParams(),
  topIssueData: {},
  isShowTop3: false,
  realTimeTopIssueData: {},
  realTimeAppendData: {},
  countryGroupConfigList: [],
  countryGroupConfigModalVisible: false,
  bigDataRealtimeUpdateMillis: 0,
  bigDataResultDailyUpdateMillis: 0,
});
