import Immutable, { Map, List, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  APP_LIST_SUCC,
  SELECT_APP,
  INITIAL_CURRENTAPP,
  VERSION_LIST_SUCC,
  HAS_GRAY_SUCC,
  UPDATE_CURRENT_APP,
  UPDATE_APP_INFO,
  SEND_MESSAGE_SUCC,
  UPDATE_APP_FAVORITE_BY_ID,
  APPINFO_UPDATE_SUCC,
} from 'store/actions';
import moment from 'moment';
import { ReduxActionType } from 'reducers/redux-action-type';
import { TimeZoneUtil } from 'utils/time-zone-util';


// 默认开启，再通过接口查到真实情况
export const initalState = new Map({
  appList: new List(),
  appListWithBlack: new List(),
  current: new Map(),
  confirmed: true,
  init: false,
  applyActiveStatus: false,
  appIdToWebAppSettings: {},
  appIdToServerAppSettings: {},
  appIdToReportDiscardStrategy: {},
  appIdToDashboardFilterList: {},
  appIdToTapdWorkspaceId: {},
});

function enrichAppListItem(item) {
  let todayCrashRatePercentage = null;
  const today = moment().format('YYYYMMDD');
  if (item.dailyStatList) {
    const todayStat = item.dailyStatList.find(x => x.date === today);
    if (todayStat && todayStat.accessUser !== 0) {
      todayCrashRatePercentage = todayStat.crashUser / todayStat.accessUser * 100;
    } else {
      todayCrashRatePercentage = 0;
    }
  }

  return {
    ...item,
    isActive: true,
    platformId: item.pid,
    isDeleted: !Number(item.isShow),
    todayCrashRatePercentage,
  };
}

export default handleActions({
  [APP_LIST_SUCC]: (state, action) => {
    const result = action.response || action.data || [];
    // const newResult = result;
    const newResult = result.map(item => enrichAppListItem(item));
    return state.update('appList', (appList) => fromJS(newResult))
      .set('init', true);
  },
  [SEND_MESSAGE_SUCC]: (state) => {
    return state.set('init', true);
  },
  PRODUCT_DELETE_SUCC: (state, action) => {
    const { appList } = action.response;
    return state.set('appList', appList ? fromJS(action.response.appList.map(item => enrichAppListItem(item))) : new List());
  },
  [SELECT_APP]: (state, action) => {
    // console.log(`${TAG} SELECT_APP: action: `, action.payload);
    const { appId } = action.payload;
    const selected = state.get('appList').find((app) => app.get('appId') === appId);
    return selected ? state.set('current', selected) : state;
  },

  APPLY_ACTIVE_SUCCESS: (state) => state.set('applyActiveStatus', true),

  APPLY_ACTIVE_INIT: (state) => state.set('applyActiveStatus', false),

  [HAS_GRAY_SUCC]: (state, action) => {
    return state.set('isGrayApp', action.response && action.response.data && action.response.data.enableVersion2);
  },
  // 当前的activeAPP的更新
  [UPDATE_CURRENT_APP]: (state, action) => state.update('current', (current) => current.mergeDeep(action.payload.appInfo)),

  /**
   * 超级管理员通过路由访问任意的app
   */
  ADD_EXTRA_APP: (state, action) => {
    let current = action.payload;
    if (!current) {
      return state;
    }
    const { appId } = current;
    const appList = state.get('appList').toJS();
    current = Immutable.fromJS(enrichAppListItem({
      ...current,
      pid: current.platformId,
      appName: current.name,
    }));
    if (appList.some(x => x.appId === appId)) {
      return state.set('current', current);
    }
    return state.update('appList', (appList) => appList.push(current)).set('current', current);
  },

  [INITIAL_CURRENTAPP]: (state, action) => {
    return state.set('current', new Map({ appId: action.appId, pid: action.pid }));
  },
  [VERSION_LIST_SUCC]: (state, action) => {
    return state.set('currentAppVersions', action.response.versions || []);
  },

  APP_PERMISSION_SUCC: (state, action) => {
    return state.set('confirmed', !!action.response.data.datas);
  },

  POST_PERMISSION_SUCC: (state) => {
    return state.update('current', (app) => app.set('enableUserAuit', 1));
  },

  [UPDATE_APP_INFO]: (state, action) => {
    const { appInfo } = action.payload;
    state = state.update('current', x => x.merge(appInfo));
    // 全局时区设置为项目时区
    TimeZoneUtil.setMomentTimeZoneByReduxStateCurrentApp(appInfo);
    const appIndex = state.get('appList').findIndex((listItem) => {
      return appInfo.get('appId') === listItem.get('appId');
    });
    if (appIndex === -1) {
      return state;
    }
    return state.update('appList', (appList) => appList.update(appIndex,
      (item) => item.set('type', appInfo.get('type')).set('isSdkApp', appInfo.get('isSdkApp')).set('appName', appInfo.get('name'))));
  },

  updateWebAppSettingsByAppId: (state, action) => {
    const { appId, webAppSettings } = action.payload;
    return state.set('appIdToWebAppSettings', { ...state.get('appIdToWebAppSettings'), [appId]: webAppSettings });
  },

  updateServerAppSettingsByAppId: (state, action) => {
    const { appId, serverAppSettings } = action.payload;
    return state.set('appIdToServerAppSettings', { ...state.get('appIdToServerAppSettings'), [appId]: serverAppSettings });
  },

  updateTapdWorkspaceIdByAppId: (state, action) => {
  const { appId, workspaceId } = action.payload;
  return state.set('appIdToTapdWorkspaceId', { ...state.get('appIdToTapdWorkspaceId'), [appId]: workspaceId });
},

  updateBugTrackingInfoByAppId: (state, action) => {
    const { appId, data } = action.payload;
    const { tapdWorkspaceInfo, jiraAppInfo } = data;
    const simplifiedInfo = {
      tapdWorkspaceId: tapdWorkspaceInfo?.workspaceId || '',
      jiraProjectId: jiraAppInfo?.jiraProjectId || '',
    };

    return state.set('appIdToBugTrackingSimplifiedInfo', { ...state.get('appIdToBugTrackingSimplifiedInfo'), [appId]: simplifiedInfo });
  },

  [ReduxActionType.setAdminPermissionAppList](state, action) {
    const { data } = action.payload;
    return state.set('adminPermissionAppList', data);
  },

  [ReduxActionType.setReportDiscardStrategyByAppId](state, action) {
    const { appId, reportDiscardStrategy } = action.payload;
    return state.set('appIdToReportDiscardStrategy', { ...state.get('appIdToReportDiscardStrategy'), [appId]: reportDiscardStrategy });
  },

  [ReduxActionType.setDashboardFilterListByAppId](state, action) {
    const { appId, data } = action.payload;
    return state.set('appIdToDashboardFilterList', { ...state.get('appIdToDashboardFilterList'), [appId]: data });
  },

  [UPDATE_APP_FAVORITE_BY_ID]: (state, action) => {
    const { appId, isFavorited } = action.payload;
    return state.set('appList', state.get('appList').map((app) => {
      if (app.get('appId') === appId) {
        return app.set('isUserFavoriteApp', isFavorited);
      }
      return app;
    }));
  },
}, initalState);
