import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Divider, Space, Tooltip, Select } from 'antd';
import { SummaryVmTypeMultipleSelectOptions } from 'utils/constants';
import { ze } from 'utils/zhEn';
import { VmTypeInt } from 'utils/constants/vm-type';
import { useTranslation } from 'react-i18next';
import { EnvUtil } from 'utils/env-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import { CsSelect } from 'components/antd-extension/CsSelect';
import { useDeepCompareEffect } from 'ahooks';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';


const VmTypeSelect = (props) => {
  const {
    value = [],
    style = { width: '200px'},
    onChange = (v) => {},
  } = props;
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [intermediateValue, setIntermediateValue] = useState(null);

  useDeepCompareEffect(() => {
    setIntermediateValue(value);
  }, [value]);

  const options = useMemo(() => {
    return [
      {
        label: ze('全部运行环境', 'All Runtime Envs'),
        value: VmTypeInt.ALL,
      },
      ...SummaryVmTypeMultipleSelectOptions,
    ];
  }, []);

  return <CsSelect
    mode="multiple"
    maxTagCount="responsive"
    style={style}
    value={intermediateValue}
    options={options}
    showArrow={true}
    showSearch={false}
    onChange={v => {
      const newValue = v.filter(item => !(intermediateValue || []).includes(item));

      let newRes = [];
      if (!newValue.length) {
        // 删除选项
        newRes = v.length ? v : [VmTypeInt.ALL];
      } else {
        if (newValue[0] === VmTypeInt.ALL) {
          // 选择全部
          newRes = [VmTypeInt.ALL]
        } else {
          // 过滤全部
          newRes = v.filter(item => ![VmTypeInt.ALL].includes(item))
        }
      }
      // 选择其他所有选项
      // if (newRes.join('-') === valueWithoutAll.join('-')) {
      //   newRes = [VmTypeInt.ALL];
      // }
      if (dropdownOpen) {
        setIntermediateValue(newRes);
      } else {
        onChange(newRes);
      }
    }}
    onDropdownVisibleChange={(open) => {
      setDropdownOpen(open);
      if (open) {
        setIntermediateValue(value);
      } else if (value !== intermediateValue) {
        onChange(intermediateValue);
      }
    }}
  />;
};

VmTypeSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default VmTypeSelect;
