import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ReportImg,
} from 'components/top';
import {
  UploadPopAlert, CopyRight, PureRender,
} from 'components/commons';
import { ERR_TOKEN_EXPIRED, ERR_NO_PERMISSION } from 'utils/constants';
import {
  getExceptionType, isContains, parseSearch, tryToGetAppIdFromPathname, ravenPostError,
} from 'utils/helper';
import NotificationSystem from 'react-notification-system';
import * as EventEmitter from 'utils/EventEmitterHelper';
import { showNotifyHandler, notifyStyle } from 'utils/NotifyHelper';
import mapActions from 'reducers/actions';
import style from './style.scss';
import CrashSightHeader from 'components/top/CrashSightHeader';
import CrashSightLeftMenu from 'components/top/CrashSightLeftMenu';
import SettingSubLeftMenu from 'components/top/SettingSubLeftMenu';
import IssueEditStatusModal from 'components/exception/issue/IssueEditStatusModal';
import Axios from 'axios';
import { isNotNullish, isNullish } from 'utils/nullish';
import moment from 'moment-timezone';
import { Alert, Modal } from 'antd';
import { ze } from 'utils/zhEn';
import WatermarkAppContainerDiv from 'containers/App/WatermarkAppContainerDiv';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';
import { DEFAULT_TIME_ZONE, TimeZoneUtil } from 'utils/time-zone-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import { CS_STYLES } from 'utils/constants/style-constants';
import PreferencesSubMenu from 'components/top/PreferencesSubMenu';
import GlobalNotificationBar from 'components/top/GlobalNotificationBar';
import classnames from 'classnames';
import { WetestSemUtil } from 'utils/wetest-sem';

function getAppIdFromPropsCurrentApp(props) {
  const { currentApp } = props;
  return isNotNullish(currentApp) ? currentApp.get('appId') || null : null;
}

function getPlatformIdFromPropsCurrentApp(props) {
  const { currentApp } = props;
  return isNotNullish(currentApp) ? currentApp.get('platformId') : null;
}

const TAG = '[APP]-';

/* eslint-disable no-param-reassign */
@connect((state, router) => {
  const { pathname, search } = router.location;
  const { pid } = parseSearch(router.location) || {};
  return {
  reduxState: state,
  user: state.user.get('current'),
  appList: state.app.get('appList'),
  currentApp: state.app.get('current'),
  exceptionType: getExceptionType(pathname),
  routerAppId: tryToGetAppIdFromPathname(pathname),
  routerPid: pid,
  popStatus: state.global.get('popAlertStatus'),
  uploadPopAlertStatus: state.global.get('uploadPopAlertStatus'),
  applyPopAlertStatus: state.global.get('applyPopAlertStatus'),
  pathname,
  reportUrl: state.global.get('reportUrl'),
  message: state.error.get('message'),
  failureType: state.error.get('failureType'),
  globalError: state.global.get('error'),
  localStorage: state.global.get('localStorage'),
  router,
  };
  }, (dispatch) => mapActions(dispatch))
@PureRender
export default class App extends Component {
  static childContextTypes = {
    currentApp: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    appList: PropTypes.object,
    actions: PropTypes.object,
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    appList: PropTypes.object.isRequired,
    currentApp: PropTypes.object.isRequired,
    message: PropTypes.string,
    exceptionType: PropTypes.string,
    routerAppId: PropTypes.string,
    routerPid: PropTypes.string,
    popStatus: PropTypes.any,
    uploadPopAlertStatus: PropTypes.any,
    applyPopAlertStatus: PropTypes.any,
    pathname: PropTypes.string,
    reportUrl: PropTypes.string,
    failureType: PropTypes.string,
    globalError: PropTypes.any,
    actions: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.array,
    ]),
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      scroll: false,
      showTip: !!props.message,
      ele: null,
      isLeftMenuHovered: false,
      isLeftMenuAutoCollapsed: false,

      isPreparingWhenCurrentAppChanged: true,
      blackAppWarningMessage: null,
      isGlobalNotification: false,
    };
  }

  getChildContext() {
    const {
      currentApp, user, appList, actions,
    } = this.props;
    return {
      currentApp,
      currentUser: user,
      appList,
      actions,
    };
  }

  async getBlackAppInfo(appId) {
    let message;
    const rsp = await RestHelper.mustPost('/redir/api/black/getBlackAppInfo', {
      appId,
    });
    const { data } = rsp.json;
    if (data && data.typeOfOperation == 'ADMIN') {
      message = ze('当前项目已被冻结，如需继续使用请联系我们。', 'The current project has been frozen. Please contact us if you want to continue using it.');
    } else if (data && data.typeOfOperation == 'SYSTEM') {
      message = ze('当前项目已被冻结，续费后将恢复正常使用。', 'The current project has been frozen. It will be restored to normal use after renewal.');
    }
    this.setState({
      blackAppWarningMessage: message,
    });
  }

  componentDidMount() { // 还需要执行事件统计的操作
    this.onEffectCurrentAppIdChange({});
    console.log(`App: componentDidMount, routerAppId: ${this.props.routerAppId} `);
    const isScreenshotVisit = this.getIsScreenshotVisit();

    const {
      routerAppId, routerPid, pathname, exceptionType, actions, user, appList, location,
    } = this.props;
    if (user.size && appList.size) {
      this.handleRouteChange(actions, routerAppId, routerPid, pathname, exceptionType);
    } else {
      (async () => {
        if (!isScreenshotVisit) {
          this.fetchAndSetIsInTencentLocalNetwork(); // 判断内外网的不放在下面的promise.all里，避免阻塞页面导致转圈影响使用
        }
        await Promise.all([
          actions.showLoading(),
          actions.getFrontendWebVolatileConfig(),
          ...(!isScreenshotVisit ? [  // 截图服务不拿userInfo
            actions.actionGetUserInfo(),
          ] : []),
          routerAppId && routerPid && actions.selectApp({ appId: routerAppId, pid: routerPid }),
        ].filter(x => x));
        this.handleRouteChange(actions, routerAppId, routerPid, pathname, exceptionType);

        // 首次进入，pv上报
        reportEvent({
          action: EVENT_ACTIONS.OPEN,
          tp1: location.pathname,
        });

        // 首次进入，wetest sem埋点上报
        WetestSemUtil.trackPageView();
      })();
    }

    EventEmitter.addListener('notify.show', (options) => {
      showNotifyHandler(style, options, this.refs.notify.addNotification);
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { pathname, routerAppId: oldRouterAppId } = this.props;
    const {
      pathname: nextPath, actions, routerAppId, routerPid,
    } = nextProps;

    if (!routerAppId) { // 访问非项目内页面，重置时区
      moment.tz.setDefault(DEFAULT_TIME_ZONE);
    }

    if (pathname !== nextPath) {
      if (nextPath === '/') {
        this.handleRouteChange(actions, routerAppId, routerPid, nextPath, getExceptionType(nextPath) || 'crashes');
      } else if (nextPath.indexOf('crash-reporting/dashboard') !== -1) {
        // A new url
        this.handleRouteChange(actions, routerAppId, routerPid, nextPath);
      } else if (nextPath.indexOf('summary/index') !== -1) {
        this.handleRouteChange(actions, routerAppId, routerPid, nextPath);
      } else if (nextPath.indexOf('product/apps/') !== -1) {
        this.handleRouteChange(actions, routerAppId, routerPid, nextPath);
      } else if (nextPath.indexOf('summary/apps') !== -1) { // 从我的产品跳转过来的
        this.handleRouteChange(actions, routerAppId, routerPid, nextPath);
      } else if (nextPath.indexOf('beta/index') !== -1) {
        this.handleRouteChange(actions, routerAppId, routerPid, nextPath);
      }
    }

    if (nextProps.message && nextProps.message !== this.props.message) {
      this.setState({
        showTip: !!nextProps.message,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.onEffectCurrentAppIdChange(prevProps);
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log('props', this.props);
      // 路由切换，pv上报
      reportEvent({
        action: EVENT_ACTIONS.OPEN,
        tp1: this.props.location.pathname,
      });
    }
  }

  async onEffectCurrentAppIdChange(prevProps) {
    const appId = getAppIdFromPropsCurrentApp(this.props) || null;
    const prevAppId = getAppIdFromPropsCurrentApp(prevProps || {}) || null;
    if (appId === prevAppId) {
      return;
    }

    this.setState({ isPreparingWhenCurrentAppChanged: true });

    const { actions } = this.props;
    // 全局时区设置为项目时区
    TimeZoneUtil.setMomentTimeZoneByReduxStateCurrentApp(this.props.currentApp);

    // 重置redux跟app有关的属性
    const platformId = getPlatformIdFromPropsCurrentApp(this.props);

    if (appId) {
      await Promise.all([
        actions.fetchWebAppSettings(appId),
        actions.fetchServerAppSettings(appId),
        this.fetchPermissionList(appId),
        this.getBlackAppInfo(appId), // 获取当前项目黑名单状态
      ]);
    }

    actions.resetAppRelatedStateWhenAppChanged({ appId, platformId });

    this.setState({ isPreparingWhenCurrentAppChanged: false });
  }

  // 检查是否在腾讯内网
  async fetchAndSetIsInTencentLocalNetwork() {
    const { actions } = this.props;
    // 接口来自 https://内网码客域名/q/277107/answer/87443
    try {
      const rsp = await Axios.get('https://xc.woa.com/api/user/getUsers?name=test', { timeout: 3000 });
      const isInTencentLocalNetwork = rsp.status < 400;
      actions.setIsInTencentLocalNetwork(isInTencentLocalNetwork);
    } catch (e) {
      actions.setIsInTencentLocalNetwork(false);
    }
  }

  // 检查是否是截图服务（出账单用的）在访问页面
  getIsScreenshotVisit() {
    const { location } = this.props;
    return !!(new URLSearchParams(location.search).get('screenshotSecret'));
  }

  async fetchPermissionList(appId) {
    if (!appId) {
      return;
    }
    const { actions } = this.props;
    await actions.fetchPermissionListByAppId(appId);
  }

  handleRouteChange(actions, routerAppId, routerPid, pathname, exceptionType) {
    // console.log(this.props.currentApp.toJS(), this.props.currentApp.toJS().appId, this.props.currentApp.toJS().pid, 'handleRoute的数据传输');
    console.log(this.props.appList.toJS(), 'handleRoute的数据传输');
    console.log(this.props.globalError.toJS(), 'handleRoute的数据传输');
    // if (!this.props.currrentApp.toJs().isActive) {
    //   actions.setError(404, '你查看的产品已失活');
    // }
    console.log(routerAppId, '数据的信息有没有呢');
    if (routerAppId) {
      // console.log(currentApp, 'CURRENTAPP');
      actions.checkIsDemo(routerAppId);
      actions.fetchWebAppSettings(routerAppId);
      actions.fetchServerAppSettings(routerAppId);
      actions.fetchBugTrackingInfoByApp(routerAppId);
      actions.fetchTapdWorkspaceIdByApp(routerAppId, routerPid);
      actions.hideLoading();
      this.handleSelectApp({
        actions, routerAppId, routerPid, exceptionType,
      }, () => this.handleRouteChange2({
        appId: routerAppId, pid: routerPid, pathname, exceptionType,
      }));
    } else {
      let currentApp = null;
      if (this.props.currentApp && this.props.currentApp.size) {
        currentApp = this.props.currentApp;
      } else {
        // currentApp = this.props.appList && this.props.appList.size && this.props.appList.get(0);
      }
      console.log(currentApp, 'CURRENTAPP');
      if (currentApp) {
        const { appId, pid } = currentApp.toJS();
        actions.checkIsDemo(appId);
        actions.fetchWebAppSettings(appId);
        actions.selectApp({ appId, pid, exceptionType });
        console.log(appId, '传递过去的appId');
        this.handleRouteChange2({
          appId, pid, pathname, exceptionType,
        });
      }
    }
  }

  handleRouteChange2({
    appId, pid, pathname, exceptionType,
  }) {
    const activeApp = this.props.appList.toJS().filter(item => item.appId === appId)[0];
    const { actions } = this.props;
    if (activeApp && activeApp.isActive === false) {
      actions.setError(555, '你查看的产品已经失活了');
    }
    console.log(`${TAG} !handleRouteChange: pathname=${pathname}, appId=${appId}, pid=${pid} exceptionType=${exceptionType}`);

    actions.checkIsDemo(appId);
    actions.updateOperatorRight();

    pathname = pathname[pathname.length - 1] === '/' ? pathname.substring(0, pathname.length - 1) : pathname;
    switch (pathname) {
      case '':
      case '/':
      case 'crash-reporting/dashboard':
        actions.replaceState(`/crash-reporting/dashboard/${appId}?pid=${pid}`);
        break;
      case 'crash-reporting/crashes':
      case 'crash-reporting/blocks':
      case 'crash-reporting/errors':
        actions.replaceState(`/crash-reporting/${exceptionType || 'crashes'}/${appId}?pid=${pid}`);
        break;
      case 'analytics':
      case 'analytics/dashboard':
      case 'analytics/dashboard/index':
        actions.replaceState(`/analytics/dashboard/${appId}?pid=${pid}`);
        break;
      case 'summary/index':
        actions.replaceState(`/crash-reporting/dashboard/${appId}?pid=${pid}`);
        break;
      case 'crash':
      case 'crash/index':
      case 'anr':
      case 'anr/index':
      case 'error':
      case 'error/index':
        actions.replaceState(`/${exceptionType || 'crash'}/apps/${appId}?pid=${pid}`);
        break;
      case 'userdata':
      case 'userdata/summary':
      case 'userdata/summary/index':
      case 'userdata/summary/apps':
        actions.replaceState(`userdata/summary/apps/${appId}?pid=${pid}`);
        break;
      case 'workbench':
        actions.replaceState(`workbench/apps/${this.props.user.get('userId')}`);
        break;
      case 'beta':
      case 'beta/index':
        actions.replaceState(`beta/index/apps/${appId}?pid=${pid}`);
        break;
      default: break;
    }
  }


  // 取消灰度的逻辑
  async handleSelectApp(nextProps, callback) {
    const {
      actions, routerAppId, routerPid, exceptionType,
    } = nextProps;
    await actions.selectApp({ appId: routerAppId, pid: routerPid, exceptionType });
    callback && callback();
  }

  // scroll event
  handleScroll(e) {
    if (!this.state.ele) {
      this.setState({
        ele: e.target,
      });
    }
    if ((e.target.scrollTop > 112 && !this.state.scroll) || (e.target.scrollTop < 112 && this.state.scroll)) {
      this.setState({
        scroll: !this.state.scroll,
      });
    }
  }

  handleMouseEnter() {
    this.setState({
      scroll: false,
    });
  }

  handleMouseLeave() {
    if (!this.state.ele) return;
    const { ele } = this.state;
    if (!this.state.scroll && ele.scrollTop > 112) {
      this.setState({
        scroll: true,
      });
    }
  }

  /**
   * 是否显示错误提示
   */
  isShowError() {
    return this.props.failureType === ERR_NO_PERMISSION || this.props.failureType === ERR_TOKEN_EXPIRED;
  }

  async onChangeAppByAppSelect(appId, pid) {
    this.setState({ isPreparingWhenCurrentAppChanged: true });
    const { pathname, actions } = this.props;
    const exceptionType = getExceptionType(pathname) || 'crashes';
    console.log(`App -> handleSelectApp: exceptionType: ${exceptionType}, ${appId}, ${pathname}`);
    actions.resetSearchParams({ appId, pid, exceptionType });
    actions.pushState(`/crash-reporting/dashboard/${appId}?pid=${pid}`);
    actions.selectApp({ appId, pid, exceptionType });
    actions.updateOperatorRight();
    actions.checkIsDemo(appId);
  }

  renderErrorTip() {
    const { failureType } = this.props;
    if (failureType === ERR_TOKEN_EXPIRED) {
      alert('ERR_TOKEN_EXPIRED, 请刷新页面重试或联系系统管理员');
      return null;
    } if (failureType === 'TOO_MANY_REQUESTS') {
      this.props.actions.setError(429, '请求频率超出限制，请稍后再试');
      return null;
    } if (this.props.message !== '未知错误: "ok"!') {
      if (this.state.showTip) {
        alert(`发生错误，请重新打开页面并联系管理员。错误提示信息：${this.props.message}`);
      }
      return null;
    }
  }

  getBillingExpireWarningDom() {
    try {
      const { currentApp } = this.props;
      if (isNullish(currentApp.get('billingAccountExpireTime'))) {
        return null;
      }
      const expireDatetime = currentApp.get('billingAccountExpireTime');
      const expireMillis = moment(expireDatetime).valueOf();
      const nowMillis = +new Date();
      let type;
      let message;
      if (expireMillis > nowMillis) {
        type = 'warning';
        message = `项目关联的计费账户额度已消耗完，将于 ${expireDatetime} 冻结数据上报等项目功能。请及时续费以免影响正常使用。`;
      } else {
        type = 'error';
        message = '项目关联的计费账户额度已消耗完，当前账户及所有关联项目已经冻结。续费后将恢复正常使用。';
      }
      return <Alert style={{ height: '30px' }} message={message} type={type}/>;
    } catch (e) {
      console.error('getBillingExpireWarningDom failed', e);
      return null;
    }
  }

  getAppWarningDom() {
    if (this.state.blackAppWarningMessage) {
      return <Alert style={{ height: '30px' }} message={this.state.blackAppWarningMessage} type='error'/>;
    }
    return null;
  }

  getSdkDisabledWarningDom() {
    const { currentApp } = this.props;
    if (!currentApp || !currentApp.get('isSdkDisabled')) {
      return null;
    }
    return <Alert
      style={{ height: '30px' }}
      message={ze('当前项目已设置停止SDK初始化。所有客户端将停止上报数据。', 'The current app is set to stop SDK initialization. All clients will stop reporting data.')}
      type='error'
    />;
  }

  render() {
    const {
      reduxState,
      children, user, currentApp,
      pathname, reportUrl, actions,
      globalError, uploadPopAlertStatus, appList,
      localStorage,
    } = this.props;

    const hasViewAppGeneralConfigPermission = selectHasSpecifiedPermissionInCurrentApp(reduxState, RolePermission.VIEW_APP_GENERAL_CONFIG);

    const isScreenshotVisit = this.getIsScreenshotVisit(); // 是否是截图服务在访问页面

    const { isLeftMenuHovered, isLeftMenuAutoCollapsed, isGlobalNotification } = this.state;
    const isAppView = ['/crash-reporting', '/product'].some(x => pathname.includes(x));

    const hasSubheader = (pathname.includes('/preferences') || pathname.includes('/product/apps')) && hasViewAppGeneralConfigPermission;

    const isAtWelcomePage = pathname.startsWith('/welcome');
    const hasHeader = !isAtWelcomePage;

    const isLeftMenuManuallyCollapsed = localStorage.get('isLeftMenuManuallyCollapsed');

    const isLeftMenuCollapsed = isLeftMenuManuallyCollapsed || isLeftMenuAutoCollapsed;

    let headerLeft = 0;
    let headerWidth = '100vw';
    if (isAppView) {
      headerLeft = isLeftMenuCollapsed ? CS_STYLES.COLLAPSED_LEFT_MENU_WIDTH : CS_STYLES.LEFT_MENU_WIDTH;
      headerWidth = isLeftMenuCollapsed ? `calc(100vw - ${CS_STYLES.COLLAPSED_LEFT_MENU_WIDTH})` : `calc(100vw - ${CS_STYLES.LEFT_MENU_WIDTH})`;
    }

    const billingExpireWarningDom = isAppView && this.getBillingExpireWarningDom();
    const blackAppWarningDom = isAppView &&  this.getAppWarningDom();
    const sdkDisabledWarningDom = isAppView && this.getSdkDisabledWarningDom();
    const showWarningDom = billingExpireWarningDom || blackAppWarningDom || sdkDisabledWarningDom;

    const headerLeftPadding = hasSubheader ? `calc(${headerLeft} + ${CS_STYLES.LEFT_SUB_MENU_WIDTH})` : headerLeft;
    const headerTopPadding = isGlobalNotification && hasHeader
    ? `calc(${CS_STYLES.MAIN_HEADER_HEIGHT} + ${CS_STYLES.GLOBAL_NOTIFICATION})` : hasHeader
    ? CS_STYLES.MAIN_HEADER_HEIGHT : isGlobalNotification ? CS_STYLES.GLOBAL_NOTIFICATION : '0px';
    const finalPaddingTop = showWarningDom
    ? `calc(${headerTopPadding} + 30px)`
      : headerTopPadding;
    const menuContainerPaddingTop = isGlobalNotification && showWarningDom
    ? `calc(${CS_STYLES.GLOBAL_NOTIFICATION} + 30px)`
      : isGlobalNotification
    ? CS_STYLES.GLOBAL_NOTIFICATION
      : showWarningDom
    ? '30px'
      : null;
    return (
      globalError.get('hasError') ? (
          <div className={style.content}>
            <NotificationSystem ref="notify" style={notifyStyle} />
          </div>
      )
        : (
          <div className={classnames(style.crashsight)}>
            <GlobalNotificationBar
              reduxState={reduxState}
              actions={actions}
              onChangeVisible={v => this.setState({ isGlobalNotification: v })}
            />
            {hasHeader && <CrashSightHeader
              style={{
                overflowX: 'auto',
                position: 'fixed',
                zIndex: 200,  // 解决antd某些组件比如radio的z-index设置了1，导致显示到header前面去了的问题
                top: isGlobalNotification ? CS_STYLES.GLOBAL_NOTIFICATION : 0,
                left: headerLeft,
                width: headerWidth,
                transition: isAppView ? 'left 0.3s, width 0.3s' : null,
                // boxShadow: '0 2px 16px 0 rgba(0,0,0,0.05)',
              }}
              isAppView={isAppView}
              isScreenshotVisit={isScreenshotVisit}
              currentUser={user}
              currentApp={currentApp}
              appList={appList}
              actions={actions}
              selectApp={this.onChangeAppByAppSelect.bind(this)}
            />}
            { isAppView && <CrashSightLeftMenu
              pathname={pathname}
              currentApp={currentApp}
              manuallyCollapsed={isLeftMenuManuallyCollapsed}
              autoCollapsed={isLeftMenuAutoCollapsed}
              isGlobalNotification={isGlobalNotification}
              onChangeManuallyCollapsed={v => actions.setPartialLocalStorage({ isLeftMenuManuallyCollapsed: v })}
              onChangeAutoCollapsed={v => this.setState({ isLeftMenuAutoCollapsed: v })}
              hovered={isLeftMenuHovered}
            /> }
            <div style={{
              paddingLeft: headerLeftPadding,
              paddingTop: finalPaddingTop,
              minHeight: isAppView ? `calc(100vh - 48px)` : undefined, // 留48px给copyright
            }}>{ (!this.props.routerAppId || !this.state.isPreparingWhenCurrentAppChanged)
              ? children
              : null
            }</div>
            { isAppView && <div style={{ paddingLeft: headerLeftPadding }}><CopyRight/></div> }
            { showWarningDom && <div style={{
              position: 'fixed',
              zIndex: 300,  // 解决antd某些组件比如radio的z-index设置了1，导致显示到header前面去了的问题
              top: isGlobalNotification ? `calc(${CS_STYLES.MAIN_HEADER_HEIGHT} + ${CS_STYLES.GLOBAL_NOTIFICATION})` : CS_STYLES.MAIN_HEADER_HEIGHT,
              left: headerLeft,
              width: headerWidth,
            }}>
              { billingExpireWarningDom }
              { blackAppWarningDom }
              { sdkDisabledWarningDom }
            </div> }
            { hasSubheader && <div className={style.subMenuContainer} style={{left:headerLeft,paddingTop:menuContainerPaddingTop}}>
              <PreferencesSubMenu
                pathname={pathname}
                currentApp={currentApp}
              />
            </div> }
            <NotificationSystem ref="notify" style={notifyStyle} />
            <IssueEditStatusModal/>
            {uploadPopAlertStatus && <UploadPopAlert closeWindow={actions.inactiveUploadPopAlert} />}
            {reportUrl && <ReportImg reportUrl={reportUrl} />}
            {this.isShowError() && this.renderErrorTip() }
            {
              !isAtWelcomePage && <WatermarkAppContainerDiv />
            }
          </div>
        )
    );
  }
}
