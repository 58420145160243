import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Input, InputNumber, Divider, Radio, Dropdown, Spin, Menu, Modal, Tooltip, Popover } from 'antd';
import style from './CrashSightLeftMenu.scss';
import { PLATFORM_ID } from 'utils/constants';
import { useTranslation } from 'react-i18next';
// import CsLogo from 'images/cs.png';
import Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';
// import dashboardLeftLogo from 'images/v2/newcs_dashboard_left_logo.png';
import CsLogo from 'svg/v2/newcs_dashboard_textlogo2.svg';
import { MdOutlineDashboard, MdLegendToggle, MdTroubleshoot, MdConstruction, MdOutlineSettings, MdOutlineDashboardCustomize } from 'react-icons/md';

import dashboardLeftLogoDownsize from 'images/v2/newcs_dashboard_left_logo2.png';
import OverviewIconNormal from 'svg/v2/newcs_dashboard_left_overview_icon_normal.svg';
import VersiontrendIconNormal from 'svg/v2/newcs_dashboard_left_versiontrend_icon_normal.svg';
import CrashIconNormal from 'svg/v2/newcs_dashboard_left_crash_icon_normal.svg';
import AnrIconNormal from 'svg/v2/newcs_dashboard_left_anr_icon_normal.svg';
import ErrorIconNormal from 'svg/v2/newcs_dashboard_left_error_icon_normal.svg';
import AdvancedsearchIconNormal from 'svg/v2/newcs_dashboard_left_advancedsearch_icon_normal.svg';
import OomIconNormal from 'svg/v2/newcs_dashboard_left_oom_icon_normal.svg';
import FeatureanalysisIconNormal from 'svg/v2/newcs_dashboard_left_featureanalysis_icon_normal.svg';
import EfficiencytoolIconNormal from 'svg/v2/newcs_dashboard_left_efficiencytool_icon_normal.svg';
import ConfigurationIconNormal from 'svg/v2/newcs_dashboard_left_configuration_icon_normal.svg';
import SettingIconNormal from 'svg/v2/newcs_dashboard_left_setting_icon_normal.svg';
import TutorialIconNormal from 'svg/v2/newcs_dashboard_left_tutorial_icon_normal.svg';
import Document_icon_normal from 'svg/v2/newcs_dashboard_left_document_icon_normal.svg';
import PackupArrowNormal from 'svg/v2/newcs_dashboard_left_packup_arrow_normal.svg';
import Arrow from 'svg/v2/newcs_dashboard_left_arrow.svg';
import CompareTrendSvg from 'svg/compare_trend.svg';
import InsightAnalysisIcon from 'svg/v2/newcs_dashboard_left_statisticalanalysis_icon_normal.svg'

import { isAndroidOrHarmony, isGameConsole, isMobile, isSwitch, PlatformUtil } from 'utils/platform';
import { VerticalLeftOutlined, VerticalRightOutlined } from '@ant-design/icons';
import {
  selectFrontendWebVolatileConfigValueByKey,
} from 'utils/selectors/selectors';
import { connect } from 'react-redux';
import {
  FrontendWebVolatileConfigKeys,
} from 'utils/constants/frontend-web-volatile-config';
import { PreferencesSubMenuUtil } from 'components/top/PreferencesSubMenu';
import { ServerAppSettings } from 'utils/server-app-settings';
import { UserTypeEnum } from 'utils/constants/user-type-enum';
import { ze } from 'utils/zhEn';
import { EnvUtil } from 'utils/env-util';
import { useSize } from 'ahooks';
import { MenuItem } from './MenuItem';
import { CS_STYLES } from 'utils/constants/style-constants';
import { SubAppType } from 'utils/constants/sub-app-type';

const AUTO_COLLAPSE_ENABLE_WIDTH = 1500;
const AUTO_COLLAPSE_DISABLE_WIDTH = 1600;

const CrashSightLeftMenu = ({
  reduxState,
  pathname,
  currentApp,
  manuallyCollapsed,
  autoCollapsed,
  onChangeManuallyCollapsed,
  onChangeAutoCollapsed,
  hovered,
  selectFrontendWebVolatileConfigValueByKey,
  isGlobalNotification,
  localStorage,
}) => {
  const { t } = useTranslation();

  const { width: bodyWidth } = useSize(document.body) || {};
  useEffect(() => {
    if (bodyWidth < AUTO_COLLAPSE_ENABLE_WIDTH && !autoCollapsed) {
      onChangeAutoCollapsed(true);
    } else if (bodyWidth > AUTO_COLLAPSE_DISABLE_WIDTH && autoCollapsed) {
      onChangeAutoCollapsed(false);
    }
  }, [bodyWidth]);
  const collapsed = manuallyCollapsed || autoCollapsed;

  const currentAppJs = currentApp.toJS();
  const {
    appId,
    platformId: pid,
    appName,
    demoApp,
    accountId,
    subAppType,
  } = currentAppJs;
  const isNetmarble = accountId === '10050690181558001'; // 临时对netmarble账户下的所有项目，禁止效率工具

  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};

  const enableOom = serverAppSettings[ServerAppSettings.keys.enableOom];
  const enableFeatureAnalysis = serverAppSettings[ServerAppSettings.keys.enableFeatureAnalysis];
  const enableInsightPage = serverAppSettings[ServerAppSettings.keys.enableInsightPage];
  const enableIssueStatusReopened = serverAppSettings[ServerAppSettings.keys.enableIssueStatusReopened];

  const currentUser = reduxState.user.get('current').toJS();
  const isSuper = !!currentUser.isSuper;
  // const isCompareTrendVisible = !!serverAppSettings[ServerAppSettings.keys.enableAdvancedTrend];

  const isStatsViewer = currentUser.type === UserTypeEnum.STATS_VIEWER;

  const customFeatureAppIdWhitelist = (selectFrontendWebVolatileConfigValueByKey(FrontendWebVolatileConfigKeys.CUSTOM_FEATURE_APP_ID_WHITELIST) || '')
    .split(',')
    .map(x => x.trim())
    .filter(x => x);
  const isCustomFeatureAvailable = enableFeatureAnalysis || customFeatureAppIdWhitelist.includes(appId);

  const oomAppNameWhitelist = (selectFrontendWebVolatileConfigValueByKey(FrontendWebVolatileConfigKeys.OOM_APP_NAME_WHITELIST) || '')
    .split(',')
    .map(x => x.trim())
    .filter(x => x);
  const isOomAvailable = enableOom || (isMobile(pid) && oomAppNameWhitelist.some(x => (appName || '').includes(x)));

  const excAnalysisCommonSearch = `?${new URLSearchParams({
    pid,
    status: enableIssueStatusReopened ? '0,2,4' : '0,2',
  }).toString()}`;

  const preferencesLinkTo = useMemo(() => PreferencesSubMenuUtil.makeMenuItems({ currentAppJs, serverAppSettings, currentUserJs: currentUser })[0].linkTo, [appId, pid, serverAppSettings, reduxState.user]);

  // 将右侧滚动条的位置置顶
  const handleScrollToTop = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
      window.scrollTo(0, 0);
    }
  };

  let tutorialSubAppType = undefined;
  if (subAppType === SubAppType.UNITY) {
    tutorialSubAppType = 'UNITY';
  } else if (subAppType === SubAppType.UE4 || subAppType === SubAppType.UE5) {
    tutorialSubAppType = 'UNREAL';
  }

  const menuItems = [{
    available: true,
    label: t('EXCP_LEFTMENU.overview'),
    labelAbridge: ze('概览', 'Overview'),
    pathPrefixes: ['/crash-reporting/dashboard'],
    linkTo: { pathname: `/crash-reporting/dashboard/${appId}`, search: `?pid=${pid}` },
    svg: <MdOutlineDashboard style={{ width: '24px', height: '24px' }} />,
  }, {
    available: true,
    disabled: isStatsViewer,
    label: t('EXCP_LEFTMENU.versionDashboard'),
    labelAbridge: ze('趋势', 'Trend'),
    pathPrefixes: ['/crash-reporting/version-dashboard'],
    linkTo: { pathname: `/crash-reporting/version-dashboard/${appId}`, search: `?pid=${pid}` },
    svg: <MdLegendToggle style={{ width: '28px', height: '28px' }} />,
  }, {
    available: PlatformUtil.isCrashAvailable(pid),
    label: t('EXCP_LEFTMENU.crashAnalysis'),
    labelAbridge: ze('崩溃', 'Crash'),
    pathPrefixes: ['/crash-reporting/crashes'],
    linkTo: { pathname: `/crash-reporting/crashes/${appId}`, search: excAnalysisCommonSearch },
    svg: <CrashIconNormal width="20" height="20" viewBox="0 0 20 20"/>,
  }, {
    available: PlatformUtil.isAnrAvailable(pid),
    label: isAndroidOrHarmony(pid) ? t('EXCP_LEFTMENU.anrAnalysis') : t('EXCP_LEFTMENU.jankAnalysis'),
    labelAbridge: isAndroidOrHarmony(pid) ? t('common.anr') : t('common.jank'),
    pathPrefixes: ['/crash-reporting/blocks'],
    linkTo: { pathname: `/crash-reporting/blocks/${appId}`, search: excAnalysisCommonSearch },
    svg: <AnrIconNormal width="20" height="20" viewBox="0 0 20 20"/>,
  }, {
    available: true,
    label: t('EXCP_LEFTMENU.errorAnalysis'),
    labelAbridge: ze('错误', 'Error'),
    pathPrefixes: ['/crash-reporting/errors'],
    linkTo: { pathname: `/crash-reporting/errors/${appId}`, search: excAnalysisCommonSearch },
    svg: <ErrorIconNormal width="20" height="20" viewBox="0 0 20 20" />,
  }, {
    available: isOomAvailable,
    label: ze('OOM分析', 'OOM'),
    labelAbridge: 'OOM',
    pathPrefixes: ['/crash-reporting/oom', '/crash-reporting/model-oom'],
    svg: <OomIconNormal width="20" height="20" viewBox="0 0 20 20" />,
    linkTo: { pathname: `/crash-reporting/oom/${appId}`, search: `?pid=${pid}` },
  }, {
    available: true,
    label: t('EXCP_LEFTMENU.search'),
    labelAbridge: ze('搜索', 'Search'),
    pathPrefixes: ['/crash-reporting/advanced-search'],
    linkTo: { pathname: `/crash-reporting/advanced-search/${appId}`, search: `?pid=${pid}`, state: { leftMenuClickTrigger: Math.random() } },
    svg: <MdTroubleshoot style={{ width: '25px', height: '25px' }} />,
  }, {
    available: enableInsightPage,
    disabled: isStatsViewer,
    label: t('EXCP_LEFTMENU.insight'),
    labelAbridge: ze('洞察', 'Insight'),
    pathPrefixes: ['/crash-reporting/insight'],
    linkTo: { pathname: `/crash-reporting/insight/${appId}`, search: `?pid=${pid}` },
    svg: <InsightAnalysisIcon />,
  }, {
    available: isCustomFeatureAvailable,
    disabled: isStatsViewer,
    label: t('EXCP_LEFTMENU.featureAnalysis'),
    labelAbridge: ze('特征', 'Features'),
    pathPrefixes: ['/crash-reporting/feature'],
    linkTo: { pathname: `/crash-reporting/feature-stat-result/${appId}`, search: `?pid=${pid}` },
    svg: <MdOutlineDashboardCustomize style={{ width: '24px', height: '24px' }} />,
  }, {
    available: appId && !isNetmarble, // appId这个条件的目的是防止appInfo没加载好的时候用户能看到这个选项
    disabled: isStatsViewer,
    label: t('EXCP_LEFTMENU.EfficiencyTool'),
    labelAbridge: ze('工具', 'Tools'),
    pathPrefixes: ['/crash-reporting/online'],
    linkTo: { pathname: `/crash-reporting/online-retrace/${appId}`, search: `?pid=${pid}` },
    svg: <MdConstruction style={{ width: '24px', height: '24px' }} />,
  }, {
    available: true,
    disabled: isStatsViewer,
    label: t('NAVBAR.setting'),
    labelAbridge: ze('设置', 'Settings'),
    pathPrefixes: ['/crash-reporting/preferences', '/product/apps/'],
    linkTo: preferencesLinkTo,
    svg: <MdOutlineSettings style={{ width: '24px', height: '24px' }} />,
  // }, {
  //   available: !demoApp,
  //   disabled: isStatsViewer,
  //   label: t("NAVBAR.setting"),
  //   labelAbridge: ze('设置','Setting'),
  //   pathPrefixes: ['/product/apps/'],
  //   linkTo: { pathname: `/product/apps/${appId}`, search: `?pid=${pid}` },
  //   svg: <SettingIconNormal />,
  }, {
    available: true,
    customElement: true,
    content: <div
      className={style.divider}
      style={{ margin: collapsed ? '2px 8px 10px 8px' : '6px 20px 14px 20px' }}
    />,
  }, {
    available: true,
    label: t('EXCP_LEFTMENU.接入引导'),
    labelAbridge: ze('指南', 'Tutorial'),
    pathPrefixes: ['/crash-reporting/tutorial/'],
    linkTo: { pathname: `/crash-reporting/tutorial/${appId}`, search: tutorialSubAppType ? `?pid=${pid}&subAppType=${tutorialSubAppType}` : `?pid=${pid}` },
    svg: <TutorialIconNormal />,
  }].filter(x => x.available);

  function getMenuItemClassName(menuItem) {
    const { pathPrefixes, disabled, children = [] } = menuItem;
    if (disabled) {
      return [style.menuItem, style.menuItemDisabled].join(' ');
    }
    if (children.length) {
      return pathPrefixes.some(x => (pathname || '').includes(x))
        ? [style.menuItem, style.parentMenu, style.parentMenuSelected].join(' ')
        : [style.menuItem, style.parentMenu].join(' ');
    }
    if (menuItem.isChild) {
      return pathPrefixes.some(x => (pathname || '').includes(x))
        ? [style.submenuItem, style.submenuItemSelected].join(' ')
        : [style.submenuItem].join(' ');
    }
    return pathPrefixes.some(x => (pathname || '').includes(x))
      ? [style.menuItem, style.menuItemSelected].join(' ')
      : style.menuItem;
  }

  const showCollapsed = collapsed && !hovered;

  const nowMillis = +new Date(); // 加个时间戳query参数，防止2.0用户点logo看到了缓存的1.0首页
  const logoClickHref = `/welcome?t=${nowMillis}`;


  return <div className={
    showCollapsed ? [style.crashSightLeftMenu, style.collapsed].join(' ') : style.crashSightLeftMenu
  }
  style={isGlobalNotification ? { top: `${CS_STYLES.GLOBAL_NOTIFICATION}` } : {}}
  >
    <div className={showCollapsed ? style.collapsedLogoContainer : style.logoContainer}>
      { collapsed
        ? <a href={ logoClickHref } style={{ height: '12px', display: 'flex', alignItems: 'center' }}>
           <CsLogo width="64" height="12" viewBox="0 0 120 24" style={{ color: '#393157' }}/>
        </a>
        : <a href={ logoClickHref }>
          <span style={{ display: 'block', objectFit: 'cover', marginLeft: '32px' }}>
            <CsLogo style={{ color: '#393157' }}/>
          </span></a>
      }
    </div>
    <div className={style.menuContainer}>{
      menuItems.map((x, i) => {
        if (x.customElement) {
          return <div key={i}>{x.content}</div>;
        }
        const selectedState = x.pathPrefixes.some(x => (pathname || '').includes(x));
        const inner = <React.Fragment>
          <div
            style={{ width: '4px', height: '48px', background: selectedState ? CS_STYLES.PRIMARY_COLOR : '' }}
          ></div>
          <div style={{
            display: 'flex',
            height: '48px',
            width: '212px',
            fontWeight: selectedState ? 700 : 400,
            backgroundColor: selectedState ? 'rgba(var(--cs-primary-color-rgb-params), 0.2)' : '',
            color: selectedState ? 'var(--cs-primary-color-alt)' : '',
            borderRadius: '8px',
            margin: !showCollapsed ? '0 12px' : '0 8px 0 4px',
            alignItems: 'center',
            ...showCollapsed ? { justifyContent: 'center', width: '72px' } : {},
            position: 'relative',
          }}
          >
            <div>
              { !showCollapsed
                ? <div className={style.iconBorder} style={{ border: selectedState ? '' : '1px solid #D6CAC9' }}>
                {x.svg}
              </div> : <div style={{ display: 'grid', placeItems: 'center' }}>
                <div>
                {x.svg}
                </div>
                <div style={{ fontSize: '12px', textAlign: 'center', lineHeight: '12px', marginTop: '4px' }}>{ x.labelAbridge }</div>
              </div>}
            </div>
            { !showCollapsed && <div style={{
              marginLeft: '8px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}>{ x.label }</div> }
            {/* {selectedState && !showCollapsed && <div style={{position:'absolute',right:'10px'}}>
              <Arrow />
            </div>} */}
          </div>
        </React.Fragment>;

        if (x.disabled) {
          return <Tooltip title={ze('没有权限', 'No Permission')} placement='right'>
            <div key={i} className={getMenuItemClassName(x)}>{ inner }</div>
          </Tooltip>;
        }
        return <div>
          <MenuItem
            key={x.label}
            menuOption={x}
            showCollapsed={showCollapsed}
            getMenuItemClassName={getMenuItemClassName}
            handleScrollToTop={handleScrollToTop}
            pathname={pathname}
            selectedState={selectedState}
          ></MenuItem>
        </div>;
        // return <div>
        //   {!showCollapsed ? <div style={{}}>
        //     <Link
        //       key={i}
        //       className={getMenuItemClassName(x)}
        //       to={x.linkTo}
        //       onClick={handleScrollToTop}
        //       style={{ marginTop:'12px'}}
        //     >
        //       { inner }
        //     </Link>
        //   </div> : <Popover placement="right" content={x.label} overlayStyle={{ paddingLeft: '10px', position: 'fixed' }}>
        //     <Link
        //       key={i}
        //       className={getMenuItemClassName(x)}
        //       to={x.linkTo}
        //       onClick={handleScrollToTop}
        //       style={{ marginTop:'4px'}}
        //     >
        //       { inner }
        //     </Link>
        //   </Popover>}
        // </div>;
      })
    }</div>
    { !autoCollapsed && <div
      className={style.footer}
      style={ collapsed ? {} : { justifyContent: 'end' } }
    >{
      collapsed
        ? <div
            style={{ marginLeft: '37px', transform: 'rotate(180deg)' }}
            onClick={() => onChangeManuallyCollapsed(false)}
          ><PackupArrowNormal /></div>
        : <div
            style={{ marginRight: '32px', cursor: 'pointer' }}
            onClick={() => onChangeManuallyCollapsed(true)}
          ><PackupArrowNormal /></div>
    }</div> }
  </div>;
};

CrashSightLeftMenu.props = {
  pathname: PropTypes.string.isRequired,
  currentApp: PropTypes.object.isRequired,
  manuallyCollapsed: PropTypes.bool,
  autoCollapsed: PropTypes.bool,
  onChangeManuallyCollapsed: PropTypes.func,
  onChangeAutoCollapsed: PropTypes.func,
  hovered: PropTypes.bool,
  reduxState: PropTypes.object,
};

const mapStateToProps = (state, router) => ({
  reduxState: state,
  selectFrontendWebVolatileConfigValueByKey: key => selectFrontendWebVolatileConfigValueByKey(state, key),
});


export default connect(mapStateToProps)(CrashSightLeftMenu);
