import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
// import { AppContainer } from 'react-hot-loader';
import createBrowserHistory from 'history/createBrowserHistory';
import { ConnectedRouter } from 'connected-react-router';
import configureStore from 'store/configureStore';
import generateRoute from './route';
import 'utils/classList';

import 'styles/cs-theme-color.scss';
import 'styles/amazeui.min.css';
import 'styles/react-select.min.css';
import 'styles/loaders.css';
import 'antd/dist/antd.variable.css';
import 'styles/common.scss';
// import 'styles/cs-antd-custom.less';
import 'styles/antd-hack.scss';

import i18n, { antdLocale } from './i18n';
import moment from 'moment-timezone';
import { ConfigProvider, Modal, notification, Spin } from 'antd';

import { aegisInit } from 'utils/aegis';
import { GoogleAnalytics } from 'utils/google-analytics';
import Cookies from 'js-cookie';
import RestHelper from 'utils/RestHelper';
import isBrowserSupported from 'check-browser-support';
import { ze } from 'utils/zhEn';
import { DEFAULT_TIME_ZONE } from 'utils/time-zone-util';
import { CsLoading } from 'components/antd-extension/CsLoading';
import { EmptyData } from 'components/commons';
import { CS_STYLES } from 'utils/constants/style-constants';
import { ThemeColor, ThemeColorUtil } from 'utils/theme-color-util';

// 初始化前台性能监控
aegisInit();
//  WeTest SEM营销推广追踪需求，初始化Google Analytics
GoogleAnalytics.init();

Spin.setDefaultIndicator(<CsLoading />);

// 检查浏览器兼容情况
if (!isBrowserSupported()) {
  Modal.error({
    title: ze('当前浏览器版本过低', 'Your browser version is too low'),
    content: ze('部分网站功能可能无法正常使用，请升级浏览器到最新版本。', 'Please update your browser to the latest version.'),
  });
}

// 是否是嵌入cros平台用的iframe。现在不对接其他平台了，所以常为false
window.IS_CROS_IFRAME = false;

moment.tz.setDefault(DEFAULT_TIME_ZONE);

// 对于有曾经配过其他域名的环境，如果配置变量里面指定了正式域名(REACT_APP_OFFICIAL_DOMAIN_NAME)，那么从其他域名访问网站，就会触发强制提示跳转。
// 作用是把旧域名上的用户引流到新域名上去。
// 可以配置多个，逗号分隔。
const officialDomainNames = (process.env.REACT_APP_OFFICIAL_DOMAIN_NAME || '').split(',')
  .map(x => x.trim())
  .filter(x => x);
if (officialDomainNames.length > 0 && !officialDomainNames.includes(window.location.hostname) && window.top === window) {
  const firstOfficialDomainName = officialDomainNames[0];
  Modal.info({
    title: i18n.t('平台域名变更通知.title'),
    content: i18n.t('平台域名变更通知.content', { officialDomainName: firstOfficialDomainName }),
    onOk() {
      window.location.hostname = firstOfficialDomainName;
    },
  });
}

// 在body上设置用户自定义主题class
ThemeColorUtil.setThemeClassToBodyByCurrentLocalStorage();

// 是否是本地开发，本地开发的话打开页面弹个提示，这样方便判断有没有成功连上本地
const isLocalDev = process.env.REACT_APP_IS_LOCAL_DEV;
if (isLocalDev) {
  notification.info({ message: '已连接本地开发' });
}

// 为方便界面调试发请求，把RestHelper暴露出来
if (!window.RestHelper) {
  window.RestHelper = RestHelper;
}
// 为方便控制台设置cookie，把js-cookie暴露出来
if (!window.JsCookie) {
  window.JsCookie = Cookies;
}

notification.config({
  top: 100,
});

const browserHistory = createBrowserHistory({
  basename: __BASEPATH__,
});

const initialState = window.__INITIAL_STATE__;
const store = configureStore(initialState, browserHistory, __DEBUG__);
window.__store = store;

export function getAllInOneReduxState() {
  return store ? store.getState() : null;
}

const component = (
  <ConnectedRouter history={browserHistory}>
    {generateRoute(browserHistory)}
  </ConnectedRouter>
);



window.onerror = function (msg, url, line, col, error) {
  let newMsg = msg;
  if (error && error.stack) {
    newMsg = _processStackMsg(error);
  }
  if (_isOBJByType(newMsg, 'Event')) {
    newMsg += newMsg.type ? (`--${newMsg.type}--${newMsg.target ? (`${newMsg.target.tagName}::${newMsg.target.src}`) : ''}`) : '';
  }
  console.warn(`window onerror:  target=${url} rowNum=${line} colNum=${col} \n ${newMsg}`);
  if (error) {
    window.Raven && window.Raven.captureException(error);
  }
};

function _isOBJByType(o, type) {
  return Object.prototype.toString.call(o) === `[object ${type || 'Object'}]`;
}

function _processStackMsg(error) {
  let stack = error.stack.replace(/\n/gi, '').split(/\bat\b/).slice(0, 9).join('@').replace(/\?[^:]+/gi, '');
  const msg = error.toString();
  if (stack.indexOf(msg) < 0) {
    stack = `${msg}@${stack}`;
  }
  return stack;
}

render(
  // <AppContainer>
    <ConfigProvider
      locale={antdLocale}
      renderEmpty={() => <EmptyData text={i18n.t('EXCP_OVERVIEW.noData')}/>} // 全局设置antd table的空数据展示占位
    >
      <Provider store={store}>
        <div>
          {component}
        </div>
      </Provider>
    </ConfigProvider>,
  // </AppContainer>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
