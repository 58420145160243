import { Map, List, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import i18n from 'i18n.js';
import {
  CHANGE_TREND_TAG,
  CHANGE_RANK_TAG,
  CHANGE_PAGE_TYPE,
  CHANGE_SUMMARY_VM_SELECT_VALUE,
  GETSELECTOR_GET_SUCC,
  GET_TREND_SUCC,
  CHANGE_TREND_PARAMS,
  CHANGE_TOP3_STATUS,
  GET_HOURLY_STAT_SUCC,
  APP_RATE_SUCC,
  APP_ACCESS_SUCC,
  SYSTEM_RATE_SUCC,
  SYSTEM_ACCESS_SUCC,
  MODEL_ACCESS_SUCC,
  MODEL_RATE_SUCC,
  TAG_ACCESS_SUCC,
  TAG_RATE_SUCC,
  GPU_RATE_SUCC,
  GPU_ACCESS_SUCC,
  CPU_RATE_SUCC,
  CPU_ACCESS_SUCC,
  REALTIMEAPPEND_FETCH_SUCC,
  RESET_SEARCH_PARAMS,
  CHANGE_STATE_SUCC,
  CHANGE_DATA_TYPE,
  CHANGE_TOPISSUEDATA_TYPE,
  GET_APPEND_MINUTELY_STAT_SUCC,
  LOCATION_CHANGE,
} from 'store/actions';
import { getNickName, getUserId, parseSearch } from 'utils/helper';
import { getChartTags } from 'utils/StringUtils';
import {
  initialState,
  makeInitialSummarySearchParamsByPid,
  makeSummaryInitialSelectOptions,
} from './summaryInitialState';
import { isNullish } from 'utils/nullish';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';
import { ReduxActionType } from 'reducers/redux-action-type';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import uniqBy from 'lodash.uniqby';
import { getAllInOneReduxState } from 'index';
import { ServerAppSettings } from 'utils/server-app-settings';
import { VmTypeInt } from 'utils/constants/vm-type';


function locationChangeReducer(state, action) {
  const { location } = action.payload;
  const { pathname } = location;
  const query = parseSearch(location);
  const { pid } = query;
  if (isNullish(pid)) {
    console.error('Cannot get pid in location_change');
  }

  const prevPathname = state.get('summaryPagePrevLocationChangePathname');
  console.log('locationChangeReducer', state, pathname, query, prevPathname)
  state = state.set('summaryPagePrevLocationChangePathname', pathname);
  if (isMatchSummaryRoute(pathname)) {
    // pathname发生改变，判定为页面发生切换，重置summary大盘的相关state
    if (prevPathname !== pathname) {
      state = state.set('searchParams', makeInitialSummarySearchParamsByPid(pid))
        .set('selectOptions', makeSummaryInitialSelectOptions(pid))
        .set('realTimeTopIssueData', initialState.get('realTimeTopIssueData'))
        .set('topIssueData', initialState.get('topIssueData'))
        .set(SYSTEM_RATE_SUCC, fromJS([]))
        .set(SYSTEM_ACCESS_SUCC, fromJS([]))
        .set(MODEL_RATE_SUCC, fromJS([]))
        .set(MODEL_ACCESS_SUCC, fromJS([]))
        .set(APP_RATE_SUCC, fromJS([]))
        .set(APP_ACCESS_SUCC, fromJS([]))
        .set(TAG_RATE_SUCC, fromJS([]))
        .set(TAG_ACCESS_SUCC, fromJS([]))
        .set(GPU_RATE_SUCC, fromJS([]))
        .set(GPU_ACCESS_SUCC, fromJS([]))
        .set(CPU_RATE_SUCC, fromJS([]))
        .set(CPU_ACCESS_SUCC, fromJS([]));
    }

    // 修正表示成了string的number型字段
    [
      'isRealTime',
    ].forEach(numberField => {
      if (query[numberField]) {
        query[numberField] = Number(query[numberField]);
      }
    });
    // 修正表示成了string的boolean型字段
    [
      'isIntegratedAppTrend',
      'enableRealtimePrevDayTrend',
      'enableRealtimePrevWeekTrend',
    ].forEach(booleanField => {
      if (query[booleanField]) {
        query[booleanField] = query[booleanField] === 'true' || !!Number(query[booleanField]);
      }
    });

    state = state.update('searchParams', (searchParams) => {
      return Object.keys(query).length
        ? searchParams.merge(query)
        : makeInitialSummarySearchParamsByPid(pid);
    });

    // 用searchParams里面的exceptionCategory填充b**ly遗留的旧pageType字段
    const exceptionCategory = state.getIn(['searchParams', 'exceptionCategory']);
    if (ExceptionCategoryUtil.isCrash(exceptionCategory)) {
      state = state.setIn(['selectOptions', 'pageType'], '崩溃');
    } else if (ExceptionCategoryUtil.isAnr(exceptionCategory)) {
      state = state.setIn(['selectOptions', 'pageType'], '卡顿');
    } else if (ExceptionCategoryUtil.isError(exceptionCategory)) {
      state = state.setIn(['selectOptions', 'pageType'], '错误');
    } else if (ExceptionCategoryUtil.isOom(exceptionCategory)) {
      state = state.setIn(['selectOptions', 'pageType'], 'oom');
    }
  }

  return state;
}


export default handleActions({
  // 这里是调用了issueActiopn里面的方法getRecordSearchOptions
  [GETSELECTOR_GET_SUCC]: (state, action) => {
    console.log('这是一个summary的页面', state, action, 'test');
    const { versionList = [], processorList = [] } = action.response;
    const { pid } = action.params;

    const versionOptions = (versionList || []).map(x => ({
      label: x.enable ? x.name : `${x.name} (Closed)`,
      value: encodeURIComponent(x.name), // bugly的老逻辑，其实没有encode的必要，发请求的时候还要decode，不知道是什么意思
    }));

    const versionTree = getTreeSelectVersionOptions(versionOptions);

    return state.update('selectOptions', (options) => options.merge({
      trendVersion: {
        options: (() => {
          return fromJS(versionOptions).unshift({
            label: i18n.t('versionOptions.全版本'),
            value: '-1',
          });
        })(),
        versionTree,
      },
      rankVersion: {
        options: (() => {
          return fromJS(versionOptions);
        })(),
        versionTree,
      },
      processor: {
        options: new List(uniqBy(processorList, x => getUserId(x))).map((item) => ({
          label: getNickName(item),
          value: encodeURIComponent(getUserId(item)),
          rtx: (item || {}).rtx,
        })),
      },
    }));
  },

  [CHANGE_TREND_TAG]: (state, action) => {
    return state.update('searchParams', (params) => params.set('trendTagName', action.payload));
  },

  [CHANGE_RANK_TAG]: (state, action) => {
    const tagName = action.payload;
    let tagType = 'appTag';
    if (tagName.startsWith('system')) {
      tagType = 'systemTag';
    } else if (tagName.startsWith('app')) {
      tagType = 'appTag';
    } else if (tagName.startsWith('model')) {
      tagType = 'modelTag';
    } else if (tagName.startsWith('gpu')) {
      tagType = 'gpuTag';
    } else if (tagName.startsWith('cpu')) {
      tagType = 'cpuTag';
    } else if (tagName.startsWith('tag')) {
      tagType = 'tagTag';
    }
    return state.update('selectOptions', (options) => options.set(tagType, tagName));
  },

  [CHANGE_SUMMARY_VM_SELECT_VALUE]: (state, action) => {
    const v = action.payload;
    return state.update('searchParams', (o) => o.set('vmSelectValue', v));
  },

  [CHANGE_PAGE_TYPE]: (state, action) => state
    .update('selectOptions', (options) => options.set('pageType', action.payload)),

  [GET_TREND_SUCC]: (state, action) => {
    const trendDataList = action.response.data;
    return state.set(action.params.dataType, fromJS(trendDataList).filter((item) => !!item));
  },

  [GET_HOURLY_STAT_SUCC]: (state, action) => {
    const trendList = action.response.data; // CountryUtil.convertTrendCountryGroupIdToName(state, action.response.data);
    const data = fromJS(trendList);
    return state.set(action.params.dataType, data.filter((item) => !!item));
  },

  [GET_APPEND_MINUTELY_STAT_SUCC]: (state, action) => {
    const trendList = action.response.data; // CountryUtil.convertTrendCountryGroupIdToName(state, action.response.data);
    const data = fromJS(trendList);
    return state.set(action.params.dataType, data);
  },

  [CHANGE_TREND_PARAMS]: (state, action) => {
    const { type, value } = action.payload;
    return state.update('searchParams', (param) => param.set(type, type === 'isRealTime' ? parseInt(value) : value));
  },

  summaryReducerSetTopIssueData: (state, action) => {
    const v = action.payload;
    return state.set('topIssueData', new Map(v));
  },

  [CHANGE_TOP3_STATUS]: (state, action) => state.set('isShowTop3', action.payload),

  [CHANGE_DATA_TYPE]: (state, action) => {
    return state.update('searchParams', (params) => params.set('dataType', action.payload));
  },

  [CHANGE_TOPISSUEDATA_TYPE]: (state, action) => {
    return state.update('searchParams', (params) => params.set('topIssueDataType', action.payload));
  },

  [APP_RATE_SUCC]: (state, action) => saveRankData(state, action, 'version'),
  [APP_ACCESS_SUCC]: (state, action) => saveRankData(state, action, 'version'),
  [MODEL_RATE_SUCC]: (state, action) => saveRankData(state, action, 'model'),
  [MODEL_ACCESS_SUCC]: (state, action) => saveRankData(state, action, 'model'),
  [SYSTEM_RATE_SUCC]: (state, action) => saveRankData(state, action, 'osVersion'),
  [SYSTEM_ACCESS_SUCC]: (state, action) => saveRankData(state, action, 'osVersion'),
  [GPU_RATE_SUCC]: (state, action) => saveRankData(state, action, 'gpu'),
  [GPU_ACCESS_SUCC]: (state, action) => saveRankData(state, action, 'gpu'),
  [CPU_RATE_SUCC]: (state, action) => saveRankData(state, action, 'cpu'),
  [CPU_ACCESS_SUCC]: (state, action) => saveRankData(state, action, 'cpu'),
  [TAG_RATE_SUCC]: (state, action) => saveRankData(state, action, 'tag'),
  [TAG_ACCESS_SUCC]: (state, action) => saveRankData(state, action, 'tag'),

  REALTIMETOPISSUE_FETCH_SUCC: (state, action) => {
    return state.set('realTimeTopIssueData', new Map(action.json.data))
      .update('searchParams', (params) => params.set('realTimeTopIssueLoading', false));
  },

  [REALTIMEAPPEND_FETCH_SUCC]: (state, action) => state.set('realTimeAppendData', fromJS(action.response.data && action.response.data[0] || [])),

  [LOCATION_CHANGE]: (state, action) => locationChangeReducer(state, action),

  [RESET_SEARCH_PARAMS]: (state, action) => {
    const { appId, pid, exceptionType } = action.payload;
    return state.update('searchParams', () => makeInitialSummarySearchParamsByPid(pid));
  },

  [ReduxActionType.resetAppRelatedStateWhenAppChanged]: (state, action) => {
    const { appId, platformId } = action.payload;
    const reduxState = getAllInOneReduxState();
    const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
    const dashboardDefaultVmType = serverAppSettings[ServerAppSettings.keys.dashboardDefaultVmType] || VmTypeInt.ALL;
    let newState = state.update('searchParams', () => makeInitialSummarySearchParamsByPid(platformId, dashboardDefaultVmType));
    // TODO: 这里通过触发一下LOCATION_CHANGE这个reducer实现设置query参数到searchParams里面
    action.payload.location = window.location;
    return locationChangeReducer(newState, action);
  },

  UPDATE_SUMMARY_SEARCH_PARAMS: (state, action) => {
    const v = action.payload;
    return state.update('searchParams', x => x.merge(v));
  },

  updateSummarySelectOptions: (state, action) => {
    const v = action.payload;
    return state.update('selectOptions', x => x.merge(v));
  },

  [ReduxActionType.patchSummaryState]: (state, action) => {
    const v = action.payload;
    return state.merge(v);
  },

  SET_COUNTRY_GROUP_CONFIG_MODAL_VISIBLE: (state, action) => {
    const v = action.payload;
    return state.set('countryGroupConfigModalVisible', v);
  },

  [CHANGE_STATE_SUCC]: (state, action) => {
    let newState = state;
    ['topIssueData', 'realTimeTopIssueData'].forEach((field) => {
      newState = newState.update(field, (topIssueData) => topIssueData.update('topIssueList', (topIssueList) => {
        if (!Array.isArray(topIssueList)) {
          return topIssueList;
        }
        return topIssueList.map((issue) => {
          if (issue.issueId === action.params.issueIds) {
            issue.issueInfo.assigneeList = action.params.assigneeWithoutLocalUserIdList;
            issue.state = action.params.status;
          }
          return issue;
        });
      }));
    });
    return newState;
  },

  COUNTRYGROUPCONFIG_FETCH_SUCC: (state, action) => {
    const v = action.json.data;
    return state.set('countryGroupConfigList', fromJS(v));
  },

  FETCHSDKINTEGRATEDAPPS_FETCH_SUCC: (state, action) => {
    const options = action.json.data.map(x => ({
      label: x.name,
      value: x.appId,
    }));
    return state.setIn(['selectOptions', 'integratedAppOptions'], options);
  },

  FETCHSDKINTEGRATEDAPPVERSIONS_FETCH_SUCC: (state, action) => {
    const { integratedAppId } = action.params;
    const options = action.json.data.map(x => ({
      label: x,
      value: x,
    })).filter(x => x.value !== '-1');
    const optionsWithAllVersion = [
      ...options,
      { label: '全版本', value: '-1' },
    ];
    return state.updateIn(['selectOptions', 'integratedAppIdToVersionOptions'], (integratedAppIdToVersionOptions) => {
        return {
          ...integratedAppIdToVersionOptions,
          [integratedAppId]: optionsWithAllVersion,
        };
      })
      .updateIn(['selectOptions', 'integratedAppIdToVersionTree'], (integratedAppIdToVersionTree) => {
        return {
          ...integratedAppIdToVersionTree,
          [integratedAppId]: getTreeSelectVersionOptions(options),
        };
      });
  },

  TAG_ADD_SUCC: (state, action) => handleAddOrDelTagSucc(state, action),
  TAG_DEL_SUCC: (state, action) => handleAddOrDelTagSucc(state, action),

}, initialState);


function handleAddOrDelTagSucc(state, action) {
  let newState = state;
  ['topIssueData', 'realTimeTopIssueData'].forEach((field) => {
    const topIssueList = newState.getIn([field, 'topIssueList']);
    if (!topIssueList) {
      return;
    }

    const issueId = action.params.issueId;
    const { tagInfoList } = action.response;

    const newTopIssueList = topIssueList.map(x => {
      if (x.issueId === issueId) {
        x.tags = tagInfoList;
        return x;
      } else {
        return x;
      }
    })

    newState = newState.setIn([field, 'topIssueList'], newTopIssueList);
  });
  return newState;
}

// 会用到summary相关state的路径
function isMatchSummaryRoute(pathname) {
  return pathname.match(/crash-reporting\/(dashboard|regional-dashboard|oom|model-oom)\/\w+/i);
}

function saveRankData(state, action, field) {
  let topStats = action.json.data;
  topStats = topStats.map(x => ({
    ...x,
    crashUser: x.exceptionDevices,
    accessUser: x.accessDevices,
    [field]: x.fieldValue,
  }));
  return state.set(action.type, topStats);
}
