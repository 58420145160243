import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EmptyData from 'components/commons/EmptyData';
import { TagList } from 'components/commons';
import {
  formatNum,
  getStatus,
  getIssueDetailUrlPrefix,
  convertIssueAssigneeListToDisplayText,
} from 'utils/helper';
import _style from './style.scss';
import { very_dirty_table_hack } from 'components/exception/ProductSummaryPage/style.scss';
import { Translation, useTranslation } from 'react-i18next';
import IssueTrendBarChart from 'components/exception/BaseProblemPage/IssueItem/IssueTrendBarChart';
import { Space, Tooltip, Table, Button, Row, Col, Select, Radio } from 'antd';
import { getIsIframe } from 'utils/iframe';
import moment from 'moment';
import SortButton from 'components/exception/ProductSummaryPage/SortButton';
import { formatEnNumber } from 'utils/format/format-number';
import useDeepCompareEffect from 'use-deep-compare-effect';
import CsClampDiv from 'components/commons/CsClampDiv/CsClampDiv';
import { useImmer } from 'use-immer';
import { DemoUtil } from 'utils/demo-util';
import { TimeUtil } from 'utils/time-util';
import { ze, isZh } from 'utils/zhEn';
import IssueTapdBugStatusEx from 'components/exception/BaseProblemPage/IssueItem/IssueTapdBugStatusEx';
import { connect, useSelector } from 'react-redux';
import { IssueListTrendTypeEnum } from 'utils/constants';
import CardHead from 'components/commons/CardHead';
import TopIssuesIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_icon.svg';
import { UpdateTimeTag } from '../UpdateTimeTag';
import reportEvent, { EVENT_ACTIONS, ReportEventUtil } from 'utils/reportEvent';
import { CsvUtil } from 'utils/csv-util';
import { ALL_VERSION } from 'utils/constants';
import { isNotNullish } from 'utils/nullish';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import SingleOrRangeDatePicker from 'components/exception/ProductSummaryPage/SingleOrRangeDatePicker';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';
import { CountryUtil } from 'utils/country';
import CountrySelect from '../CountrySelect';
import * as platformUtils from 'utils/platform';
import { DocsUtil } from 'utils/docs-util';
import { VersionUtil } from 'utils/version-util';
import { orange } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IssueCrashFilter, { IssueCrashFilterUtil } from 'components/commons/IssueCrashFilter/IssueCrashFilter';
import pickBy from 'lodash.pickby';
import IssueIdIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_issueid_icon.svg';
import IssueNameIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_yichangname_icon.svg';
import UpIcon from 'svg/v2/newcs_dashboard_list_up_icon.svg';
import DownIcon from 'svg/v2/newcs_dashboard_list_down_icon.svg'
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import StatusItem from 'components/commons/StatusItem/StatusItem';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { CS_STYLES } from 'utils/constants/style-constants';
import { PlatformUtil } from 'utils/platform';
import VmTypeSelect from 'components/exception/ProductSummaryPage/VmTypeSelect';
import { VmTypeUtil } from 'utils/constants/vm-type';

const FIELDS = Object.freeze({
  DEVICES: 'DEVICES',
  REPORT_COUNT: 'REPORT_COUNT',
  FLUCTUATE: 'FLUCTUATE',
  FIRST_TIME: 'FIRST_TIME',
  TOTAL_DEVICES: 'TOTAL_DEVICES',
  TOTAL_NUM: 'TOTAL_NUM',
});

function formatRate(numerator, denominator) {
  if (parseInt(denominator) === 0) {
    return '0 %';
  }
  return TopIssueUtils.formatPercentage(100 * numerator / denominator);
}

export const TopIssueSortField = Object.freeze({
  DEVICE_COUNT: 'DEVICE_COUNT',
  REPORT_COUNT: 'REPORT_COUNT',
});

export const TopIssueUtils = Object.freeze({
  calcFluctuatePercentage(crashDevices, accessDevices, compareCrashDevices, compareAccessDevices) {
    const nowRatio = accessDevices > 0 ? crashDevices / accessDevices : 0;
    const compareRatio = compareAccessDevices > 0 ? compareCrashDevices / compareAccessDevices : 0;
    if (nowRatio === compareRatio) {
      return 0;
    }
    return 100 * (nowRatio - compareRatio) / compareRatio;
  },
  //这个地方和RealTimeTopIssue 里面的formatPercentage 逻辑重复
  formatPercentage(percentage) {
    if (percentage === Number.POSITIVE_INFINITY) {
      return '∞';
    }
    if (!Number.isFinite(percentage)) {
      return `${percentage}`;
    }
    if (Math.abs(percentage) >= 0.1 || percentage === 0) {
      return `${percentage.toFixed(2)} %`;
    }
    return `${percentage.toFixed(4)} %`;
  },
  makeFluctuateDom(fluctuatePercentage, arrowClassName, style, t) {
    const infiniteTip = fluctuatePercentage === Number.POSITIVE_INFINITY ? t('TOPISSUELIST.infinityFluctuateTip') : undefined;
    return <div style={{ display: 'inline-block', position: 'relative' }}>
      <Tooltip title={infiniteTip}>
        <Space size={4}>
          <div>{ TopIssueUtils.formatPercentage(fluctuatePercentage) }</div>
          {/* {arrowClassName && <img
            className={`${style[arrowClassName]}`}
            style={{ position: 'relative', marginTop: '0' }}
            src={arrowClassName === 'increase' ? UpIcon : DownIcon}
          />} */}
          {arrowClassName && <div className={`${style[arrowClassName]}`}>{arrowClassName === 'increase' ? <UpIcon /> : <DownIcon />}</div>}
          { infiniteTip && <WrappedTipsIcon /> }
        </Space>
      </Tooltip>
    </div>;
  },

  makeTopIssueEnrichedIssueList(topIssueData, topIssueDataType) {
    const {
      topIssueList,
      crashDevices,
      accessDevices,
      prevDayAccessDevices,
    } = topIssueData;

    return topIssueList
      .map(x => {
        const fluctuate = TopIssueUtils.calcFluctuatePercentage(x.crashUser, accessDevices, x.preDayCrashUser, prevDayAccessDevices);
        return {
          ...x,
          exceptionMessage: x.exceptionMessage || '-',
          keyStack: x.keyStack || '-',
          fluctuate,
          formattedFluctuatePercentage: TopIssueUtils.formatPercentage(fluctuate),
          fluctuateDecimal: fluctuate / 100,
          firstUploadTimestamp: x.firstUploadTimestamp,
          formattedFirstUploadTime: moment(TimeUtil.fixChaosMillis(x.firstUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss'),
          formattedCrashUser: formatEnNumber(x.crashUser),
          toAllCrashDevicesFormattedPercentage: formatRate(x.crashUser, crashDevices),
          toAllCrashDevicesProportionDecimal: (x.crashUser / crashDevices) || 0,
          toAllAccessDevicesFormattedPercentage: formatRate(x.crashUser, accessDevices),
          toAllAccessDevicesProportionDecimal: (x.crashUser / accessDevices) || 0,
          issueWebsiteUrl: `${window.location.origin}/${getIssueDetailUrlPrefix(x.type)}/${x.appId}/${x.issueId}?pid=${x.platformId}&crashDataType=${topIssueDataType}`,
          commaJoinedTagNames: (x.tags || []).map(x => x.tagName).join(', '),
        }
      });
  },
});

const ProcessorLabel = ({ processor }) => {
  return (<Tooltip title={processor}>
    <div className={_style.processor_list}>{processor}</div>
  </Tooltip>);
}

function makeDefaultTopIssueFilterData() {
  return [
    { name: 'tagList', value: { isMustNot: false, value: [] } },
  ];
}
const TOP_ISSUE_FILTER_FIELD_WHITELIST = ['tagList', 'status'];

const MemoSingleOrRangeDatePicker = React.memo(SingleOrRangeDatePicker);

const TopIssueList = ({
  reduxState,
  data, style, handleChangeStatus, members, demoApp, topIssueDataType, actions, issueIdToDailyTrendList,
  bigDataResultDailyUpdateMillis, topIssueCount, searchParams, selectOptions, hasIntegratedApps,
  isRegionalDashboard,
  onClickUpsertTapdBugByIssueIdList, onDownloadData, onTopIssueCountChange, onTopIssueSortFieldChange,
  onBugUnbind, onTopIssueVersionChange, onTopIssueParamsChange, onTopIssueCountryChange,
  onTopIssueEnableFilterChange, handleTopIssueDataTypeChange, onTopIssueFilterRawSearchChange,
  onTopIssueIntegratedAppIdChange,
  onTopIssueVmTypeListChange,
}) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [itemIndexToIsHovering, updateItemIndexToIsHovering] = useImmer({})
  const issueIds = ((data || {}).topIssueList || []).map(x => x.issueId);

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const currentApp = useMemo(() => reduxState.app.get('current').toJS(), [reduxState.app.get('current')]);
  const { appId } = currentApp;
  const bugTrackingSimplifiedInfo = useMemo(() => reduxState.app.get('appIdToBugTrackingSimplifiedInfo')?.[appId],
    [reduxState.app, appId]);
  const { tapdWorkspaceId, jiraProjectId } = bugTrackingSimplifiedInfo || {};
  const showBugStatusField = !!tapdWorkspaceId || !!jiraProjectId;

  const [isExpand, setIsExpand] = useState(false);
  const [columnTooltipVisible, setColumnTooltipVisible] = useState(false);

  const topIssueCountOptions = [20, 50, 100]
    .map(x => ({ label: t(`DATATYPESELECT.top${x}`), value: x }));

  const sortFieldOptions = [
    { label: ze('按设备数', 'By Affected Devices'), value: 'DEVICE_COUNT' },
    { label: ze('按次数', 'By Reports'), value: 'REPORT_COUNT' },
  ];
  const { topIssueSortField, pid } = searchParams.toJS();
  const { rankVersion, integratedAppOptions, integratedAppIdToVersionTree } = selectOptions.toJS();

  const versionListValue = searchParams.get('topIssueVersion') !== ALL_VERSION
    ? CsvUtil.singleLineParse(searchParams.get('topIssueVersion'))
    : [];
  const isMultipleVersions = VersionUtil.isMultipleVersions(versionListValue);

  const topIssueIntegratedAppIdValue = searchParams.get('topIssueIntegratedAppId') !== ALL_VERSION
    ? searchParams.get('topIssueIntegratedAppId')
    : null;
  const isQueryIntegratedApp = isNotNullish(topIssueIntegratedAppIdValue);
  const versionTree = isQueryIntegratedApp
    ? integratedAppIdToVersionTree[topIssueIntegratedAppIdValue] || []
    : rankVersion.versionTree;

  const vmTypeListValue = VmTypeUtil.vmSelectValueToVmTypeList(searchParams.get('topIssueVmSelectValue'));

  const momentRange = useMemo(() => {
    const { topIssueDate, topIssueMaxDate } = searchParams.toJS();
    const minMoment = topIssueDate ? moment(topIssueDate) : moment();
    const maxMoment = topIssueMaxDate ? moment(topIssueMaxDate) : minMoment;
    return [minMoment, maxMoment]
  }, [searchParams]);
  const isMultipleDates = !momentRange[0].isSame(momentRange[1]);
  const disabledDate = useCallback(moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment)), []);
  const onDateChanged = useCallback(([minDate, maxDate]) => {
    console.log('SingleOrRangeDatePicker', minDate, maxDate)
    onTopIssueParamsChange({
      topIssueDate: minDate ? minDate.format('YYYYMMDD') : null,
      topIssueMaxDate: (maxDate && !maxDate.isSame(minDate)) ? maxDate.format('YYYYMMDD') : null,
    })
  }, []);

  const countryList = searchParams.get('topIssueCountry') && searchParams.get('topIssueCountry') !== '-1'
    ? searchParams.get('topIssueCountry').split(',')
    : [];

  const topIssueEnableFilterValue = Number(searchParams.get('topIssueEnableFilter') || 0);

  const tagListReduxState = reduxState.issue.get('selectOptions').get('tagList');
  const tagListOptions = (tagListReduxState.get ? tagListReduxState.get('options').toJS() : tagListReduxState.options)
    .map(x => ({ ...x, value: `${x.value}` }))
    .filter(x => x.value !== 'all');
  const issueStatusReduxState = reduxState.issue.get('selectOptions').get('status');
  const issueStatusOptions = (issueStatusReduxState.get ? issueStatusReduxState.get('options') : issueStatusReduxState.options).filter(x => x.value !== 'all');

  const [topIssueFilterData, setTopIssueFilterData] = useState(makeDefaultTopIssueFilterData());
  useEffect(() => {
    const topIssueFilterRawSearchJsonStr = searchParams.get('topIssueFilterRawSearchJsonStr');
    if (!topIssueFilterRawSearchJsonStr) {
      setTopIssueFilterData(makeDefaultTopIssueFilterData());
      return;
    }
    const filterData = IssueCrashFilterUtil.makeFilterDataFromQueryDict(JSON.parse(topIssueFilterRawSearchJsonStr))
      .filter(x => TOP_ISSUE_FILTER_FIELD_WHITELIST.includes(x.name));
    setTopIssueFilterData(filterData);
  }, [searchParams.get('topIssueFilterRawSearchJsonStr')]);


  useDeepCompareEffect(() => {
    actions.fetchMultipleIssueTrend(issueIds,IssueListTrendTypeEnum.DAILY);
    if(issueIds.length && !isExpand) {
      setIsExpand(true);
    }
  }, [issueIds]);

  const columnsTable = [
    {
      dataIndex: 'top',
      title: t("TOPISSUELIST.top"),
      width: '50px',
      fixed: 'left',
    },
    {
      dataIndex: 'issue',
      title: t("TOPISSUELIST.issue"),
      width: 300,
      fixed: 'left',
      render: (_, { item }) => (<div className={_style.hidden_none}>
        <div className={`${_style.problem_box}`}>
          <Tooltip trigger={[]} placement='bottom' overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', minWidth: '800px' }} title={(<div>
            <ul>
              <li className={_style.problem_list}>
                <span className={_style.problem_itemName}>{ t("TOPISSUELIST.exceptionType") }:</span>
                <span className={_style.problem_itemContent}>{item.exceptionName}</span>
              </li>
              <li className={_style.problem_list}>
                <span className={_style.problem_itemName}>{ t("TOPISSUELIST.exceptionInfo") }:</span>
                <span className={_style.problem_itemContent}>{item.exceptionMessage}</span>
              </li>
              <li className={_style.problem_list}>
                <span className={_style.problem_itemName}>{ t("TOPISSUELIST.stack") }:</span>
                <span className={_style.problem_itemContent}>{item.keyStack}</span>
              </li>
            </ul>
          </div>)}
          >
            <div>
              <div className={_style.problem_name}>
                <div>
                  <a
                    {...(item.isOpenIssueInNewTab && { target: '_blank' })}
                    rel="noopener noreferrer"
                    href={item.issueWebsiteUrl}
                    className={_style.link_item}
                    style={{display:'flex',alignItems:'center'}}
                    onClick={() => {
                      reportEvent({
                        action: EVENT_ACTIONS.CLICK,
                        tp1: '每日TOP问题点击链接',
                        tp6: item.issueWebsiteUrl,
                      })
                    }}
                  >
                    {/* {<span style={{ color: '#AAA', marginRight: '4px' }}>{ t('issueCrashFilterKey.issueId') }</span>} */}
                    {/* <img src={IssueIdIcon} style={{height: '12px', width: '12px', marginRight: '4px'}} /> */}
                    {/* {item.issueId.substr(0, 8) + '...'} */}
                    {/* {<span style={{ marginLeft: '8px', color: '#AAA', marginRight: '4px' }}>{ t('issueCrashFilterKey.errorType') }</span>} */}
                    {/* <img src={IssueNameIcon} style={{height: '12px', width: '12px', margin: '0 4px'}} /> */}
                    {/* <span style={{display:'inline-block', width:'300px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{item.exceptionName}</span> */}
                    <Space className={_style.error_title} size={4}>
                      {<div style={{margin: '-2px 4px 0 0'}}>{<IssueIdIcon />}</div>}
                      {
                        <Tooltip arrow={true}
                          placement="top"
                          title={(<div>{ item.issueId }</div>)}
                        >
                          {<div className={_style.error_title_id}>{item.issueId}</div>}
                        </Tooltip>
                      }
                      {<div style={{margin: '-2px 4px 0 4px'}}>{<IssueNameIcon />}</div>}
                      {
                        <Tooltip arrow={true}
                          placement="top"
                          title={(<div>{ item.exceptionName }</div>)}
                        >
                          {<div className={_style.error_title_type}>{item.exceptionName}</div>}
                        </Tooltip>
                      }
                    </Space>
                  </a>
                </div>
              </div>
              { false && <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', lineHeight: 'normal' }}>{ item.keyStack }</div> }
              <CsClampDiv
                style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere', lineHeight: '16px' }}
                lineHeight={1.15}
                lineClamp={3}
                showButton={!!itemIndexToIsHovering[item.i]}
              >
                { item.keyStack }
              </CsClampDiv>
            </div>
          </Tooltip>
        </div>
        <div>
          <TagList
          {...{
            tags:item.tags, addTag:item.addTag, delTag:item.delTag, issueId:item.issueId, appId:item.appId, pid: item.platformId,
            issueVersions:item.issueVersions,
            firstUploadTimestamp:item.firstUploadTimestamp,
          }}
          />
        </div>
      </div>)
    },
    {
      dataIndex: FIELDS.DEVICES,
      title: t("TOPISSUELIST.affectedDevices"),
      sorter: true,
      width: isZh() ? 110 : 140,
      align: 'center',
      render: (_,{item}) => (
        <div style={{ position: 'relative' }}>
         {`${item.formattedCrashUser} `}
        </div>
      )
    },
    {
      dataIndex: 'influenceFacility',
      title: (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <span style={{paddingRight: 4}}>{ t("TOPISSUELIST.proportion") }</span>
          <Tooltip title={ze('影响设备数/影响设备总数（所选时间范围）','Affected Devices / Total Affected Devices (on selected day)')}>
            <WrappedTipsIcon />
          </Tooltip>
        </div>
      ),
      width: isZh() ? 88 : 96,
      align: 'center',
      render: (_,{item}) => (
        <div style={{ position: 'relative' }}>
         {`${item.toAllCrashDevicesFormattedPercentage} `}
        </div>
      )
    },
    {
      dataIndex: 'influenceFacility',
      title: (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <span style={{paddingRight: 4}}>{ t("TOPISSUELIST.issueReportRate") }</span>
          <Tooltip title={ze('影响设备数/联网设备数（所选时间范围）','Affected Devices / Total Active Devices (on selected day)')}>
            <WrappedTipsIcon />
          </Tooltip>
        </div>
      ),
      width: isZh() ? 100 : 160,
      align: 'center',
      render: (_,{item}) => (
        <div style={{ position: 'relative' }}>
         {`${item.toAllAccessDevicesFormattedPercentage} `}
        </div>
      )
    },
    {
      dataIndex: FIELDS.REPORT_COUNT,
      title: (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <span style={{paddingRight: 4}}>{ t("REALTIMETOPISSUE.occurNum") }</span>
          <Tooltip title={ze('上报数量（所选日期范围内）','Report(on selected day)')} onOpenChange={(e) => {
            setColumnTooltipVisible(e);
          }}>
            <WrappedTipsIcon />
          </Tooltip>
        </div>
      ),
      width: isZh() ? 110 : 90,
      paddingLeft:'40px',
      align: 'center',
      sorter: true,
      render: (text, record) => (
        <div style={{ minWidth: '80px' }}>{ formatEnNumber(record.reportCount) }</div>
      )
    },
    {
      dataIndex: FIELDS.FLUCTUATE,
      title: <div style={{display: 'flex', justifyContent: 'center'}}>
        <span style={{paddingRight: 4}}>{ t("TOPISSUELIST.dayOnDayGrowth") }</span>
        <Tooltip title={t("TOPISSUELIST.compareWithPrevDay")} onOpenChange={(e) => {
            setColumnTooltipVisible(e);
          }}>
          <WrappedTipsIcon />
        </Tooltip>
      </div>,
      sorter: true,
      width: isZh() ? 100 : 140,
      align: 'center',
      render: (_,{item}) => (
        <div style={{ position: 'relative' }}>
         { TopIssueUtils.makeFluctuateDom(item.fluctuate, item.arrowClassName, item.style, t) }
        </div>
      )
    },
    {
      dataIndex: FIELDS.TOTAL_DEVICES,
      title: t("TOPISSUELIST.userAffectTotal"),
      width: isZh() ? 120 : 170,
      align: 'center',
      sorter: true,
      render: (_, {item}) => (
        <div style={{ position: 'relative' }}>{formatNum(item.accumulateCrashUser)}</div>
      )
    },
    {
      dataIndex: FIELDS.TOTAL_NUM,
      title: t("TOPISSUELIST.numAffectTotal"),
      width: isZh() ? 120 : 180,
      align: 'center',
      sorter: true,
      render: (_, {item}) => (
        <div style={{ position: 'relative' }}>{formatNum(item.accumulateCrashNum)}</div>
      )
    },
    {
      dataIndex: FIELDS.FIRST_TIME,
      title: t("TOPISSUELIST.firstPostTime"),
      sorter: true,
      width: isZh() ? 120 : 156,
      align: 'center',
      render: (_, {item}) => (
        <div>{item.formattedFirstUploadTime}</div>
      )
    },
    {
      dataIndex: 'issueTrendBarChart',
      title: t('TOPISSUELIST.reportedTrends'),
      align: 'center',
      width: '200px',
      render: (_,{item}) => (
        <div style={{ height:'80px',}}>
         <IssueTrendBarChart
            trendType={IssueListTrendTypeEnum.DAILY}
            dailyTrendList={issueIdToDailyTrendList[item.issueId]}
          />
        </div>
      )
    },
    {
      dataIndex: 'disposeState',
      title: t("TOPISSUELIST.status"),
      width: '200px',
      align: 'center',
      render: (_, {item}) => {
        return <StatusItem
          assigneeList={item.assigneeList}
          statusNo={item.statusNo}
          handleChangeStatus={() => { !DemoUtil.checkAndWarnIfDemoApp(demoApp) && handleChangeStatus(item)}}
        />
      }
    },
    ...(showBugStatusField ? [{
      dataIndex: 'tapdStatus',
      title: <div>
        { !!tapdWorkspaceId && ze('TAPD状态', 'Tapd Status') }
        { !!jiraProjectId && ze('Jira缺陷单状态', 'Jira Issue Status') }
      </div>,
      width: '120px',
      align: 'center',
      render: (_, { item }) => {
        const record = item;
        const bugInfo = record.bugs && record.bugs[0];
        if (bugInfo) {
          return <IssueTapdBugStatusEx
            needQueryStatus={true}
            currentApp={currentApp}
            issueId={record.issueId}
            tapdBugInfo={record.bugs && record.bugs[0]}
            onBugUnbind={() => onBugUnbind()}
          />;
        } else  {
          return <Button
            type='link'
            style={{padding: '0', color: CS_STYLES.PRIMARY_COLOR }}
            onClick={() => onClickUpsertTapdBugByIssueIdList([record.issueId])}>{ ze('创建', 'Create')}
          </Button>;
        }
      },
    }] : []),
  ];

  const clientHeight = document.body.clientHeight;
  const tableHeight = Math.max(clientHeight - 72 - 60 - 52 - 250, 300);

  function getOrder(field) {
    return sortField === field ? sortOrder : null;
  }

  function onChangeSort(sortField, sortOrder) {
    setSortOrder(sortOrder);
    setSortField(sortOrder ? sortField : null);
  }

  function renderTopIssueList(data, style, handleChangeStatus, members, demoApp, topIssueDataType, actions, sortField, sortOrder) {

    const { addTag, delTag } = actions;

    const isOpenIssueInNewTab = !process.env.REACT_APP_IS_WETEST_IFRAME && !getIsIframe();

    const topIssueEnrichedIssueList = TopIssueUtils.makeTopIssueEnrichedIssueList(data, topIssueDataType);
    if (sortField && sortOrder) {
      topIssueEnrichedIssueList.sort((a, b) => {
        let ascRes = 0;
        if (sortField === FIELDS.FLUCTUATE) {
          ascRes = a.fluctuate - b.fluctuate;
        } else if (sortField === FIELDS.FIRST_TIME) {
          ascRes = a.firstUploadTimestamp - b.firstUploadTimestamp;
        } else if (sortField === FIELDS.TOTAL_DEVICES) {
          ascRes = a.accumulateCrashUser - b.accumulateCrashUser;
        } else if (sortField === FIELDS.TOTAL_NUM) {
          ascRes = a.accumulateCrashNum - b.accumulateCrashNum;
        } else if (sortField === FIELDS.REPORT_COUNT) {
          ascRes = a.crashNum - b.crashNum;
        } else if (sortField === FIELDS.DEVICES) {
          ascRes = a.crashUser - b.crashUser;
        }
        return sortOrder === 'ascend' ? ascRes : -ascRes;
      });
    }

    return topIssueEnrichedIssueList.map((item, i) => {
      const { issueId, tags, appId, issueVersions, firstUploadTimestamp, fluctuate } = item;
      const platformId = item.platformId || item.pid;

      const { assigneeList } = item.issueInfo || {};

      const statusNo = getStatus(item.state);
      const assigneeListDisplayText = convertIssueAssigneeListToDisplayText(assigneeList);
      let arrowClassName = '';
      if (fluctuate > 0) {
        arrowClassName = 'increase';
      } else if (fluctuate < 0) {
        arrowClassName = 'decrease';
      }
      item.fluctuate = fluctuate;
      item.arrowClassName = arrowClassName;
      item.style = style;
      item.assigneeListDisplayText = assigneeListDisplayText;
      item.assigneeList = assigneeList;
      item.isOpenIssueInNewTab = isOpenIssueInNewTab;
      item.addTag = addTag;
      item.delTag = delTag;
      item.actions = actions;
      item.i = i;
      item.pid = platformId;
      item.statusNo = statusNo;
      return {
        top: i + 1,
        reportCount: item.crashNum,
        item: item,
        issue: item,
        influenceFacility: item,
        issueAnomalyFluctuate: item,
        appearTime: item,
        influenceEquipment: item,
        influenceFrequency: item,
        disposeState: item,
      };
    });
  }

  return (
    <>
      <CardHead
        title={t("DATATYPESELECT.dailyTopIssue")}
        hideDivider={!isExpand}
        svgIcon={<TopIssuesIcon />}
        iconExtraStyle={{width: '18px', height: '17px'}}
        onClick={() => {
          const newIsExpand = !isExpand;
          setIsExpand(newIsExpand);
          ReportEventUtil.reportCardHeadClickEvent('每日TOP问题', false, newIsExpand);
        }}
      >
        <div style={{display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'flex-end'}}>
          <UpdateTimeTag updateTime={bigDataResultDailyUpdateMillis} />
          <CsDownloadButton
            style={{ margin: '0px 24px'}}
            onClick={(e) => {
              e.stopPropagation();
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '每日TOP问题-下载',
              });
              onDownloadData();
            }}
          />
          {/* {
            isExpand ? <UpOutlined onClick={() => setIsExpand(false)} />
              : <DownOutlined onClick={() => setIsExpand(true)} />
          } */}
           <CsCollapseButton
              extraStyle={{ width: '14px', height: '14px' }}
              isCollapsed={!isExpand}
              enableHover={true}
              onClick={(e) => {
                e.stopPropagation();
                const newIsExpand = !isExpand;
                setIsExpand(newIsExpand);
                ReportEventUtil.reportCardHeadClickEvent('每日TOP问题', true, newIsExpand);
              }}
            />
        </div>
      </CardHead>
      {
        isExpand && <>
          <Row gutter={[10, 6]} align='middle' style={{ marginTop: '20px'}}>
            <Col>
              <Select
                style={{ width: '200px' }}
                options={sortFieldOptions}
                value={topIssueSortField || TopIssueSortField.DEVICE_COUNT}
                onChange={(value) => {
                  if (value === 'REPORT_COUNT') {
                    reportEvent({
                      action: EVENT_ACTIONS.CLICK,
                      tp1: '每日TOP问题-按次数',
                    });
                  }
                  onTopIssueSortFieldChange(value);
                }}
              />
            </Col>
            <Col>
              <Select
                style={{ width: '110px' }}
                options={topIssueCountOptions}
                value={topIssueCount}
                onChange={(v) => onTopIssueCountChange(v)}
              />
            </Col>
            {
              PlatformUtil.isVmTypeSupported(pid) && <Col>
                <VmTypeSelect
                  value={vmTypeListValue}
                  onChange={(v) => onTopIssueVmTypeListChange(v)}
                />
              </Col>
            }
            <Col>
              <VersionHybridSelect
                style={{ width: '320px' }}
                value={versionListValue}
                treeData={versionTree}
                onChange={(v) => onTopIssueVersionChange(CsvUtil.singleLineStringify(v) || ALL_VERSION)}
              />
            </Col>
            <Col>
              <MemoSingleOrRangeDatePicker
                style={{ width: '220px'}}
                disabledDate={disabledDate}
                allowClear={false}
                value={momentRange}
                onChange={onDateChanged}
              />
            </Col>
            { hasIntegratedApps && <Col flex={null}>
              <Select
                allowClear={true}
                showSearch
                style={{ width: '160px' }}
                options={integratedAppOptions}
                placeholder={ze('所有接入项目', 'All Integrated App')}
                value={topIssueIntegratedAppIdValue}
                onChange={(v) => onTopIssueIntegratedAppIdChange(v || ALL_VERSION)}
              />
            </Col> }
            { isRegionalDashboard &&  <Col>
                <CountrySelect
                  style={{width: '320px'}}
                  value={countryList}
                  isMultiple={true}
                  onChangeWhenDropdownClosed={(v) => onTopIssueCountryChange(CountryUtil.listToQueryString(v))}
                />
              </Col>
            }
          </Row>
          {
            (isMultipleVersions || isMultipleDates) && <div style={{ margin: '20px 0', width: '100%', color: orange.primary, fontSize: '12px' }}>
            <Space>
              <ExclamationCircleOutlined/>
              <div>{ t('EXCP_OVERVIEW.multipleVersionsDatesWarning')}</div>
            </Space>
            </div>
          }
          {
            platformUtils.isMobile(pid) && <Row gutter={[10, 6]} align='middle' style={{marginTop: '24px', height: '20px'}}>
              <Col>
                <span style={{ paddingLeft: '4px', paddingRight: '16px', color: '#807997'}}>{t("DATATYPESELECT.增加问题过滤条件")}</span>
                <Radio.Group
                  options={[
                    { label: t("common.yes"), value: 1 },
                    { label: t("common.no"), value: 0 },
                  ]}
                  value={topIssueEnableFilterValue}
                  onChange={(e) => {
                    if (e.target.value) {
                      reportEvent({
                        action: EVENT_ACTIONS.CLICK,
                        tp1: '增加问题过滤条件'
                      });
                    }
                    onTopIssueEnableFilterChange(e.target.value);
                  }}
                />
              </Col>
              <Col>
                <Tooltip placement="top" arrowPointAtCenter={true} title={ze('点击查看关键字列表','click and view keywords list')}>
                  <span style={{ paddingLeft: '4px', paddingRight: '16px'}}>
                    <a
                      target="_blank"
                      style={{color: '#807997'}}
                      rel="noopener noreferrer"
                      href={isZh()
                        ? DocsUtil.makeDocsUrl('/terms/#%E7%B3%BB%E7%BB%9F%E9%80%80%E5%87%BA%E5%85%B3%E9%94%AE%E5%AD%97')
                        : DocsUtil.makeDocsUrl('/terms/#system-exit-keywords')
                      }>{t("DATATYPESELECT.matchSysExitKW")}
                    </a>
                  </span>
                  <Radio.Group
                    options={[
                      { label: t("common.yes"), value: 'systemExit' },
                      { label: t("common.no"), value: 'unSystemExit' },
                    ]}
                    value={topIssueDataType}
                    onChange={(e) => {
                      if (e.target.value === 'systemExit') {
                        reportEvent({
                          action: EVENT_ACTIONS.CLICK,
                          tp1: '匹配到系统退出关键字'
                        });
                      }
                      handleTopIssueDataTypeChange(e.target.value);
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          }
          { !!topIssueEnableFilterValue && <div style={{marginTop: '20px'}}>
              <IssueCrashFilter
                platformId={pid}
                maxConditions={2}
                fieldWhitelist={['tagList', 'status']}
                filterOptions={{
                  tagList: tagListOptions,
                  status: issueStatusOptions,
                }}
                filterData={topIssueFilterData}
                onChange={({ newFilterData }) => { setTopIssueFilterData(newFilterData); }}
                onSubmit={({ newFilterData }) => {
                  setTopIssueFilterData(newFilterData);
                  const rawSearch = pickBy(IssueCrashFilterUtil.makeQueryDictFromFilterData(newFilterData), isNotNullish);
                  onTopIssueFilterRawSearchChange(rawSearch);
                }}
              />
            </div>
          }
          <Translation>
            {
              (t, { i18n }) => <div className={_style.topIssueList}>
                { !data || !data.topIssueList || data.topIssueList.length < 1
                  ? <EmptyData text={ t("TOPISSUELIST.noData") } extraStyle={{paddingTop: 32, paddingBottom: 42}} />
                  : (
                    <Table className={_style.custom_table} size="small" columns={columnsTable} pagination={false}
                      showSorterTooltip={false}
                      dataSource={renderTopIssueList(data, style, handleChangeStatus, members, demoApp, topIssueDataType, actions, sortField, sortOrder)}
                      // showSorterTooltip={columnTooltipVisible ? { style: { display: "none" } } : true}
                      rowClassName = {record => {
                        const item = record.item;
                        return (
                          Number(item.statusNo) === 1 ? style.done : style.item
                        )
                      }}
                      scroll={{
                          y: `${tableHeight}px`,
                          x: '1560px',
                        }}
                      onRow = {record => {
                        return {
                          onMouseEnter:(event) => {
                            updateItemIndexToIsHovering(draft => ({ ...draft, [(record.top)-1]: true }));
                          },
                          onMouseLeave:(event) => {
                            updateItemIndexToIsHovering(draft => ({ ...draft, [(record.top)-1]: false }));
                          }
                        }
                      }}
                      onChange = {(pagination, filters, sorter) => {
                        const { order, field } = sorter;
                        setSortField(field || null);
                        setSortOrder(order || null);
                      }}
                    />
                  )
                }
              </div>
            }
          </Translation>
        </>
      }
    </>
  );
};

TopIssueList.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  members: PropTypes.array,
  demoApp: PropTypes.bool,
  handleChangeStatus: PropTypes.func,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(TopIssueList);
