// 前端性能监控

import Aegis from 'aegis-web-sdk';

/**
 * @type {Aegis}
 */
let aegis = null;

const MAX_REPORT_RESPONSE_BODY_SIZE = 30 * 1024; // 接口日志上报的body的大小限制，超过的话就不上报body了

export function aegisInit() {
  if (!process.env.REACT_APP_AEGIS_REPORT_ID) {
    return;
  }
  const hostUrl = process.env.REACT_APP_AEGIS_HOST_URL;

  aegis = new Aegis({
    id: process.env.REACT_APP_AEGIS_REPORT_ID,
    ...(hostUrl ? { hostUrl } : {}),
    reportApiSpeed: true,
    reportAssetSpeed: true,
    spa: true,
    api: {
      apiDetail: true,
      resBodyHandler(data, url) {
        if (typeof data === 'string') {
          if (data.length <= MAX_REPORT_RESPONSE_BODY_SIZE) {
            return data;
          } else {
            return `Body length is too large, length = ${data.length}`;
          }
        }

        return data;
      },
    },
  });
}

export function aegisSetConfig(config) {
  if (!aegis) {
    return;
  }
  aegis.setConfig(config);
}

/**
 * @param {{ msg, ext1, ext2, ext3, trace }} messageInfo
 */
export function aegisReportError(messageInfo) {
  if (!aegis) {
    return;
  }
  aegis.error(messageInfo);
}

/**
 * @param {{ name: string, ext1: string, ext2: string, ext3: string }} eventInfo
 */
export function aegisReportCustomEvent(eventInfo) {
  if (!aegis) {
    return;
  }
  aegis.reportEvent(eventInfo);
}
