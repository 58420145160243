import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Input, message, Modal, Spin, Table } from 'antd';
import { ze } from 'utils/zhEn';
import { downloadAsFile, downloadWithUrl } from 'utils/file-utils';
import DownloadIcon from 'svg/v2/newcs_dashboard_download_icon_normal.svg';
import scss from 'components/exception/issue/LastReport/PsCoreDumpFileSetModal.scss';
import { sleep } from 'utils/sleep';

const PsCoreDumpFileSetModal = ({
  visible,
  onClose,
  reduxState,
  content,
}) => {
  const appId = reduxState.app.get('current').get('appId');

  const [loading, setLoading] = useState(false);
  const [filenameFilterValue, setFilenameFilterValue] = useState('');
  const filenameFilterLowerValueTrimmed = (filenameFilterValue || '').trim().toLowerCase();

  const fileInfoList = useMemo(() => {
    if (!content) {
      return [];
    }
    try {
      return JSON.parse(content).filter(x => !!x.fileName);
    } catch (e) {
      message.error('Invalid file set info.');
      return [];
    }
  }, [content]);

  const filteredFileInfoList = fileInfoList
    .filter(x => (x.fileName || '').toLowerCase().includes(filenameFilterLowerValueTrimmed));

  async function download(filename, downloadUrl) {
    if (!downloadUrl) {
      message.error('Cannot download, file content was not stored.');
      return;
    }
    setLoading(true);
    downloadWithUrl(filename, downloadUrl);
    message.success(ze('正在下载', 'File downloading.'));
    await sleep(1000);
    setLoading(false);
  }

  const columns = [{
    title: <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <div>{ ze('文件', 'File') }</div>
      <Input
        value={filenameFilterValue}
        style={{ width: '240px'}}
        placeholder={ze('按文件名搜索', 'Filter by Filename')}
        allowClear
        onChange={e => { setFilenameFilterValue(e.target.value); }}
      />
    </div>,
    dataIndex: 'fileName',
    key: 'fileName',
    filterSearch: true,
  }, {
    title: ze('操作', 'Actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: '80px',
    render: (_, record) => {
      const { fileName, downloadUrl } = record;
      return <Button
        className={scss.iconButton}
        style={{ padding: 0 }}
        size='small'
        type='link'
        onClick={() => {
          download(fileName, downloadUrl);
        }}
      ><DownloadIcon/></Button>;
    },
  }];

  return <Modal
    title='Core Dump File Set'
    visible={visible}
    maskClosable={false}
    width='800px'
    onCancel={onClose}
    footer={null}
  >
    <Spin
      className={scss.psCoreDumpFileSetModal}
      spinning={loading}
    >
      <Table
        scroll={{ y: '60vh' }}
        size='small'
        columns={columns}
        dataSource={filteredFileInfoList}
        pagination={{ showSizeChanger: true }}
      />
    </Spin>
  </Modal>;
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(PsCoreDumpFileSetModal);
