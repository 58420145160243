import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatNum } from 'utils/helper';
import _style from './style.scss';
import { useTranslation } from 'react-i18next';
import { PAGE_TYPE_SHOW, PLATFORM_ID } from 'utils/constants';
import * as PlatformUtils from 'utils/platform';
import { ze } from 'utils/zhEn';
import { formatCrashPercentageString, formatEnNumber } from 'utils/format/format-number';
import { isNotNullish, isNullish } from 'utils/nullish';
import { Space, Tooltip } from 'antd';
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import isEmpty from 'lodash/lang/isEmpty';
import { SummaryHeadDataType } from 'utils/constants/summary-head-data-type';
import { VmTypeInt, VmTypeUtil } from 'utils/constants/vm-type';
import { isAndroidOrHarmony, isIos, isPc, PlatformUtil } from 'utils/platform';
import { EnvUtil } from 'utils/env-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { ServerAppSettings } from 'utils/server-app-settings';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {Link} from "react-router-dom";
import DashboardFilterIcon from 'svg/v2/newcs_dashboard_overview_configured.svg';

function makeErrorDiscardStrategyTooltip(reportDiscardStrategy, pid) {
  if (isEmpty(reportDiscardStrategy)) {
    return null;
  }
  const { appStrategyList, issueStrategyTotalCount } = reportDiscardStrategy;
  const errorAppStrategyList = (appStrategyList || []).filter(x => x.exceptionType === 'error');
  // issue级别的丢弃策略没有异常大类信息，目前就先全算到error上
  const hasErrorDiscardStrategy = errorAppStrategyList.length > 0 || issueStrategyTotalCount > 0;
  if (!hasErrorDiscardStrategy) {
    return null;
  }

  const appDom = errorAppStrategyList.length > 0 && <div>
    <div>{ ze('当前项目配置了全局上报数据丢弃策略：', 'This app has configured global report drop strategy:') }</div>
    { errorAppStrategyList.map((x, i) => {
      const { exceptionType, blockPercent } = x;
      const categoryLabel = ExceptionCategoryUtil.toI18n(exceptionType, pid);
      return <div key={i}>{ ze(`丢弃 ${blockPercent} % 比例的${categoryLabel}上报`, `Drop ${blockPercent} % ${categoryLabel} reports`) }</div>;
    }) }
  </div>;
  const issueDom = issueStrategyTotalCount > 0 && <div>
    <div>{ ze(`当前项目配置了 ${issueStrategyTotalCount} 条问题级上报数据丢弃策略。`, `This app has configured issue report drop strategy for ${issueStrategyTotalCount} issues.`) }</div>
  </div>;

  return <div>
    { appDom }
    { issueDom }
  </div>;
}

function getDashboardFilterEnabledCategoryList(dashboardFilterList) {
  return (dashboardFilterList || [])
    .filter(x => x.enabled)
    .map(x => x?.config?.exceptionCategoryList || [])
    .flat();
}

const SummaryTab = ({
  reduxState, appId,
  pid, onClick, pageType, headDataType,
  realTimeAppendData: realTimeAppendDataBeforeToJs,
  reportDiscardStrategy,
}) => {
  const currentCategory = ExceptionCategoryUtil.unify(pageType);

  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  const enableOom = serverAppSettings[ServerAppSettings.keys.enableOom];
  const defaultVmType = serverAppSettings[ServerAppSettings.keys.dashboardDefaultVmType] || VmTypeInt.ALL;

  const dashboardFilterList = reduxState.app.get('appIdToDashboardFilterList')[appId];
  const dashboardFilterEnabledCategoryList = getDashboardFilterEnabledCategoryList(dashboardFilterList) || [];

  const { t } = useTranslation();
  const realTimeAppendData = realTimeAppendDataBeforeToJs.toJS();
  const {
    errorNumAllData,
    errorDeviceAllData,
    accessNum,
    accessUser,
    allVmTypesDataList = [],
  } = realTimeAppendData || {};

  const categoryList = useMemo(() => {
    const hasCrash = PlatformUtil.isCrashAvailable(pid);
    const hasAnr = PlatformUtil.isAnrAvailable(pid);
    const hasError = PlatformUtil.isErrorAvailable(pid);
    const hasOom = PlatformUtil.isOomAvailable(pid);
    return [
      ...(hasCrash ? [ExceptionCategory.CRASH] : []),
      ...(hasAnr ? [ExceptionCategory.ANR] : []),
      ...(hasError ? [ExceptionCategory.ERROR] : []),
      ...(hasOom ? [ExceptionCategory.OOM] : []),
    ];
  }, [pid]);

  useEffect(() => {
    reportEvent({
      action: EVENT_ACTIONS.EXPOSURE,
      tp1: `${pageType}曝光`,
    });
  }, [pageType]);


  function renderFieldByHeadDataType(reportNum, reportDevices, accessNum, accessDevices) {
    switch (headDataType) {
      case SummaryHeadDataType.crashUser: {
        return formatEnNumber(reportDevices);
      }
      case SummaryHeadDataType.crashNum: {
        return formatEnNumber(reportNum);
      }
      case SummaryHeadDataType.userRate: {
        return `${formatCrashPercentageString(reportDevices, accessDevices)} %`;
      }
      case SummaryHeadDataType.sessionCrashRate: {
        return `${formatCrashPercentageString(reportNum, accessNum)} %`;
      }
      default: {
        return '-';
      }
    }
  }

  const errorGrayStrategyTooltip = makeErrorDiscardStrategyTooltip(reportDiscardStrategy, pid);
  const hasErrorGrayStrategy = isNotNullish(errorGrayStrategyTooltip);
  const errorGrayFieldValue = hasErrorGrayStrategy
    ? renderFieldByHeadDataType(errorNumAllData, errorDeviceAllData, accessNum, accessUser)
    : '';
  function makeItemDom(exceptionCategory, headDataType, vm, isDisabled, isMainStats) {
    const isOom = ExceptionCategoryUtil.isOom(exceptionCategory);
    let filterVm = vm;

    const stat = allVmTypesDataList.find(x => x.vm === filterVm);
    if (isDisabled || isNullish(stat)) {
      return '-';
    }

    let denominator = 1;
    if (headDataType === SummaryHeadDataType.userRate) {
      denominator = stat.accessDevice;
    } else if (headDataType === SummaryHeadDataType.sessionCrashRate) {
      denominator = stat.accessNum;
    }

    const isPercentage = headDataType === SummaryHeadDataType.userRate || headDataType === SummaryHeadDataType.sessionCrashRate;
    let numerator = 0;

    if (ExceptionCategoryUtil.isCrash(exceptionCategory)) {
      if (headDataType === SummaryHeadDataType.crashNum) {
        numerator = stat.crashNum;
      } else if (headDataType === SummaryHeadDataType.sessionCrashRate) {
        numerator = stat.crashSession || stat.crashNum; // 兼容移动端crashSession为0的情况
      } else if (headDataType === SummaryHeadDataType.crashUser || headDataType === SummaryHeadDataType.userRate) {
        numerator = stat.crashDevice;
      }
    } else if (ExceptionCategoryUtil.isAnr(exceptionCategory)) {
      if (headDataType === SummaryHeadDataType.crashNum) {
        numerator = stat.anrNum;
      } else if (headDataType === SummaryHeadDataType.sessionCrashRate) {
        numerator = stat.anrSession || stat.anrNum; // 兼容移动端crashSession为0的情况
      } else if (headDataType === SummaryHeadDataType.crashUser || headDataType === SummaryHeadDataType.userRate) {
        numerator = stat.anrDevice;
      }
    } else if (ExceptionCategoryUtil.isError(exceptionCategory)) {
      if (headDataType === SummaryHeadDataType.crashNum) {
        numerator = stat.errorNum;
      } else if (headDataType === SummaryHeadDataType.sessionCrashRate) {
        numerator = stat.errorSession || stat.errorNum; // 兼容移动端crashSession为0的情况
      } else if (headDataType === SummaryHeadDataType.crashUser || headDataType === SummaryHeadDataType.userRate) {
        numerator = stat.errorDevice;
      }
    } else if (isOom) {
      if (headDataType === SummaryHeadDataType.crashNum) {
        numerator = stat.oomNum;
      } else if (headDataType === SummaryHeadDataType.sessionCrashRate) {
        numerator = stat.oomNum;
      } else if (headDataType === SummaryHeadDataType.crashUser || headDataType === SummaryHeadDataType.userRate) {
        numerator = stat.oomDevice;
      }
    }

    if (isPercentage) {
      const percent = formatCrashPercentageString(numerator, denominator);
      return isMainStats
        ? <div className={_style.percent}>{ percent }</div>
        : `${percent}\xA0%`;
    }
    return formatEnNumber(numerator);
  }

  return <ul className={_style.summary_tab}>
    {
      categoryList.map((x, index) => {
        const isOomAndDisabled = x === ExceptionCategory.OOM && !enableOom;
        const dom = <li
          className={classnames(_style.summary_tab_item,
            currentCategory === x && _style.active,
            isOomAndDisabled && _style.itemDisabled)}
          style={{color: isOomAndDisabled ? '#393157' : '' }}
          key={`${x}${index}`}
          onClick={() => {
            if (isOomAndDisabled) {
              return;
            }
            onClick(x);
          }}
          value={x}
        >
          { dashboardFilterEnabledCategoryList.includes(x) && <div className={_style.filterStrategyMark}>
            <Tooltip
              title={(<div>
                <span>{ze('项目大盘过滤策略已配置生效，','The project has enabled the filtering strategy, ')}</span>
                <Link
                  to={`/crash-reporting/preferences/dashboard-filters/${appId}?pid=${pid}`}
                  target='_blank'
                >
                  <span style={{color:'#6669FE'}}>{ze('前往查看','go to view')}</span>
                </Link>
              </div>)}
            >
              <div className={_style.filterStrategyMarkIcon}>
                <DashboardFilterIcon />
              </div>
            </Tooltip>

          </div> }
          <div className={_style.summaryTabMainRow}>
            <div className={_style.summary_tab_item_key}>{ExceptionCategoryUtil.toI18n(x, pid)}</div>
            <div className={_style.summary_tab_item_value}>{makeItemDom(x, headDataType, defaultVmType, isOomAndDisabled, true)}</div>
            {defaultVmType !== VmTypeInt.ALL && <div className={_style.summary_tab_item_key} style={{ marginLeft: '8px'}}>
              {ze('（', '(')}{VmTypeUtil.toI18n(defaultVmType)}{ze('）', ')')}
            </div>}
          </div>
          {ExceptionCategoryUtil.isError(x) && hasErrorGrayStrategy && <div
                className={` ${_style.summaryTabItemGray}`}
              ><Space size={4} align='center'>
                <div>{ze(`丢弃前：${errorGrayFieldValue}`, `Before Discarded: ${errorGrayFieldValue}`)}</div>
                <Tooltip
                  overlayStyle={{minWidth: '360px'}}
                  title={errorGrayStrategyTooltip}
                  placement='top'
                >
                  <WrappedTipsIcon/>
                </Tooltip>
              </Space></div>}
          {
            PlatformUtil.isVmTypeSupported(pid) && <div className={_style.summary_tab_vm_pm_div}><Space align='start' size={16}>
              { defaultVmType !== VmTypeInt.ALL && <div>{ze('全量', 'All')}: {makeItemDom(x, headDataType, VmTypeInt.ALL, isOomAndDisabled, false)}</div> }
              { defaultVmType !== VmTypeInt.REAL_DEVICE && <div>{t('REPORTDETAIL.真机')}: {makeItemDom(x, headDataType, VmTypeInt.REAL_DEVICE, isOomAndDisabled, false)}</div> }
              { defaultVmType !== VmTypeInt.EMULATOR && <div>{t('REPORTDETAIL.模拟器')}: {makeItemDom(x, headDataType, VmTypeInt.EMULATOR, isOomAndDisabled, false)}</div> }
              { defaultVmType !== VmTypeInt.CLOUD_GAMING && <div>{t('REPORTDETAIL.云游戏')}: {makeItemDom(x, headDataType, VmTypeInt.CLOUD_GAMING, isOomAndDisabled, false)}</div> }
            </Space></div>
          }
          {/* <div className={_style.summary_tab_item_bottom}/> */}
        </li>;

        if (!isOomAndDisabled) {
          return dom;
        } else {
          return <Tooltip
            placement='bottom'
            title={t('EXCP_OVERVIEW.OOM未开启提示语')}
          >{ dom }</Tooltip>;
        }
      })
    }
  </ul>;
};

const mapStateToProps = (state, router) => ({
  reduxState: state,
});

SummaryTab.propTypes = {
  reduxState: PropTypes.object,
  realTimeAppendData: PropTypes.object,
  reportDiscardStrategy: PropTypes.object,
  appId: PropTypes.string,
  pid: PropTypes.number,
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  headDataType: PropTypes.string,
};

export default connect(mapStateToProps)(SummaryTab);
