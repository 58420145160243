// 【【CS】sem投放数据追踪和分析】
// https://tapd.woa.com/tapd_fe/20421727/story/detail/1020421727121096919
// WeTest SEM营销推广追踪需求。

import { aegisReportCustomEvent } from 'utils/aegis';
import { GoogleAnalytics } from 'utils/google-analytics';
import isObject from 'lodash/lang/isObject';

const SEM_ENABLED = window.location.hostname.includes('crashsight.qq.com');
const SEM_QUERY_KEY = 'utm_source';

function getUtmSource() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(SEM_QUERY_KEY);
}

function trackEvent(name, params) {
  if (!SEM_ENABLED) {
    return;
  }
  const utmSource = getUtmSource();
  if (!utmSource) {
    return;
  }
  // 全量和单维度各上报一次
  aegisReportCustomEvent({
    name: `${name}_全量`,
    ext1: utmSource,
    ext2: isObject(params) ? JSON.stringify(params) : params,
  });
  aegisReportCustomEvent({
    name: `${name}_${utmSource}`,
    ext1: utmSource,
    ext2: isObject(params) ? JSON.stringify(params) : params,
  });
  GoogleAnalytics.gtag('event', `${name}_全量`, params);
  GoogleAnalytics.gtag('event', `${name}_${utmSource}`, params);
}

export const WetestSemUtil = {
  getUtmSource,

  trackPageView() {
    const isWelcomePage = window.location.pathname.startsWith('/welcome');
    const utmSource = getUtmSource();
    // 不是访问欢迎页，或者没带utm_source参数，不追踪
    if (!SEM_ENABLED || !isWelcomePage || !utmSource) {
      return;
    }

    trackEvent('访问页面', window.location.pathname);
  },

  trackClickLogin() {
    trackEvent('点击登录');
  },

  trackClickRegister() {
    trackEvent('点击注册');
  },

  trackClickContactUs() {
    trackEvent('点击联系我们');
  },

  trackSubmitContactUsForm(params) {
    trackEvent('提交联系表单', params);
  },
};
