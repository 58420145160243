import { IssueListTrendTypeEnum } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setPartialLocalStorage } from 'reducers/global/globalActions';

export function getInitialStorage() {
  return {
    // 左侧菜单栏是否手动操作折叠
    isLeftMenuManuallyCollapsed: false,
    /** 自定义数据，根据不同appId的过滤器 */
    behaviorSearchConditionGroupByAppId: {},
    // 创建TAPD缺陷，使用过的处理人历史记录，右进左出的队列
    tapdCurrentOwnerHistory: [],
    // 创建TAPD缺陷，使用过的创建人历史记录，右进左出的队列
    tapdReporterHistory: [],
    // oom页配置展示列
    oomConfigurationColumn: [],
    // 还原后的堆栈，文件路径展示方式（完整路径、缩略路径，...）
    stackFilePathDisplayType: '',
    // 我的产品列表，排序的key
    appListSortKey: '',
    // key: appId, value: 自定义特征分析，趋势统计选择的数据类型
    appIdToFeatureStatsHybridCategory: {},
    // key: appId, value: 自定义特征分析，趋势统计选择的key模式（top5,top10,custom...）
    appIdToFeatureStatsTrendFeatureMode: {},
    // key: appId, value: 自定义特征分析，趋势统计选择的自定义feature key
    appIdToFeatureStatsTrendFeatureKeys: {},
    // key: appId, value: 自定义特征分析，趋势统计选择的自定义埋点事件key
    appIdToFeatureStatsTrendEventKeys: {},
    // key: appId, value: 高级搜索最近使用的过滤条件
    appIdToLastAdvancedSearchFilterInfo: {},
    // key: issueId, value: 问题特征分析的搜索过滤配置
    issueIdToFeatureAnalysisPreferences: {},
    // 问题列表的最近上报趋势，显示类型
    issueListTrendType: IssueListTrendTypeEnum.DAILY,
    /** 版本趋势，历史选择过的选项 */
    appIdToVersionDashboardPreferences: {},
    /** 大盘统计，选择过的头部指标 */
    summaryHeadDataType: null,
    /** 产品列表，选择过的展示指标，取值是SummaryHeadDataType */
    appCardTrendDataType: null,
    /** 问题趋势是否显示联网设备数趋势 */
    issueTrendShowAccessTrend: true,
    /** 用户是否操作了关闭浏览器提示 */
    isNonChromiumNoticeClosed: false,
    /** 最有一次关闭的全局公告详情 */
    lastClosedGlobalNotificationInfo: null,
    /** 新2025年横幅已关闭 */
    newYear2025BannerClosed: true,
  };
}

export function useCrashsightLocalStorage() {
  const storage = useSelector(state => state.global.get('localStorage').toJS());
  const dispatch = useDispatch();

  function updateStorage(patch) {
    dispatch(setPartialLocalStorage(patch));
  }

  return [storage, updateStorage];
}
