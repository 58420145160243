import { Button, Dropdown, Menu, message, Modal, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { blue, green, grey, red } from '@ant-design/colors';
import { CheckCircleOutlined, LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import RestHelper from 'utils/RestHelper';
import { useTranslation } from 'react-i18next';
import { isNotNullish } from 'utils/nullish';
import { TapdUtil } from "utils/tapd-util";
import debounce from 'lodash/function/debounce';
import groupBy from 'lodash/collection/groupBy';

const queryBugsParamsPendingQueue = [];
const queryBugsFinishedCallbackQueue = [];

const cachedBugIdToBugInfo = {};

async function queryQueuedBugs() {
  const appIdToParamList = groupBy(queryBugsParamsPendingQueue, x => x.appId);
  queryBugsParamsPendingQueue.splice(0, queryBugsParamsPendingQueue.length);
  const callbacks = [...queryBugsFinishedCallbackQueue];
  queryBugsFinishedCallbackQueue.splice(0, queryBugsFinishedCallbackQueue.length);

  Object.values(appIdToParamList).forEach(async (paramList) => {
    const param = {
      appId: paramList[0].appId,
      platformId: paramList[0].platformId,
      bugInfos: paramList.map(x => x.singleBugInfo),
    }
    const rsp = await RestHelper.post('/redir/query/data/queryBugs', param);
    (rsp.json.data || []).forEach(info => {
      cachedBugIdToBugInfo[info.id] = info;
    });

    callbacks.forEach(callback => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  });
}

const debounceFetchBugStatusHandler = debounce(queryQueuedBugs, 100);

const IssueTapdBugStatusEx = ({
  currentApp,
  issueId,
  // ES里issue的bugs字段里保存的信息
  tapdBugInfo,
  onBugUnbind,
  btnStyle,
  // 是否需要组件自己调用queryBugs接口拉取状态。false：不需要（外层组件获取，通过tapdBugStatus字段传进来） true：需要（不需要外部传tapdBugStatus，内部自己获取）
  needQueryStatus,
  // 当needQueryStatus为false的时候，通过外部传进来tapdBugStatus
  tapdBugStatus: explicitTapdBugStatus,
}) => {
  const { t, i18n } = useTranslation();
  const { ze } = useZhEn();
  const { appId, platformId, teamType } = currentApp || {};
  const { workspaceId, id: bugId, commercialTapd } = tapdBugInfo || {};
  const bugPlatform = tapdBugInfo.bugPlatform || 'TAPD';

  function makeTapdBugStatusLabel(status) {
    return i18n.exists(`tapdBugStatus.${status}`)
      ? t(`tapdBugStatus.${status}`)
      : status;
  }

  const [tapdBugStatus, setTapdBugStatus] = useState('');
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [isStatusFetchFailed, setIsStatusFetchFailed] = useState(false);

  useEffect(() => {
    if (needQueryStatus) {
      return;
    }
    setTapdBugStatus(explicitTapdBugStatus);
  }, [needQueryStatus, explicitTapdBugStatus]);

  useEffect(() => {
    if (!needQueryStatus || !bugId || !bugPlatform) {
      return;
    }
    fetchBugStatus(bugId, bugPlatform);
  }, [needQueryStatus, bugId, bugPlatform]);

  // TODO: 兼容以前bug数据里面没有commercialTapd字段，没有这个字段的用teamType判断
  const isCommercialTapd = isNotNullish(commercialTapd)
    ? commercialTapd
    : teamType === 'COMMERCIAL';

  const bugUrl = TapdUtil.makeBugUrl(tapdBugInfo, teamType);
  const [bugColor, bugIcon, bugStatusLabel] = (() => {
    if (isStatusFetchFailed) {
      return [red.primary, null,  ze('获取状态失败', 'ERROR')];
    }
    if (!tapdBugStatus) {
      return [grey.primary, <ExclamationCircleOutlined/>, ze('未知', 'Unknown')];
    }
    if(bugPlatform === 'JIRA'){
      return [blue.primary, <ExclamationCircleOutlined/>, tapdBugStatus];
    }
    switch (tapdBugStatus) {
      case 'new':
        return [green.primary, <ExclamationCircleOutlined/>, makeTapdBugStatusLabel(tapdBugStatus)];
      case 'closed':
        return [grey.primary, <ExclamationCircleOutlined/>, makeTapdBugStatusLabel(tapdBugStatus)];
      default:
        return [blue.primary, <ExclamationCircleOutlined/>, makeTapdBugStatusLabel(tapdBugStatus)];
    }
  })();

  const queryBugsFinishedCallback = useCallback(() => {
    setFetchingStatus(false);
    const info = cachedBugIdToBugInfo[bugId];
    if (info) {
      setTapdBugStatus(info.status);
    } else {
      setTapdBugStatus('');
    }
  }, []);



  async function fetchBugStatus(bugId, bugPlatform) {
    if (!bugId || !bugPlatform) {
      return;
    }
    setFetchingStatus(true);
    queryBugsParamsPendingQueue.push({
      appId,
      platformId,
      singleBugInfo: { bugPlatform, id: bugId },
    });
    queryBugsFinishedCallbackQueue.push(queryBugsFinishedCallback);
    await debounceFetchBugStatusHandler();
  }

  async function confirmUnbindBug() {
    const rsp = await RestHelper.post('/redir/query/data/unbindAllBugsForIssues', {
      appId,
      platformId,
      issueIdList: [issueId],
    });
    const isSuccess = rsp.json.data[0];
    if (isSuccess) {
      message.success(ze('解绑成功。', 'Unbind succeeded.'));
      onBugUnbind && onBugUnbind();
    } else {
      message.error(ze('解绑失败，请联系客服', 'An error occurs when unbinding.'));
    }
  }

  function onClickUnbindBug() {
    Modal.confirm({
      title: ze('解绑确认', 'Confirmation'),
      content: <div>
        <div>{ ze(`确认要解绑${bugPlatform}缺陷吗？`, `Do you want to unbind ${bugPlatform} bug for this issue?`) }</div>
        <div>{ ze('问题ID: ', 'Issue ID: ') }</div>
        <div>{ issueId }</div>
        <div>{ ze(`${bugPlatform}缺陷单: `, `${bugPlatform} bug: `) } <a
          href={bugUrl}
          target='_blank'
          rel="noreferrer noopener"
        >{bugUrl}</a></div>
      </div>,
      onOk() {
        confirmUnbindBug();
      },
    });
  }

  const menu = (
    <Menu>
      <Menu.Item><a
        href={bugUrl}
        target='_blank'
        rel="noreferrer noopener"
        style={{textDecoration:'none'}}
      >{ ze('查看缺陷单', 'View Bug') }</a></Menu.Item>
      <Menu.Item onClick={() => onClickUnbindBug()}>{ ze('解绑缺陷单', 'Unbind Bug') }</Menu.Item>
    </Menu>
  );

  return <Dropdown
    placement='bottomCenter'
    overlay={menu}
  ><div>
    { fetchingStatus && <LoadingOutlined/> }
    { !fetchingStatus && <Button
      size='small'
      type='default'
      style={{
        backgroundColor: '#FFF',
        color: bugColor,
        borderColor: bugColor,
        fontSize: '14px',
        ...(btnStyle ?? {})
      }}
    >
      <a
        href={bugUrl}
        target='_blank'
        style={{ pointerEvents: 'none' }}
        rel="noreferrer noopener"
      ><Space size={4}>
        {/* {bugIcon} */}
        <div>{bugStatusLabel}</div>
      </Space></a>
    </Button> }
  </div></Dropdown>;
}

export default IssueTapdBugStatusEx;
