import React from 'react';
import PropTypes from 'prop-types';
import { EXCEPTION_ERROR_TYPE } from 'utils/constants';
import { isEmptyObject } from 'utils/helper';
import { isIos } from 'utils/platform';

const StackDetail = ({
  stackContent, issue, pid, isNeedHighLight, style,
}) => {
  const isAndroid = parseInt(pid) === 1;
  // 判断是否需要高亮
  function highlight(obj) {
    return false; // 更多线程不需要高亮

    let flag = false;
    if (!isEmptyObject(issue.toJS())) {
      const { detailInfo } = issue.toJS();
      if (detailInfo) {
        const { processName, bundleId } = detailInfo;
        const regProcessName = new RegExp(`(${processName})`, 'gi');
        const regBundleId = new RegExp(`(${bundleId})`, 'gi');
        if (isAndroid) { // 安卓,进程和bunleId来匹配
          flag = regProcessName.test(obj.line) || regBundleId.test(obj.line);
        }
        if (!isAndroid && obj) {
          flag = regProcessName.test(obj.libname);
        }
      }
    }
    return flag;
  }

  function getStackArr(line) { // crash的类型就需要分成四段.其他类型原样返回,还要区分下ios和安卓
    const { crashDoc } = issue.toJS();
    const type = crashDoc && crashDoc.crashMap && crashDoc.crashMap.type;
    // if(detailInfo)debugger;
    const hexReg = /\s0[xX][0-9a-fA-F]+\s/;
    const numReg = /^\d+/g;

    if (line) {
      const lineObj = {};
      if (isIos(pid) && (EXCEPTION_ERROR_TYPE[type] === 'crash' || EXCEPTION_ERROR_TYPE[type] === 'anr')) { // ios下 crash 和 anr类型就需要分段
        const HexadecimalArr = line.match(hexReg);// 取出16进制数
        const hexIndex = HexadecimalArr && HexadecimalArr.index;
        const lineNumStr = line.substr(0, hexIndex);
        const addrStr = HexadecimalArr && HexadecimalArr[0] && line.substr(hexIndex + HexadecimalArr[0].length, line.length - 1);
        const lineNum = lineNumStr && lineNumStr.match(numReg);// 取出行号
        const numArr = lineNumStr && lineNumStr.split(numReg);// 然后再根据数字分割
        const addrArr = addrStr && addrStr.split(/\s[+]\s/);
        lineObj.lineNum = lineNum && lineNum.length && lineNum[0];
        lineObj.libname = numArr && numArr.length && numArr[1] && numArr[1].trim();
        lineObj.hexadecimal = HexadecimalArr && HexadecimalArr[0];
        lineObj.addr = addrArr && addrArr.length && addrArr[0] && addrArr[0].trim();
        lineObj.stackTraceNum = addrArr && addrArr.length && addrArr[1] && addrArr[1].trim();
        lineObj.line = line.split(numReg).length && line.split(numReg)[1] && line.split(numReg)[1].trim();// ios其他类型还要替换掉replace file:0  .replace('unknown file:0',"");
      } else if (!isAndroid && EXCEPTION_ERROR_TYPE[type] === 'error') { // ios错误类型的时候,type是104 unity 3d的时候,需要replace
        lineObj.line = (`${type}` === '104' ? (line.split(numReg).length > 1 ? line.split(numReg)[1] && line.split(numReg)[1].trim().replace('unknown file:0', '') : line.split(numReg)[0]) : line);
      } else {
        lineObj.line = line;
      }
      return lineObj;
    }
    return { line };
  }

  // 渲染li
  function renderLi(lines, flag) {
    return lines.map((line, index) => {
      return (line && line.libname && line.hexadecimal && line.addr) ? (
        <tr key={index} className={highlight(line) && flag ? style.highLight : ''} style={{ borderBottom: '1px solid #EBEBEB' }}>
          <td className={style.libName} title={line.libname} style={{ background: '#FCFCFC' }}>
            <span className={style.index} style={{ background: '#FCFCFC', borderRight: 'none' }}>
              {' '}
              {index}
              {' '}
            </span>
            {line.libname}
          </td>
          <td className={style.addr} style={{ background: '#FCFCFC' }}>
            {line.hexadecimal}
            {' '}
            {line.addr}
          </td>
        </tr>
      ) : (
        <tr key={index} className={highlight(line) && flag ? style.highLight : ''} style={{ borderBottom: '1px solid #EBEBEB' }}>
          <td colSpan="2" className={style.col2}>
            <div
              style={{ background: '#FCFCFC' }}
              className={style.normal_wrap}
            >
              <span className={style.index} style={{ background: '#FCFCFC', borderRight: 'none' }}>
                {' '}
                {isIos(pid) ? index : index + 1}
                {' '}
              </span>
              <span>{line.line}</span>
            </div>
          </td>
        </tr>
      );
    });
  }

  // let lines = stackContent.split('\n').filter(line => line.length > 0 ? true : false);
  let stack = [];
  // console.log(stackConetent, '这是stackConter的内容')
  stack = stackContent && stackContent.split('\n').filter((line) => (line && line.trim() !== '')).map((line) => getStackArr(line));
  // console.log('stack ===', stack);
  console.log(stack, '这是stack的内容了，就是那边传递过来的', stackContent, '这是stackConter的内容');
  return (
    stackContent ? (
      <table className={`${style.data_detail} ${style.data_detail_analyzed}`}>
        <colgroup>
          <col width="22%" />
          <col />
        </colgroup>
        <tbody>
          {renderLi(stack, isNeedHighLight) }
        </tbody>
      </table>
    ) : <div />
  );
};

StackDetail.propTypes = {
  stackContent: PropTypes.string,
  issue: PropTypes.object,
  pid: PropTypes.string,
  style: PropTypes.object,
  isNeedHighLight: PropTypes.bool,
};

export default StackDetail;
