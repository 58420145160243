import { isPcOrLinux, isSwitch } from 'utils/platform';
import { isNullish } from 'utils/nullish';
import { ze } from 'utils/zhEn';
import i18n from 'i18n';

export const StackUtil = (function() {
  const UNKNOWN_MODULE = ze('未知模块', 'Unknown Module');

  /** 将未分行的堆栈内容分解为行数组 */
  function splitStackIntoLines(stackBeforeSplit, pid) {
    const stack = String(stackBeforeSplit || '');
    if (isPcOrLinux(pid)) {
      return stack.split(/[\n;]/);  // PC用换行或者分号来分隔
    }
    if (isSwitch(pid)) {
      // SWITCH的崩溃堆栈信息每一行是逗号分隔的，但是堆栈里的函数参数，模板参数里面也有逗号
      // 所以分割的时候要用不在一对小括号/尖括号里的逗号来分隔
      // 因为switch的错误还是换行符分割，所以先把上述的逗号替换成\n然后统一split
      const splitRegex = /,(?![^(<]*[)>])/g;
      return stack.replace(splitRegex, '\n').split('\n');
    }
    return stack.split('\n'); // 移动、ps5、xbox用\n分割堆栈
  }

  function fillFilePathAndInlineLines(lineObj, middlePart) {
    const inlineHeader = '([Inline]';
    if (middlePart && middlePart.includes(inlineHeader)) {
      const splitResult = middlePart.split(inlineHeader);
      lineObj.filePath = (splitResult[0] || '').replace(/(^\(|\)$)/g, '');
      lineObj.inlineLines = splitResult
        .slice(1)
        .map(x => {
          const [beforePart, middlePart, afterPart] = shallowSplitAndroidStackContent(x.replace(/\)$/g, ''));
          return {
            stackContentBeforeFilePath: beforePart,
            stackContentAfterFilePath: afterPart,
            filePath: middlePart.replace(/(^\(|\)$)/g, ''),
          };
        });
    } else {
      lineObj.filePath = (middlePart || '').replace(/(^\(|\)$)/g, '');
    }
  }

  /**
   * 没有符号表匹配的行，获取原因提示的文案
   * @return {{ short: string, long: string }}
   * */
  function makeSymbolicationFailureReasonInfo(stackLineRetraceStatus, isSymbolFileUploaded) {
    if (!stackLineRetraceStatus) {
      return { short: '', long: '' };
    }
    switch (stackLineRetraceStatus) {
      case -3:
      case -9: {
        return !isSymbolFileUploaded
          ? { short: ze('符号表未上传', 'No Symbol File'), long: i18n.t("SYMBOLTABLE.找不到符号表") }
          : { short: ze('需要重还原', 'Need Re-Symbolication'), long: i18n.t('SYMBOLTABLE.需要重还原') } ;
      }
      case -8: {
        return {
          short: ze('还原失败', 'Symbolication Failed'),
          long: i18n.t("SYMBOLTABLE.关键信息不足") ,
        };
      }
      case -12: {
        return {
          short: ze('还原失败', 'Symbolication Failed'),
          long: i18n.t("SYMBOLTABLE.堆栈内容不支持还原"),
        };
      }
      case -14: {
        return {
          short: ze('还原失败', 'Symbolication Failed'),
          long: ze('根据地址找不到调试信息', 'Cannot find debug information by address.'),
        }
      }
      case -15: {
        return {
          short: ze('还原失败', 'Symbolication Failed'),
          long: ze('缺少基地址信息', 'Cannot find the base address information.'),
        }
      }
      case -16: {
        return {
          short: ze('还原失败', 'Symbolication Failed'),
          long: ze('符号表缺少调试信息', 'Missing debug information in symbol file.'),
        }
      }
      default: {
        return {
          short: ze('还原失败', 'Symbolication Failed'),
          long: ze(`堆栈还原失败：服务器发生错误，请联系技术支持。（错误码=${stackLineRetraceStatus}）`, `Symbolication failed: Server Error Occurred. Please contact technical support. (error code = ${stackLineRetraceStatus})`),
        };
      }
    }
  }

  return Object.freeze({
    UNKNOWN_MODULE,
    splitStackIntoLines,
    fillFilePathAndInlineLines,
    makeSymbolicationFailureReasonInfo,
  });
})();

function shallowSplitAndroidStackContent(stackContent) {
  const filePathSplitRegex = /(\(\S+?\.\S+?:\d+.*\))/;
  const [
    beforePart,
    middlePart,
    ...afterPart
  ] = (stackContent || '').split(filePathSplitRegex);

  return [
    beforePart || '',
    middlePart || '',
    (afterPart || []).join(''),
  ];
}

export function makeAndroidDetailObjectFromStackLine(line, isMinidumpStack) {
  // 安卓还原前堆栈行示例：
  // #01 pc 00000000020224b0 /data/app/~~D5H-YVIu4cQC9h4BQ6DEMA==/com.tencent.ig-vcwQVii2xuIFBzNheYTt8g==/lib/arm64/libUE4.so [arm64-v8a::c6dea8c7db99d827b97b068cf32b8304]
  // 安卓还原后堆栈行示例：
  // #00 pc 061680d0 libUE4.so buildEnd (I:\\dev_engine\\DevOps\\UE4181\\Engine\\Source\\ThirdParty\\PhysX\\PhysX_3.4\\Source\\SceneQuery\\src/SqAABBTree.cpp:450) [armeabi]

  // minidump堆栈示例：
  // libil2cpp.so	pc 0xce64e8		(:0)[arm64:Android:AE52F5DA9D332C10EE7A1A24E65983460]

  if (isMinidumpStack) {
    return makeDetailObjectFromMinidumpStackLine(line);
  }

  const lineObj = {
    line,
  };
  // 匹配#00 pc 12345678 ，capture group 1的内容是pc 12345678
  const hexadecimalRegex = /(?:.*#\d+\s+)([a-zA-Z]+\s+(?:0x)?[0-9a-fA-F]+)(?:\s+)/;
  const hexadecimalRegexMatchResult = line.match(hexadecimalRegex);
  if (!hexadecimalRegexMatchResult) {
    const [beforePart, middlePart, afterPart] = shallowSplitAndroidStackContent(lineObj.line);
    lineObj.stackContentBeforeFilePath = beforePart;
    lineObj.filePath = middlePart;
    lineObj.stackContentAfterFilePath = afterPart;
    return lineObj;
  }
  // 十六进制地址
  lineObj.hexadecimal = hexadecimalRegexMatchResult[1];
  // 移除了地址及之前内容（行号等）的剩余行内容
  let lineRemaining = line.replace(hexadecimalRegex, '');
  const libNameRegex = /\S+/;
  lineObj.libname = (lineRemaining.match(libNameRegex) || [])[0];  // libname是可能带有路径的
  lineObj.soNameWithoutPath = ((lineObj.libname || '').match(/[^\/]+\.so/i) || [])[0] || lineObj.libname; // soNameWithoutPath和libname的区别是这个不带路径

  lineRemaining = lineRemaining.replace(libNameRegex, '').trim();
  lineObj.stackContent = lineRemaining;

  const bracketedArchWithUuidMatches = lineRemaining.match(/\[[^\[\]]+?\]/g);
  if (bracketedArchWithUuidMatches) {
    const bracketedArchWithUuid = bracketedArchWithUuidMatches[bracketedArchWithUuidMatches.length - 1];
    lineObj.soUuid = (bracketedArchWithUuid.match(/::(.+?)\]/) || [])[1] || '';  // 只有还原前的堆栈带uuid，还原后的没有
  } else {
    lineObj.soUuid = '';
  }

  // 分割出文件路径
  const [beforePart, middlePart, afterPart] = shallowSplitAndroidStackContent(lineObj.stackContent);
  lineObj.stackContentBeforeFilePath = beforePart;
  lineObj.stackContentAfterFilePath = afterPart;

  // 文件路径中间可能有inline行
  StackUtil.fillFilePathAndInlineLines(lineObj, middlePart);

  return lineObj;
}

/** 解析安卓、PC等minidump堆栈 */
export function makeDetailObjectFromMinidumpStackLine(line) {
 // console.log('gomo', line);
  // console.log('gomo', line.split('\t'));
  // minidump堆栈示例：
  // 原始： \t libil2cpp.s \t pc 0xce64e8 \t (:0)[arm64:Android:AE52F5DA9D332C10EE7A1A24E65983460]
  // 还原： \t FGame.exe \t pc 0x62df0e7 \t SWidget::Prepass_Internal(float) (D:\agent\workspace\MasterPackage\Engine\Engine\Source\Runtime\SlateCore\Private\Widgets/SWidget.cpp:1447)[amd64:Windows NT:D5263ADE0FD2402896174379341CA3331]

  line = String(line || '');
  const lineObj = {
    line,
  };

  // 每行堆栈最前面，mike加了个额外的\t，需要去掉
  let splitResult = line.replace(/^\t/, '').split('\t');
  const [libname = '', hexadecimal = '', ...rest] = splitResult;

  const restContent = rest.join('\t');
  lineObj.libname = libname || StackUtil.UNKNOWN_MODULE;
  lineObj.hexadecimal = hexadecimal || '';
  lineObj.soNameWithoutPath = (lineObj.libname.match(/[^\/]+\.so/i) || [])[0] || lineObj.libname; // soNameWithoutPath和libname的区别是这个不带路径
  lineObj.stackContent = restContent;

  const stackContentAfterFilePathPattern = /\[[^[]*]$/;
  const stackContentAfterFilePath = (restContent.match(stackContentAfterFilePathPattern) || [])[0] || '';
  lineObj.stackContentAfterFilePath = stackContentAfterFilePath;
  const soUuidPattern = /([a-fA-F0-9]+)]/;
  lineObj.soUuid = (lineObj.stackContentAfterFilePath.match(soUuidPattern) || [])[1] || '';
  const beforeFilePathAndFilePath = restContent.replace(stackContentAfterFilePath, '');
  const filePathWithParentheses = ((beforeFilePathAndFilePath.match(/\S*\)$/) || [])[0] || '');
  lineObj.filePath = filePathWithParentheses.replace(/(^\(|\)$)/g, '');
  lineObj.stackContentBeforeFilePath = beforeFilePathAndFilePath.replace(filePathWithParentheses, '');
  return lineObj;
}

// 给stack的每一个lineObj计算并设置精简后的filePath，返回新的stack
export function enrichShrunkFilePathOnStackLineObj(stack) {
  if (!stack) {
    return stack;
  }

  const dirSeparatorSplitRegex = /([/\\])/;

  function makeShrunkLine(line) {
    const { filePath } = line;
    if (!filePath) {
      return line;
    }

    const filePathSlices = filePath.split(dirSeparatorSplitRegex);
    const shrunkFilePath = filePathSlices.slice(-5).join(''); // 保留3层目录

    return {
      ...line,
      shrunkFilePath,
    };
  }

  return stack.map((line) => {
    if (!line) {
      return line;
    }
    const { inlineLines } = line;
    if (!inlineLines) {
      return makeShrunkLine(line);
    } else {
      return {
        ...makeShrunkLine(line),
        inlineLines: inlineLines.map(makeShrunkLine),
      }
    }
  });

  /* 这段的逻辑是区分关键行和非关键行，每一行和最近关键行进行对比，差异超过3层目录的设置为关键行。
     关键行显示完整堆栈，非关键行显示差异堆栈。这套逻辑比较复杂目前暂时不用 */
  /* const calcShrunkPath = (filePathSlices, refFilePathSlices) => {
    if (isNullish(refFilePathSlices) || refFilePathSlices.length === 0) {
      return {
        isKeyPath: true,
        shrunkPath: filePathSlices.join(''),
      };
    }

    const dirDepth = (filePathSlices.length >> 1) + 1;
    const refDirDepth = (refFilePathSlices.length >> 1) + 1;
    let commonDepth = 1;
    for (;commonDepth <= dirDepth && commonDepth <= refDirDepth; commonDepth += 1) {
      const i = (commonDepth - 1) * 2;
      const slice = filePathSlices[i];
      const refSlice = refFilePathSlices[i];
      if (slice !== refSlice) {
        break;
      }
    }

    if (refDirDepth - commonDepth >= 3 || dirDepth - commonDepth >= 3) {
      return {
        isKeyPath: true,
        shrunkPath: filePathSlices.join(''),
      };
    }

    const shrunkPath = filePathSlices.slice(-5).join('');
    return {
      isKeyPath: false,
      shrunkPath,
    };
  };

  const dirSeparatorSplitRegex = /([/\\])/;
  let keyFilePathSlices = null;

  stack.forEach((line) => {
    const { filePath } = line;
    if (!filePath) {
      return;
    }

    const filePathSlices = filePath.split(dirSeparatorSplitRegex);
    const { isKeyPath, shrunkPath } = calcShrunkPath(filePathSlices, keyFilePathSlices);
    if (isKeyPath) {
      keyFilePathSlices = filePathSlices;
    }

    line.shrunkFilePath = shrunkPath;
  }); */
}
