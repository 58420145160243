import SummaryRest from 'utils/SummaryRest';
import { createAction } from 'redux-actions';
import { PAGE_TYPE_CONVERT } from 'utils/constants';
import moment from 'moment';
import {
  CHANGE_TREND_TAG,
  CHANGE_RANK_TAG,
  CHANGE_PAGE_TYPE,
  CHANGE_TREND_PARAMS,
  CHANGE_TOP3_STATUS,
  CHANGE_DATA_TYPE,
  CHANGE_TOPISSUEDATA_TYPE,
  CHANGE_SUMMARY_VM_SELECT_VALUE,
} from 'store/actions';
import { ProductSummaryUtil } from 'components/exception/ProductSummaryPage/ProductSummaryUtil';
import { getStartEndDateFromStr } from 'utils/StringUtils';
import { CountryUtil } from 'utils/country';
import { isNotNullish, isNullish } from 'utils/nullish';
import { CsvUtil } from 'utils/csv-util';
import { VersionUtil } from 'utils/version-util';
import { ReduxActionType } from 'reducers/redux-action-type';
import { VmTypeUtil } from 'utils/constants/vm-type';

const TAG = '[summaryActions]::';

export const systemAccess = getTopData(SummaryRest.system.access,'system-access', 'osVersion');
export const systemRate = getTopData(SummaryRest.system.rate,'system-rate', 'osVersion');

export const modelAccess = getTopData(SummaryRest.model.access,'model-access', 'model');
export const modelRate = getTopData(SummaryRest.model.rate,'model-rate', 'model');

export const appAccess = getTopData(SummaryRest.app.access,'app-access', 'version');
export const appRate = getTopData(SummaryRest.app.rate,'app-rate', 'version');

export const gpuAccess = getTopData(SummaryRest.gpu.access, 'gpu-access', 'gpu');
export const gpuRate = getTopData(SummaryRest.gpu.rate, 'gpu-rate', 'gpu');

export const cpuAccess = getTopData(SummaryRest.cpu.access, 'cpu-access', 'cpu');
export const cpuRate = getTopData(SummaryRest.cpu.rate, 'cpu-rate', 'cpu');

export const tagAccess = getTopData(SummaryRest.tag.access, 'tag-access', 'tag');
export const tagRate = getTopData(SummaryRest.tag.rate, 'tag-rate', 'tag');

export function fetchCountryGroupConfig() {
  return (dispatch, getState) => {
    const { appId } = getState().app.get('current').toJS();
    return dispatch({
      rest: SummaryRest.countryGroupConfig.fetch,
      data: { appId },
    });
  };
}

export function fetchSdkIntegratedApps(sdkAppId) {
  return (dispatch, getState) => {
    const { appId } = getState().app.get('current').toJS();
    return dispatch({
      rest: SummaryRest.fetchSdkIntegratedApps.fetch,
      data: {
        appId,
        sdkAppId,
      },
    });
  };
}

export function fetchSdkIntegratedAppVersions(sdkAppId, integratedAppId) {
  return (dispatch, getState) => {
    const { appId } = getState().app.get('current').toJS();
    return dispatch({
      rest: SummaryRest.fetchSdkIntegratedAppVersions.fetch,
      data: {
        appId,
        sdkAppId,
        integratedAppId,
      },
    });
  };
}

export function queryBigDataLastUpdateTime() {
  return async (dispatch, getState) => {
    const { appId } = getState().app.get('current').toJS();
    const rsp = await RestHelper.mustPost('/redir/api/statResource/queryBigDataLastUpdateTime', {appId});
    const { realtimeUpdateMillis: bigDataRealtimeUpdateMillis, resultDailyUpdateMillis: bigDataResultDailyUpdateMillis } = rsp.json.data;
    return dispatch({
      type: ReduxActionType.patchSummaryState,
      payload: { bigDataRealtimeUpdateMillis, bigDataResultDailyUpdateMillis },
    });
  };
}

/**
 * 获取top issue数据
 */
export function getTopIssue(extraDataMap = {}) {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const {
      subModuleId,
      topIssueVersion,
      topIssueDate,
      topIssueMaxDate,
      topIssueCountry,
      // topIssueSubModuleId,
      topIssueFilterRawSearchJsonStr,
      topIssueEnableFilter,
      topIssueIntegratedAppId,
      topIssueVmSelectValue,
      topIssueSortField,
    } = getState().summary.get('searchParams').toJS();
    const { pageType } = getState().summary.get('selectOptions').toJS();
    const type = PAGE_TYPE_CONVERT[pageType];
    const issueFilterRawSearch = topIssueEnableFilter && topIssueFilterRawSearchJsonStr
      ? JSON.parse(topIssueFilterRawSearchJsonStr)
      : undefined;

    dispatch({ type: 'UPDATE_SUMMARY_SEARCH_PARAMS', payload: { topIssueLoading: true } });

    const versionList = VersionUtil.makeVersionListFromVersionCsvString(topIssueVersion);
    const isMultiVersion = VersionUtil.isMultipleVersions(versionList);

    const minDate = topIssueDate;
    const maxDate = topIssueMaxDate || topIssueDate;

    try {
      const rsp = await RestHelper.mustPost('/api/app/getTopIssue', {
        appId: (topIssueIntegratedAppId && topIssueIntegratedAppId !== '-1')
          ? `${appId}:${topIssueIntegratedAppId}`
          : appId,
        platformId,
        sortField: topIssueSortField,
        mergeMultipleVersionsWithInaccurateResult: isMultiVersion,
        version: isMultiVersion ? undefined : versionList[0],
        versionList: isMultiVersion ? versionList : undefined,
        type,
        minDate,
        maxDate,
        mergeMultipleDatesWithInaccurateResult: minDate !== maxDate,
        limit: 20,
        countryList: CountryUtil.queryStringToList(topIssueCountry),
        vmTypeList: VmTypeUtil.vmSelectValueToVmTypeList(topIssueVmSelectValue),
        subModuleId,
        issueFilterRawSearch,
        ...extraDataMap,
      });
      dispatch({ type: 'summaryReducerSetTopIssueData', payload: rsp.json.data });
    } catch(e) {
      dispatch({ type: 'summaryReducerSetTopIssueData', payload: {} });
    } finally {
      dispatch({ type: 'UPDATE_SUMMARY_SEARCH_PARAMS', payload: { topIssueLoading: false } });
    }
  };
}

export function fetchRealTimeTopIssueAndSetState({ sortField, hour, version, country, limit, vmSelectValue } = {}, collapseTopIssues) {
  return (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const { searchParams } = getState().summary.toJS();
    const newVersion = version || searchParams.realTimeTopIssueVersion;
    const newCountry = country || searchParams.realTimeTopIssueCountry;
    const subModuleId = searchParams.subModuleId;
    // const newSubModuleId = subModuleId || searchParams.realTimeTopIssueSubModuleId;
    const { pageType } = getState().summary.get('selectOptions').toJS();
    const type = PAGE_TYPE_CONVERT[pageType];
    const newHour = hour || searchParams.realTimeTopIssueHour;
    const newLimit = limit || searchParams.realTimeTopIssueLimit;
    const newSortField = sortField || searchParams.realTimeTopIssueSortField;
    const newVmSelectValue = isNotNullish(vmSelectValue) ? vmSelectValue : searchParams.realTimeTopIssueVmSelectValue;

    dispatch({
      type: 'UPDATE_SUMMARY_SEARCH_PARAMS',
      payload: {
        realTimeTopIssueLoading: true,
        realTimeTopIssueSortField: newSortField,
        realTimeTopIssueVersion: newVersion,
        realTimeTopIssueCountry: newCountry,
        // realTimeTopIssueSubModuleId: newSubModuleId,
        realTimeTopIssueHour: newHour,
        realTimeTopIssueLimit: newLimit,
        realTimeTopIssueVmSelectValue: newVmSelectValue,
      },
    });

    if (collapseTopIssues) {
      dispatch({
        type: 'CHANGE_TOP3_STATUS',
        payload: true,
      });
    }

    const versionList = VersionUtil.makeVersionListFromVersionCsvString(newVersion);
    const isMultiVersion = VersionUtil.isMultipleVersions(versionList);

    return dispatch({
      rest: SummaryRest.realTimeTopIssue.fetch,
      data: {
        appId,
        platformId,
        sortField: newSortField,
        mergeMultipleVersionsWithInaccurateResult: isMultiVersion,
        version: isMultiVersion ? undefined : versionList[0],
        versionList: isMultiVersion ? versionList : undefined,
        countryList: CountryUtil.queryStringToList(newCountry),
        subModuleId,
        startHour: newHour,
        type,
        vmTypeList: VmTypeUtil.vmSelectValueToVmTypeList(newVmSelectValue),
        limit: newLimit,
      },
    });
  };
}

/**
 * 头部数据
 */
export function getRealTimeAppendData() {
  return (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const { subModuleId } = getState().summary.get('searchParams').toJS();
    const date = moment().format('YYYYMMDD');
    const startHour = `${date}00`;
    const endHour = `${date}23`;
    dispatch({
      rest: SummaryRest.realTimeAppend.fetch,
      data: {
        appId, platformId, startHour, endHour, version: '-1',
        subModuleId,
        queryAllVmTypes: true,
      },
    });
  };
}


// 改变趋势图的tag
export const changeTrendTag = createAction(CHANGE_TREND_TAG);

// 改变崩溃排行的tag
export const changeSummaryRankTag = createAction(CHANGE_RANK_TAG);

// 改变概览类型
export const changePageType = createAction(CHANGE_PAGE_TYPE);

// 改变趋势的搜索条件
export const changeTrendParams = createAction(CHANGE_TREND_PARAMS);

// 改变top3展示状态
export const changeTop3Status = createAction(CHANGE_TOP3_STATUS);

// 实时或累计状态
export const changeDataType = createAction(CHANGE_DATA_TYPE);

// topIssue显示数据类型状态
export const changeTopIssueDataType = createAction(CHANGE_TOPISSUEDATA_TYPE);

export const changeSummaryVmSelectValue = createAction(CHANGE_SUMMARY_VM_SELECT_VALUE);

export const updateSummarySearchParams = createAction('UPDATE_SUMMARY_SEARCH_PARAMS');

export const updateSummarySelectOptions = createAction('updateSummarySelectOptions');

export const setCountryGroupConfigModalVisible = createAction('SET_COUNTRY_GROUP_CONFIG_MODAL_VISIBLE');

/**
  * 查询趋势数据或者比较数据, 通过isTrend来区分.
  * 通过判断是否isRealTime来分别调用getRealTimeHourlyData和getTrendData
 * @param params {{ trend: boolean, compare: boolean, prevDay: boolean, prevWeek: boolean }}
  */
export function getTrendOrCompareData(params) {
  let {
    trend = false,
    compare = false,
    prevDay,
    prevWeek,
  } = params || {};

  return (dispatch, getState) => {
    const isRealTime = getState().summary.get('searchParams').get('isRealTime');
    const dataType = getState().summary.get('searchParams').get('dataType');
    if (parseInt(isRealTime) === 1 && dataType === 'appendly') {
      return dispatch(getTrendOrCompareDataAppend(params));
    }
    if (isNullish(prevDay)) {
      prevDay = getState().summary.get('searchParams').get('enableRealtimePrevDayTrend');
    }
    if (isNullish(prevWeek)) {
      prevWeek = getState().summary.get('searchParams').get('enableRealtimePrevWeekTrend');
    }

    if (parseInt(isRealTime)) {
      const promises = [];
      trend && promises.push(dispatch(getRealTimeHourlyData('realTimeTrendData', false)));
      compare && promises.push(dispatch(getRealTimeHourlyData('realTimeCompareData'), false));
      prevDay && promises.push(dispatch(getRealTimeHourlyData('realTimePrevDayTrendData'), false));
      prevWeek && promises.push(dispatch(getRealTimeHourlyData('realTimePrevWeekTrendData'), false));
      return Promise.all(promises);
      // }
    } else {
      // 几天的，获取每天的数据
      let type = '';
      if (trend) {
        type = 'trendData';
      } else if (compare) {
        type = 'compareData';
      }
      return dispatch(getTrendData(type));
    }
  };
}

/**
 * @param params {{ trend: boolean, compare: boolean, prevDay: boolean, prevWeek: boolean }}
 */
export function getTrendOrCompareDataAppend(params) {
  let {
    trend = false,
    compare = false,
    prevDay,
    prevWeek,
  } = params || {};

  return (dispatch, getState) => {
    if (isNullish(prevDay)) {
      prevDay = getState().summary.get('searchParams').get('enableRealtimePrevDayTrend');
    }
    if (isNullish(prevWeek)) {
      prevWeek = getState().summary.get('searchParams').get('enableRealtimePrevWeekTrend');
    }

    const promises = [];
    trend && promises.push(dispatch(getRealTimeHourlyData('realTimeTrendData', true)));
    compare && promises.push(dispatch(getRealTimeHourlyData('realTimeCompareData', true)));
    prevDay && promises.push(dispatch(getRealTimeHourlyData('realTimePrevDayTrendData', true)));
    prevWeek && promises.push(dispatch(getRealTimeHourlyData('realTimePrevWeekTrendData', true)));
    return Promise.all(promises);
  };
}

/**
 * 获取趋势数据
 */
function getTrendData(dataType) {
  return (dispatch, getState) => {
    const { appId, pid: platformId } = getState().app.get('current').toJS();
    const isRegionalDashboard = ProductSummaryUtil.getIsRegionalDashboard(platformId);
    const countryGroupConfigList = getState().summary.get('countryGroupConfigList').toJS();
    const {
      trendVersion, startDate1, endDate1, date1, startDate2, endDate2, date2, compareVersion,
      trendCountry, compareCountry,
      vmSelectValue,
      trendUserSceneTag,
      isIntegratedAppTrend,
      integratedAppId,
      subModuleId,
    } = getState().summary.get('searchParams').toJS();

    const { pageType } = getState().summary.get('selectOptions').toJS();
    const vmTypeList = CsvUtil.singleLineParse(vmSelectValue).filter(x => x.trim()).map(x => Number(x));
    const type = PAGE_TYPE_CONVERT[pageType];
    const isTrend = dataType === 'trendData';

    const { startDate, endDate } = isTrend
      ? getStartEndDateFromStr(date1, startDate1, endDate1)
      : getStartEndDateFromStr(date2, startDate2, endDate2);

    const usedCountry = isTrend ? trendCountry : compareCountry;
    const countryList = isRegionalDashboard
      ? CountryUtil.queryStringToList(usedCountry)
      : [];
    const needCountryDimension = isRegionalDashboard && countryList.length > 0;

    const userSceneTagList = (trendUserSceneTag || '').split(',').filter(x => x);
    const needUserSceneTagDimension = userSceneTagList.length > 0;

    const versionList = isTrend
      ? VersionUtil.makeVersionListFromVersionCsvString(trendVersion)
      : VersionUtil.makeVersionListFromVersionCsvString(compareVersion);
    const isMultiVersion = VersionUtil.isMultipleVersions(versionList);

    return dispatch({
      rest: SummaryRest.trend.getTrend,
      data: {
        appId: isIntegratedAppTrend && integratedAppId
          ? appId + ':' + integratedAppId
          : appId,
        platformId,
        type,
        dataType,
        vmTypeList,
        mergeVmTypes: true,
        ...(subModuleId && { subModuleId }),
        startDate,
        endDate,
        mergeMultipleVersionsWithInaccurateResult: isMultiVersion,
        version: isMultiVersion ? undefined : versionList[0],
        versionList: isMultiVersion ? versionList : undefined,
        needCountryDimension,
        countryList,
        needUserSceneTagDimension,
        ...(needUserSceneTagDimension ? { userSceneTagList } : {}),
      },
    });
  };
}

// 小时级数据（分小时 或 累计）
function getRealTimeHourlyData(dataType, isAppend) {
  return (dispatch, getState) => {
    const { app, summary } = getState();
    const { appId, pid: platformId } = app.get('current').toJS();
    const isRegionalDashboard = ProductSummaryUtil.getIsRegionalDashboard(platformId);
    const countryGroupConfigList = getState().summary.get('countryGroupConfigList').toJS();
    const {
      trendVersion, compareVersion, startDate1, endDate1, startDate2, endDate2, date1, date2,
      trendCountry, compareCountry,
      vmSelectValue,
      trendUserSceneTag,
      isIntegratedAppTrend,
      integratedAppId,
      subModuleId,
    } = summary.get('searchParams').toJS();
    const { pageType } = summary.get('selectOptions').toJS();
    const vmTypeList = VmTypeUtil.vmSelectValueToVmTypeList(vmSelectValue);
    const type = PAGE_TYPE_CONVERT[pageType];
    const isTrend = dataType !== 'realTimeCompareData';

    const usedCountry = isTrend ? trendCountry : compareCountry;
    const countryList = isRegionalDashboard
      ? CountryUtil.queryStringToList(usedCountry)
      : [];
    const needCountryDimension = isRegionalDashboard && countryList.length > 0;

    const userSceneTagList = (trendUserSceneTag || '').split(',').filter(x => x);
    const needUserSceneTagDimension = userSceneTagList.length > 0;

    let { startDate, endDate } = isTrend
      ? getStartEndDateFromStr(date1, startDate1, endDate1)
      : getStartEndDateFromStr(date2, startDate2, endDate2);
    if (dataType === 'realTimePrevDayTrendData') {
      startDate = moment(startDate).subtract(1, 'days').format('YYYYMMDD');
      endDate = moment(endDate).subtract(1, 'days').format('YYYYMMDD');
    } else if (dataType === 'realTimePrevWeekTrendData') {
      startDate = moment(startDate).subtract(7, 'days').format('YYYYMMDD');
      endDate = moment(endDate).subtract(7, 'days').format('YYYYMMDD');
    }

    const versionList = isTrend
      ? VersionUtil.makeVersionListFromVersionCsvString(trendVersion)
      : VersionUtil.makeVersionListFromVersionCsvString(compareVersion);
    const isMultiVersion = VersionUtil.isMultipleVersions(versionList);

    return dispatch({
      rest: isAppend ? SummaryRest.realTime.getAppendMinutelyStat : SummaryRest.realTime.getHourlyStat,
      data: {
        appId: isIntegratedAppTrend && integratedAppId
          ? appId + ':' + integratedAppId
          : appId,
        platformId,
        type,
        vmTypeList,
        mergeVmTypes: true,
        dataType,
        ...(subModuleId && { subModuleId }),
        mergeMultipleVersionsWithInaccurateResult: isMultiVersion,
        version: isMultiVersion ? undefined : versionList[0],
        versionList: isMultiVersion ? versionList : undefined,
        startDate: `${startDate}00`,
        endDate: `${endDate}23`,
        needCountryDimension,
        countryList,
        needUserSceneTagDimension,
        ...(needUserSceneTagDimension ? { userSceneTagList } : {}),
      },
    });
  };
}

/**
 * 获取排行榜数据
 */

function getTopData(rest, mode, field) {
  const sortByException = !mode.includes('access');
  return () => {
    return (dispatch, getState) => {
      const { app, summary } = getState();
      const { appId, pid } = app.get('current').toJS();
      const {
        rankVersion,
        startDate3,
        rankMaxDate,
        rankCountry,
        rankVmSelectValue,
      } = summary.get('searchParams').toJS();
      const { pageType } = getState().summary.get('selectOptions').toJS();
      const type = PAGE_TYPE_CONVERT[pageType];

      const versionList = VersionUtil.makeVersionListFromVersionCsvString(rankVersion);
      const isMultiVersion = VersionUtil.isMultipleVersions(versionList);

      const minDate = startDate3;
      const maxDate = rankMaxDate || startDate3;

      const body =  {
        appId,
        pid,
        platformId: pid,
        mergeMultipleVersionsWithInaccurateResult: isMultiVersion,
        version: isMultiVersion ? undefined : versionList[0],
        versionList: isMultiVersion ? versionList : undefined,
        minDate,
        maxDate,
        mergeMultipleDatesWithInaccurateResult: minDate !== maxDate,
        type,
        limit: 5,
        field,
        sortByException,
        countryList: CountryUtil.queryStringToList(rankCountry),
        vmTypeList: VmTypeUtil.vmSelectValueToVmTypeList(rankVmSelectValue),
        needQueryFieldRollup: true,
      };
      sessionStorage.setItem(mode,JSON.stringify(body));
      return dispatch({
        rest,
        data: body,
      });
    };
  };
}
