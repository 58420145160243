// 【【CS】sem投放数据追踪和分析】
// https://tapd.woa.com/tapd_fe/20421727/story/detail/1020421727121096919
// WeTest SEM营销推广追踪需求，接入Google Analytics统计

const GTAG_ID = 'G-QK4KX3WCNE';

const GOOGLE_ANALYTICS_ENABLED = window.location.hostname.includes('crashsight.qq.com');

window.dataLayer = window.dataLayer || [];
function gtag() {
  if (!GOOGLE_ANALYTICS_ENABLED) {
    return;
  }
  window.dataLayer.push(arguments);
}

export const GoogleAnalytics = Object.freeze({
  init() {
    // wetest那边的sem需求，现在不统计google analytics了，用腾讯云rum就够了
    /* if (!GOOGLE_ANALYTICS_ENABLED) {
      return;
    }
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
    document.head.appendChild(script);
    gtag('js', new Date());
    gtag('config', GTAG_ID); */
  },

  gtag,
});
