import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FILE_OPTIONS, SYSTEM_LOG_PLATFORM_ID_TO_DESC } from 'utils/constants';
import { isEmptyObject, makeDetailObjectFromStackLine, handleLog, LOG_ABBR_LEVEL_TO_NUMBER } from 'utils/helper';
import * as platformUtils from 'utils/platform';
import {
  isAndroid,
  isAndroidOrHarmony,
  isIos,
  isPc,
  isPcOrLinux,
  isPlaystation,
  isSwitch,
  PlatformUtil
} from 'utils/platform';
import { StarOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, Radio, Space, Tag, Tooltip } from 'antd';
import { cyan, red } from '@ant-design/colors';
import { isNotNullish, isNullish } from 'utils/nullish';
import dataDetailStyle from './DataDetail.scss';
import { geekblue } from '@ant-design/colors';
import { enrichShrunkFilePathOnStackLineObj, StackUtil } from 'utils/stack';
import { connect, useDispatch } from 'react-redux';
import { PcStackSourceEnum } from 'utils/constants/pc-stack-source-enum';
import uniq from 'lodash/array/uniq';
import { isZh, ze } from 'utils/zhEn';
import escapeRegExp from 'lodash/string/escapeRegExp';
import { CS_STYLES } from 'utils/constants/style-constants';
import RetraceAnrTraceModal from 'components/exception/issue/RetraceAnrTraceModal';
import { patchCurrentIssueInfo } from 'reducers/issue/issueActions';
import { useTranslation } from 'react-i18next';
import StackLineMemoryInfo from 'components/exception/issue/DataDetail/StackLineMemoryInfo';
import { MinidumpUnwindTypeInt, MinidumpUnwindUtil } from 'utils/constants/minidump-unwind';
import { DocsUtil } from 'utils/docs-util';
import isString from 'lodash/lang/isString';

import { BsBug } from "react-icons/bs";
import IconDefinitive from 'svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_errorstack_mark.svg';
import IconSymbolFail from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_miss_icon.svg';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import NoticeIcon from 'svg/v2/newcs_dashboard_notice_icon.svg';
import { MdFilterAlt } from 'react-icons/md';
import { CrashModuleUtil } from 'utils/crash-module-util';
import CopyreportIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_copyreport_icon_normal.svg';
import { ClipboardUtil } from 'utils/clipboard-util';

const SystemSoLinesFilterType = Object.freeze({
  ALL: 'ALL',
  ONLY_APP: 'ONLY_APP',
});

const SystemSoLinesFilterTypeOptions = [{
  label: ze('显示全部', 'All'),
  value: SystemSoLinesFilterType.ALL,
}, {
  label: ze('仅显示应用模块行（去重）', 'Only App Modules (Dedup)'),
  value: SystemSoLinesFilterType.ONLY_APP,
}];

const SKIP_STACK_TYPE_TO_REASON = {
  1: ze('系统堆栈行不参与分类', 'Stack trace from system modules are ignored'),
  2: ze('用户配置的分类跳过规则', 'User configured rule'),
  3: ze('缺少模块信息', 'No module info'),
};

function makeSkipStackReasonTextBlock(nullableSkipStackInfo) {
  if (!nullableSkipStackInfo) {
    return null;
  }
  const { type, skipStack: skippedStackList } = nullableSkipStackInfo;
  const reason = ze('原因：', 'Reason: ') + SKIP_STACK_TYPE_TO_REASON[nullableSkipStackInfo.type] || type;
  return <div>
    <div>{ reason }</div>
    { type === 2 && skippedStackList && <div>{ ze('命中以下关键词：', 'Matched keyword:') }</div> }
    { type === 2 && skippedStackList && skippedStackList.map(x => <div>{ x }</div>) }
  </div>;
}

export const DataDetailUtil = (function() {
  const BINARY_INFO_HEADER = 'Binary Infos:';
  const SYSTEM_SO_INFO_HEADER = 'System SO infos:';
  const SYSTEM_BINARY_INFO_HEADER = 'System Binary infos:';
  const FD_INFO_HEADER = 'FD infos:';
  const MEMORY_NEAR_HEADER = /^memory near.*:$/;

  const ALL_HEADERS = Object.freeze([
    SYSTEM_SO_INFO_HEADER,
    BINARY_INFO_HEADER,
    SYSTEM_BINARY_INFO_HEADER,
    FD_INFO_HEADER,
    MEMORY_NEAR_HEADER,
  ]);

  const REGISTER_INFO_EXCLUDE_HEADERS = ALL_HEADERS;

  /**
   * @param filename {string}
   * @param attachList {{ fileName: string, fileType: number, content: string }[]}
   * */
  function _extractFileContent(filename, attachList) {
    if (!Array.isArray(attachList)) {
      return '';
    }
    const findRes = attachList.find(x => x.fileName === filename);
    if (!findRes) {
      return '';
    }
    return String(findRes.content || '');
  }

  /**
   * @param content {string} 内容
   * @param filterHeaderList {(string | RegExp)[]} 需要过滤的段落的header
   * @param allHeaderList {(string | RegExp)[]} 所有段落的header
   * @param not {boolean} 如果为true，表示取反（匹配到headerList的段落被丢弃，保留剩下的）
   * */
  function filterSectionLinesByHeaderList(content, filterHeaderList, allHeaderList, not) {
    // const headerRegex = new RegExp(`(${allHeaderList.map(x => escapeRegExp(x)).join('|')})`, 'i');
    // const splitRes = content.split(headerRegex).map(x => x.trim());

    const allHeaderRegexList = allHeaderList.map(x => {
      return isString(x) ? new RegExp(`^${x}$`, 'i') : x;
    });
    const filterHeaderRegexList = filterHeaderList.map(x => {
      return isString(x) ? new RegExp(`^${x}$`, 'i') : x;
    });

    // 先按照allHeaderList分割成section，header和content各占一个section
    const lines = (content || '').split('\n').map(x => x.trimEnd()); // 移除行尾空格，\r等字符
    // console.log('wenqing lines ', lines);

    /** @type {{ header: string, bodyLineList: string[] }[]} */
    const splitRes = [];

    let tempHeader = undefined;
    let tempBodyLineList = [];
    lines.forEach((line, i) => {
      const isHeader = allHeaderRegexList.some(x => x.test(line));
      if (isHeader || i === lines.length - 1) {
        if (isNotNullish(tempHeader) || tempBodyLineList.length > 0) {
          splitRes.push({
            header: tempHeader,
            bodyLineList: tempBodyLineList,
          });
          tempBodyLineList = [];
        }
        tempHeader = line;
      } else {
        tempBodyLineList.push(line);
      }
    });

    // console.log('wenqing splitRes ', splitRes);

    const filteredSplitRes = splitRes.filter(splitItem => {
      const { header, bodyLineList } = splitItem;
      const isFilterHeaderMatched = isNotNullish(header) && filterHeaderRegexList.some(x => x.test(header));
      return isFilterHeaderMatched !== not;
    });

    // console.log('wenqing filteredSplitRes ', filteredSplitRes);

    return filteredSplitRes
      .map(x => [x.header, ...x.bodyLineList])
      .flat()
      .map(x => (x || '').trim())
      .filter(x => x);
  }

  /**
   * 提取出加载模块信息的文本行
   * @param pid {number}
   * @param attachList {{ fileName: string, fileType: number, content: string }[]}
   * */
  function makeSoInfoTextLines(pid, attachList) {
    if (!PlatformUtil.isMobileOrMobileLike(pid) || !Array.isArray(attachList)) {
      return [];
    }
    const attachFilename = isAndroidOrHarmony(pid) ? FILE_OPTIONS.加载模块信息.filename : FILE_OPTIONS.加载模块信息IOS.filename;
    const content = _extractFileContent(attachFilename, attachList);
    const lines = filterSectionLinesByHeaderList(content, [SYSTEM_SO_INFO_HEADER, SYSTEM_BINARY_INFO_HEADER], ALL_HEADERS, false);
    return lines;
  }

  function makeFdInfoTextLines(pid, attachList) {
    if (!PlatformUtil.isMobileOrMobileLike(pid) || !Array.isArray(attachList)) {
      return [];
    }
    const attachFilename = isAndroidOrHarmony(pid) ? FILE_OPTIONS.崩溃线程寄存器信息.filename : FILE_OPTIONS.崩溃线程寄存器信息IOS.filename;
    const content = _extractFileContent(attachFilename, attachList);
    const lines = filterSectionLinesByHeaderList(content, [FD_INFO_HEADER], ALL_HEADERS, false);
    return lines;
  }

  function makeRegisterInfoTextLines(pid, attachList, modelBit) {
    if (!PlatformUtil.isMobileOrMobileLike(pid) || !Array.isArray(attachList)) {
      return [];
    }
    const attachFilename = isAndroidOrHarmony(pid) ? FILE_OPTIONS.崩溃线程寄存器信息.filename : FILE_OPTIONS.崩溃线程寄存器信息IOS.filename;
    const content = _extractFileContent(attachFilename, attachList);
    let lines = filterSectionLinesByHeaderList(content, REGISTER_INFO_EXCLUDE_HEADERS, ALL_HEADERS, true);
    if (modelBit === 64) {
      lines = lines.map(x => x.replace(/r(\d+)=/g, (match, p1) => `x${p1}=`));
    }
    return lines;
  }

  function makeOtherTypeTextLines(pid, attachFilename, attachList) {
    const content = _extractFileContent(attachFilename, attachList);
    return content.split('\n').map(x => x.trim()).filter(x => x);
  }

  return Object.freeze({
    BINARY_INFO_HEADER,
    SYSTEM_SO_INFO_HEADER,
    SYSTEM_BINARY_INFO_HEADER,
    FD_INFO_HEADER,
    makeSoInfoTextLines,
    makeFdInfoTextLines,
    makeRegisterInfoTextLines,
    makeOtherTypeTextLines,
    filterSectionLinesByHeaderList,
  });
})();

const DataDetail = ({
  issue,
  stackLineDetailObjectList,
  dataType,
  pid,
  style,
  exceptionType,
  obfuscationFileInfo,
  pcStackSource,
  reduxState,
  isRetracedStack,
  symbolInfoLoading,
  symbolInfoList,
  ignoreSymbolInfoList,
  isShowingMinidumpStack,
  logType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMinidump = isPcOrLinux(pid) || isPlaystation(pid) || isShowingMinidumpStack;

  // 当前是否是渲染原始堆栈。 true：原始堆栈  false：解析堆栈
  let isOriginalStack;
  if (isNotNullish(isRetracedStack)) {
    isOriginalStack = !isRetracedStack;
  } else {
    isOriginalStack = !issue || issue.get('stackType') === 'original';
  }

  const appId = reduxState.app.get('current').get('appId');
  const stackFilePathDisplayType = reduxState.global.get('localStorage').get('stackFilePathDisplayType') || 'fullFilePath';

  const hasSymbolFileSoNameList = uniq((symbolInfoList || []).map(x => x.soName)).filter(x => x);
  const hasSymbolFileUuidList = uniq((symbolInfoList || []).map(x => x.uuid)).filter(x => x);
  const ignoredSymbolFileSoNameList = uniq((ignoreSymbolInfoList || []).map(x => x.soName)).filter(x => x);
  const ignoredSymbolFileUuidList = uniq((ignoreSymbolInfoList || []).map(x => x.uuid)).filter(x => x);

  const [systemSoLinesFilterType, setSystemSoLinesFilterType] = useState(SystemSoLinesFilterType.ALL);

  const [isRetraceAnrTraceModalVisible, setIsRetraceAnrTraceModalVisible] = useState(false);

  const [stackLineNumberToIsExpanded, setStackLineNumberToIsExpanded] = useState({});

  function highlight(stackLineDetailObject) {
    const obj = stackLineDetailObject;
    let flag = false;
    if (issue && !isEmptyObject(issue.toJS())) {
      const { detailInfo } = issue.toJS();
      console.log(detailInfo, 'detailInfo');
      if (detailInfo) {
        console.log('detailinfo的第一个if');
        const { processName, bundleId } = detailInfo;
        const regProcessName = new RegExp(`(${processName})`, 'gi');
        const regBundleId = new RegExp(`(${bundleId})`, 'gi');
        if (parseInt(pid) === 1) { // 安卓,进程和bunleId来匹配
          flag = regProcessName.test(obj.line) || regBundleId.test(obj.line);
        }
        // 同时考虑ios系统和mac系统，pid为2，ios；pid为4，为mac；
        if ((parseInt(pid) === 2 && obj) || (parseInt(pid) === 4 && obj)) {
          console.log('detailinfo的第二个if');
          flag = regProcessName.test(obj.libname);
        }
      }
    }
    return flag;
  }

  function getTextStyleFromExtraInfo(stackLineDetailObject) {
    const { extraInfo } = stackLineDetailObject;
    if (!extraInfo) {
      return null;
    }
    const { isDefinitive, isSkipped } = extraInfo;
    if (isDefinitive) {
      return { color: cyan.primary };
    }
    if (isSkipped) {
      return { color: '#AAA' };
    }
  }

  function getTooltipDistinctIconCount(allLinesInfo) {
    const {
      hasDefinitiveLines,
      hasSymbolicationFailureLines,
      hasUnknownModuleLines,
      hasSuspectedHackingLines,
    } = allLinesInfo ?? {};

    const distinctIconCount = [
      hasDefinitiveLines,
      hasSymbolicationFailureLines,
      hasUnknownModuleLines,
      hasSuspectedHackingLines,
    ].filter(x => x).length;

    return distinctIconCount;
  }

  function getTooltipMinWidth(allLinesInfo) {
    return getTooltipDistinctIconCount(allLinesInfo) >= 2 ? '36px' : '20px';
  }

  function makeTooltipBeforeStackLine(stackLineDetailObject, isSymbolFileUploaded, allLinesInfo) {
    const { extraInfo, libname } = stackLineDetailObject;
    const {
      isDefinitive,
      isSymbolicationFailure,
      stackLineRetraceStatus,
      isSuspectedHacking,
      nullableSkipStackInfo,
    } = extraInfo || {};

    // 判断是否需要对齐显示
    const minWidth = getTooltipMinWidth(allLinesInfo);
    const allLinesDistinctIconCount = getTooltipDistinctIconCount(allLinesInfo);

    const isUnknownModule = libname === StackUtil.UNKNOWN_MODULE;
    let failReasonTooltip;
    if (stackLineRetraceStatus) {
      failReasonTooltip = StackUtil.makeSymbolicationFailureReasonInfo(stackLineRetraceStatus, isSymbolFileUploaded).long;
    }

    const skipStackReasonDom = makeSkipStackReasonTextBlock(nullableSkipStackInfo);

    const showDict = {
      showSkipStack: !isDefinitive && nullableSkipStackInfo,
      showDefinitive: isDefinitive,
      showUnknownModule: isUnknownModule,
      showSymbolicationFail: !isUnknownModule && isSymbolicationFailure,
      showSuspectedHacking: !isUnknownModule && isSuspectedHacking,
    };
    const showCount = Object.values(showDict).filter(x => x).length;

    // --story=120750529 【CS】上报堆栈前面的关键行/还原失败图标，改为占据固定位置
    const onlyHasSymbolicationFail = showDict.showSymbolicationFail && showCount === 1;

    return <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        minWidth,
      }}
    >
      { showDict.showSkipStack && <Tooltip
        overlayStyle={{ minWidth: '320px' }}
        title={<div>
          <div>{ze('该行堆栈不参与问题分类。','This line of stack lines is ignored for issue grouping.')}</div>
          <div>{ skipStackReasonDom }</div>
        </div>}
      ><MdFilterAlt style={{ color: '#999' }}/></Tooltip> }
      { showDict.showDefinitive && <Tooltip
        title={<div>
          <div>{ze('该行堆栈是用于问题分类的关键行。','Stack trace lines used for issue grouping.')}</div>
        </div>}
      ><IconDefinitive/></Tooltip> }
      {
        /** --story=120750529 【CS】上报堆栈前面的关键行/还原失败图标，改为占据固定位置 */
        onlyHasSymbolicationFail && allLinesDistinctIconCount >= 2 && <IconDefinitive style={{ visibility: 'hidden' }}/>
      }
      { showDict.showUnknownModule && <Tooltip
        title={t('REPORTDETAIL.unknownModuleTooltip')}
        overlayStyle={{ whiteSpace: 'pre-wrap' }}
      ><WrappedTipsIcon/></Tooltip> }
      { showDict.showSymbolicationFail && <Tooltip
        title={failReasonTooltip}
      ><IconSymbolFail /></Tooltip>}
      { showDict.showSuspectedHacking && <Tooltip
        overlayStyle={{ maxWidth: '500px' }}
        title={ze('so不在/proc/self/maps中，疑似外挂行为', 'The so file is not found in /proc/self/maps, suspected hacking.')}
      ><BsBug style={{ width: 16, height: 16, color: CS_STYLES.PRIMARY_COLOR }} /></Tooltip> }
    </div>;
  }

  function renderLi(lines, flag) {
    console.log('lines', lines);
    return lines.map((line, index) => {
      const textStyleFromExtraInfo = getTextStyleFromExtraInfo(line);
      const html = (line.libname && line.hexadecimal && line.addr)
        ? (
          <tr
            key={index}
            style={textStyleFromExtraInfo}
            className={highlight(line) && flag
              ? style.highLight : ''}>
            <td className={style.libName} title={line.libname}>
              <span className={style.index}>
                {' '}
                {index}
                {' '}
              </span>
              {makeTooltipBeforeStackLine(line)}<span>{line.libname}</span>
            </td>
            <td className={style.addr} style={{    whiteSpace: 'normal'}}>
              {line.hexadecimal}
              {' '}
              {line.addr}
              {' '}
              {line.stackTraceNum && '+' }
              {' '}
              {line.stackTraceNum}
            </td>
          </tr>
        )
        : (
          <tr
            key={index}
            className={highlight(line) && flag
              ? style.highLight : ''}>
            <td colSpan="2" className={style.col2}>
              <div className={style.normal_wrap} style={{ whiteSpace: 'pre-wrap' }}>
                <span className={style.index}>
                  {' '}
                  {parseInt(pid) === 2 ? index : index + 1}
                  {' '}
                </span>
                {makeTooltipBeforeStackLine(line)}<span style={textStyleFromExtraInfo}>{line.line || line}</span>
              </div>
            </td>
          </tr>
        );
      return html;
    });
  }

  function onClickCopyStackToClipboard() {
    let {
      stackLineObjList,
      stackBeforeSplit,
      mobileOriginalStackBeforeSplit,
    } = makeStackParseResult();
    const allLines = stackLineObjList.map(line => line.line).filter(x => x).join('\n');
    ClipboardUtil.copyToClipboard(allLines);
    message.success(ze('已复制至剪贴板', 'Copied to clipboard.'));
  }

  function renderStackHeader(detailStackMemoryInfoList, isShowingMinidumpStack, allLinesInfo) {
    const { hasFormattedLines } = allLinesInfo;
    // 判断是否需要对齐显示
    const toolTipMinWidth = getTooltipMinWidth(allLinesInfo);

    const hasMinidumpUnwindType = detailStackMemoryInfoList.some(x => !!x.unwindType);
    const isMinidump = isPcOrLinux(pid) || isShowingMinidumpStack;

    let stackContentTitle = hasFormattedLines
      ? 'Function (File Name: Line Number)'
      : 'Stack Trace';
    let stackContentTitleSuffix = '';
    if (hasFormattedLines && isAndroid(pid)) {
      stackContentTitleSuffix = ' [Arch]';
    } else if (hasFormattedLines && isPcOrLinux(pid)) {
      stackContentTitleSuffix = ' [Arch:OS:Module UUID]';
    }

    return <div
      className={dataDetailStyle.stackLineContainer}
    ><div
      className={dataDetailStyle.stackHeader}
    >
      <div
        className={dataDetailStyle.tooltip}
      ><div style={{ minWidth: toolTipMinWidth }}/></div>
      <div className={dataDetailStyle.lineNumber}></div>
      { hasFormattedLines && <div className={dataDetailStyle.libName}>
        <div>{ isIos(pid) ? 'Image' : 'Module' }</div>
      </div> }
      { hasFormattedLines && isMinidump && hasMinidumpUnwindType && <div
        className={dataDetailStyle.unwindType}
      >Mechanism</div> }
      { hasFormattedLines && <div className={dataDetailStyle.hex}>RVA</div> }
      <div
        className={dataDetailStyle.stackContent}
      >{stackContentTitle}{stackContentTitleSuffix}</div>
      <div style={{ flexGrow: 1 }}/>
      <Tooltip
        title={ze('复制堆栈到剪贴板', 'Copy Stack Trace to Clipboard')}
      >
        <Button
          className={dataDetailStyle.copyStackBtn}
          type='link'
          style={{ padding: 0, height: '14px', display: 'flex', alignSelf: 'center' }}
          onClick={() => onClickCopyStackToClipboard()}
        ><CopyreportIcon /></Button>
      </Tooltip>
    </div></div>;
  }

  function renderStackLine(lineObj, index, stack, detailStackMemoryInfoList, isShowingMinidumpStack, allLinesInfo) {
    const {
      hasDefinitiveLines,
      hasSymbolicationFailureLines,
      hasUnknownModuleLines,
    } = allLinesInfo;

    detailStackMemoryInfoList = detailStackMemoryInfoList || [];
    const lineNumber = index + 1;
    const hasLibName = !!lineObj.libname;

    const prefixIconCount = [hasDefinitiveLines, hasSymbolicationFailureLines, hasUnknownModuleLines].filter(x => x).length;

    const {
      soNameWithoutPath,
      stackContentBeforeFilePath,
      filePath,
      shrunkFilePath,
      stackContentAfterFilePath,
      extraInfo,
      inlineLines,
    } = lineObj;

    const {
      stackLineRetraceStatus,
      isSymbolFileUploaded,
      needFormat,
    } = extraInfo || {};

    if (!needFormat) {
      return <div className={dataDetailStyle.stackLineContainer}><div className={dataDetailStyle.stackLine}>
        <div className={dataDetailStyle.tooltip}>{ makeTooltipBeforeStackLine(lineObj, isSymbolFileUploaded, allLinesInfo) }</div>
        <div className={dataDetailStyle.lineNumber}>{lineNumber}</div>
        <div>{lineObj.line || lineObj}</div>
      </div></div>;
    }

    const isMinidump = isPcOrLinux(pid) || isShowingMinidumpStack;

    let displayFilePath = (stackFilePathDisplayType === 'fullFilePath' || !shrunkFilePath) ? filePath : shrunkFilePath;
    if (displayFilePath) {
      if (!displayFilePath.startsWith('(')) {
        displayFilePath = `(${displayFilePath}`;
      }
      if (!displayFilePath.endsWith(')')) {
        displayFilePath = `${displayFilePath})`;
      }
    }

    /* const libNameDom = lineObj.libname !== StackUtil.UNKNOWN_MODULE
      ? lineObj.libname
      : <Tooltip
          title={t('REPORTDETAIL.unknownModuleTooltip')}
          overlayStyle={{ maxWidth: '80vw', whiteSpace: 'pre-wrap' }}
        >
          <Space style={{ color: '#999' }}>
            <div >{ lineObj.libname }</div>
            <WrappedTipsIcon/>
          </Space>
        </Tooltip>;  */
    const libNameDom = lineObj.libname !== StackUtil.UNKNOWN_MODULE
      ? lineObj.libname
      : <span style={{ color: CS_STYLES.SUB_TEXT_COLOR }}>{ lineObj.libname }</span>;

    const matchedDetailStackMemoryInfo = detailStackMemoryInfoList.find(x => {
      return x.lineNumber === lineNumber && (x.lineStack || '').trim() === (lineObj.rawLine || lineObj.line || '').trim();
    });
    const hasMatchedDetailStackMemoryInfo = isNotNullish(matchedDetailStackMemoryInfo);
    const hasMinidumpUnwindType = detailStackMemoryInfoList.some(x => !!x.unwindType);

    /* let symbolicationFailureReasonDom = undefined;
    if (stackLineRetraceStatus) {
      const reasonInfo = StackUtil.makeSymbolicationFailureReasonInfo(stackLineRetraceStatus, isSymbolFileUploaded);
      symbolicationFailureReasonDom = <Tooltip
        title={reasonInfo.long}
      ><Tag
        color='red'
        style={{ userSelect: 'none' }}
      >{ reasonInfo.short }</Tag></Tooltip>;
    } */

    const showExpandStackLineButton = isMinidump
      ? detailStackMemoryInfoList.length > 0
      : hasMatchedDetailStackMemoryInfo && !!matchedDetailStackMemoryInfo.asmCodeInfo;

    const stackLineDom = <div
      className={dataDetailStyle.stackLine}
    >
      <div className={dataDetailStyle.tooltip}>{ makeTooltipBeforeStackLine(lineObj, isSymbolFileUploaded, allLinesInfo) }</div>
      <div className={dataDetailStyle.lineNumber}>{lineNumber}</div>
      { hasLibName && <div className={dataDetailStyle.libName}>
        <div>{ libNameDom }</div>
      </div> }
      { hasLibName && isMinidump && hasMinidumpUnwindType && <div
        className={dataDetailStyle.unwindType}
      ><Tooltip
        title={ze('点击查看说明', 'Click to view description.')}
      >
        <a
          href={DocsUtil.makeDocsUrl(isZh() ? '/terms/#堆栈回溯' : '/terms#stack-backtracing')}
          target="_blank"
          rel="noopener noreferrer"
        ><Tag
          style={{
            margin: 0,
            minWidth: '64px',
            textAlign: 'center',
            paddingLeft: 0,
            paddingRight: 0,
          }}
          color='purple'
        >{ MinidumpUnwindUtil.getLabelByTypeInt((matchedDetailStackMemoryInfo || {}).unwindType) }</Tag></a>
      </Tooltip></div> }
      { hasLibName && <div className={dataDetailStyle.hex}>{lineObj.hexadecimal}</div> }
      <div className={dataDetailStyle.stackContent}>
        <div>{[
          <span key={0} style={{ fontWeight: 'bold' }}>{stackContentBeforeFilePath}</span>,
          displayFilePath && <span key={1} style={{ fontWeight: 'bold', color: geekblue.primary }}>{ displayFilePath }</span>,
          stackContentAfterFilePath && <span key={2} style={{ fontWeight: 'bold' }}>{stackContentAfterFilePath}</span>,
        ].filter(x => x)}</div>
        { inlineLines && inlineLines.length > 0 && inlineLines.filter(x => x).map((inlineLineObj, i) => {
          const { stackContentBeforeFilePath, stackContentAfterFilePath, filePath, shrunkFilePath } = inlineLineObj;
          const displayFilePath = (stackFilePathDisplayType === 'fullFilePath' || !shrunkFilePath) ? filePath : shrunkFilePath;
          return <div key={i}>{[
            <span style={{ color: '#999' }}>(Inline) </span>,
            <span key={0} style={{ fontWeight: 'bold' }}>{stackContentBeforeFilePath}</span>,
            displayFilePath && <span key={1} style={{ fontWeight: 'bold', color: geekblue.primary }}>{`(${displayFilePath})`}</span>,
            stackContentAfterFilePath && <span key={2} style={{ fontWeight: 'bold' }}>{stackContentAfterFilePath}</span>,
          ].filter(x => x)}</div>;
        }) }
      </div>
      { showExpandStackLineButton && <div className={dataDetailStyle.stackMemoryExpand}>{
        <div
          style={{
            padding: '0 8px',
            cursor: 'pointer',
            visibility: hasMatchedDetailStackMemoryInfo ? 'visible' : 'hidden',
          }}
          onClick={() => {
            const oldIdExpanded = !!stackLineNumberToIsExpanded[lineNumber];
            setStackLineNumberToIsExpanded({
              ...stackLineNumberToIsExpanded,
              [lineNumber]: !oldIdExpanded,
            });
          }}
        >
          { !!stackLineNumberToIsExpanded[lineNumber] ? <UpOutlined/> : <DownOutlined/> }
        </div>
      }</div> }
    </div>;

    return <div className={dataDetailStyle.stackLineContainer}>
      { stackLineDom }
      { !!stackLineNumberToIsExpanded[lineNumber] && <StackLineMemoryInfo
        isMinidump={isMinidump}
        detailStackMemoryInfo={matchedDetailStackMemoryInfo}
      /> }
    </div>;
  }

  // 根据log数组生成带样式的li
  function renderLog(logs) {
    return logs.map((log, index) => {
      const numberLevel = Number(log.level);
      if (numberLevel === LOG_ABBR_LEVEL_TO_NUMBER.W) {
        return (
          <tr className={style.logWarn} key={`logli${index}`}>
            <td>
              <span className={style.index}>{index}</span>
              {log.line}
            </td>
          </tr>
        );
      } else if (numberLevel >= LOG_ABBR_LEVEL_TO_NUMBER.A) {
        return (
          <tr className={style.logError} key={`logli${index}`}>
            <td>
              <span className={style.index}>{index}</span>
              {log.line}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={`logli${index}`}>
            <td>
              <span className={style.index}>{index}</span>
              {log.line}
            </td>
          </tr>
        );
      }
    });
  }

  function othercase(opt) {
    const issueObj = issue.toJS() || {};
    const {
      attachList,
      crashDoc,
      pcRegisterInfoLines,
      pcModuleInfoList,
      isShowingMinidumpStack,
      isShowingRetracedAnrTrace,
      retracedAnrTrace,
    } = issueObj;
    const { esMap } = crashDoc || {};
    const { crashId, modelBit, appSoNameList } = esMap || {};
    const hasAppSoNameList = Array.isArray(appSoNameList) && appSoNameList.length > 0;

    let lines = [];
    if (opt.value === '崩溃线程寄存器信息') {
      lines = isPcOrLinux(pid) || isShowingMinidumpStack
        ? (pcRegisterInfoLines || [])
        : DataDetailUtil.makeRegisterInfoTextLines(pid, attachList, modelBit);
    } else if (opt.value === 'FD信息') {
      lines = DataDetailUtil.makeFdInfoTextLines(pid, attachList);
    } else if (opt.value === 'anrTrace') {
      lines = isShowingRetracedAnrTrace
        ? (retracedAnrTrace || '').split('\n').map(x => x.trim()).filter(x => x)
        : DataDetailUtil.makeOtherTypeTextLines(pid, opt.filename, attachList);
    } else if (opt.value === '加载模块信息') {
      lines = isPcOrLinux(pid) || isShowingMinidumpStack
        ? (pcModuleInfoList || []).map(x => `${x.moduleName}  ${x.uuid}`)
        : DataDetailUtil.makeSoInfoTextLines(pid, attachList);

      if (hasAppSoNameList) {
        const remainingAppSoSet = new Set(appSoNameList);
        const reg = new RegExp('(' + appSoNameList.map(x => escapeRegExp(x)).join('|') + ')');
        lines = lines.map(line => {
          const splitRes = String(line || '').split(reg);
          const hasAppSo = splitRes.length > 1;
          const appSo = hasAppSo ? splitRes[1] : '';

          const richLine = splitRes.map((x, i) => {
            if (i % 2 === 0) {
              return <span>{x}</span>;
            }
            return <Tooltip title={ze('应用so', 'App .so')}><span style={{ color: CS_STYLES.PRIMARY_COLOR }}>{x}</span></Tooltip>;
          });

          return {
            richLine,
            hasAppSo,
            appSo,
          };
        }).filter(x => {
          if (systemSoLinesFilterType === SystemSoLinesFilterType.ALL) {
            return true;
          }
          if (remainingAppSoSet.has(x.appSo)) {
            remainingAppSoSet.delete(x.appSo);
            return true;
          }
          return false;
        }).map(x => x.richLine);
      }
    } else {
      lines = DataDetailUtil.makeOtherTypeTextLines(pid, opt.filename, attachList);
    }

    return (
      (!!lines && lines.length > 0) ? (<div>
        { hasAppSoNameList && opt.value === '加载模块信息' && <Radio.Group
          style={{ marginBottom: '16px' }}
          optionType='button'
          options={SystemSoLinesFilterTypeOptions}
          value={systemSoLinesFilterType}
          onChange={(e) => setSystemSoLinesFilterType(e.target.value)}
        /> }
        { opt.value === 'anrTrace' && renderAnrTraceHeader(issueObj, crashId) }
        <table className={`${style.data_detail} ${style.data_detail_origin}`}>
          <colgroup>
            <col width="22%" />
            <col />
          </colgroup>
          <tbody>
            {renderLi(lines, false) }
          </tbody>
        </table>
      </div>) : <div className={style.no_content}>{opt.msg}</div>
    );
  }

  function renderAnrTraceHeader(issueObj, crashId) {
    const {
      isShowingRetracedAnrTrace,
      retracedAnrTrace,
    } = issueObj;

    return <div style={{ marginBottom: '16px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Radio.Group
          value={Number(!!isShowingRetracedAnrTrace)}
          onChange={e => {
            const v = !!e.target.value;
            dispatch(patchCurrentIssueInfo({ isShowingRetracedAnrTrace: v }));
          }}
        >
          <Radio.Button value={0}>{ze('还原前', 'Before Retrace')}</Radio.Button>
          <Radio.Button
            value={1}
            disabled={isNullish(retracedAnrTrace)}
          >{ze('还原后', 'After Retrace')} {isNullish(retracedAnrTrace) && ze('(未还原)', '(No Retrace Result)')}</Radio.Button>
        </Radio.Group>
        <Button
          style={{ marginLeft: '8px' }}
          type='primary'
          onClick={() => setIsRetraceAnrTraceModalVisible(true)}
        >{ ze('重试还原ANR Trace', 'Retrace ANR Trace') }</Button>
      </div>
      <RetraceAnrTraceModal
        crashId={crashId}
        visible={isRetraceAnrTraceModalVisible}
        onCancel={() => setIsRetraceAnrTraceModalVisible(false)}
        onOk={() => {
          setIsRetraceAnrTraceModalVisible(false);
          Modal.success({
            // 现在后台重还原有问题，还没重还原完就提前返回了，所以暂时提示用户过一会再刷新
            content: ze('已经成功请求重新还原，请稍等片刻后刷新页面查看。', 'Processing, please refresh the page later to view the result.'),
          });
        }}
      />
    </div>;
  }

  function makeStackParseResult() {
    let stackLineObjList = [];
    let stackBeforeSplit = '';
    let mobileOriginalStackBeforeSplit = '';
    if (stackLineDetailObjectList) {
      stackLineObjList = stackLineDetailObjectList;
    } else if (issue && isShowingMinidumpStack) {
      const {
        minidumpStackInfo,
      } = issue.toJS();
      const { hasMinidumpStack, minidumpStackText } = minidumpStackInfo || {};
      if (hasMinidumpStack) {
        stackBeforeSplit = minidumpStackText;
      }
    } else if (issue && !isShowingMinidumpStack) {
      const { crashDoc, stackType } = issue.toJS();
      if (crashDoc && crashDoc.crashMap) {
        const {
          callStack,
          rawStack,
          crashDetail, // 兼容nintendo switch用的
          linuxBreakpadStack,
          retraceCrashDetail,
          addCodeFrame,
        } = crashDoc.crashMap;

        mobileOriginalStackBeforeSplit = rawStack || callStack || crashDetail;

        if (stackType === 'original' && (rawStack || callStack)) {
          if (pcStackSource === PcStackSourceEnum.LINUX_BREAKPAD) {
            stackBeforeSplit = linuxBreakpadStack || '';
          } else {
            stackBeforeSplit = rawStack || callStack || crashDetail;
          }
        } else {
          stackBeforeSplit = retraceCrashDetail;
        }
      }
    }

    if (stackBeforeSplit) {
      stackLineObjList = StackUtil.splitStackIntoLines(stackBeforeSplit, pid)
        .map((line) => makeDetailObjectFromStackLine(line, exceptionType, pid, { isMinidumpStack: isShowingMinidumpStack }));
    }

    return {
      stackLineObjList,
      stackBeforeSplit,
      mobileOriginalStackBeforeSplit,
    };
  }

  // 渲染crash的信息
  function renderTableByType() {
    switch (dataType) {
      case 'stack': {
        let detailStackMemoryInfoList = [];
        let definitiveStackIndexList = null;

        if (issue) {
          const {
            nonMinidumpDetailStackMemoryInfoList,
            minidumpDetailStackMemoryInfoList,
          } = issue.toJS();
          if (isMinidump && minidumpDetailStackMemoryInfoList && Array.isArray(minidumpDetailStackMemoryInfoList)) {
            detailStackMemoryInfoList = minidumpDetailStackMemoryInfoList;
          } else if (!isMinidump && nonMinidumpDetailStackMemoryInfoList && Array.isArray(nonMinidumpDetailStackMemoryInfoList)) {
            detailStackMemoryInfoList = nonMinidumpDetailStackMemoryInfoList;
          }
        }

        let {
          stackLineObjList,
          stackBeforeSplit,
          mobileOriginalStackBeforeSplit,
        } = makeStackParseResult();
        let stack = stackLineObjList;

        if (issue && !isShowingMinidumpStack) {
          const { crashDoc } = issue.toJS();
          if (crashDoc && crashDoc.crashMap) {
            const {
              addCodeFrame,
            } = crashDoc.crashMap;
            // 堆栈分类关键行的下标
            if (isNotNullish(addCodeFrame)) {
              definitiveStackIndexList = addCodeFrame.map(x => Number(x.split(',')[0]));
            }
          }
        }

        let soNameToUuid = {}; // 还原后的堆栈没有uuid，需要从未还原堆栈里面提取出来
        if (stackBeforeSplit) {
          const { crashDoc, stackType } = issue.toJS();
          const { esMap, crashMap } = crashDoc || {};
          const {
            systemSoNameList,
          } = crashMap || {};
          const lowerSystemSoNameList = (systemSoNameList || []).map(x => (x || '').toLowerCase());

          const {
            retraceResultInfo,
            notInLoadModuleSoList,
            skipStackInfos,
            appSoNameList,
          } = esMap || {};
          const lowerAppSoNameList = (appSoNameList || []).map(x => (x || '').toLowerCase());

          if (PlatformUtil.isMobileOrMobileLike(pid)) {
            const mobileOriginStackObjList = mobileOriginalStackBeforeSplit
              ? mobileOriginalStackBeforeSplit.split('\n').map((line) => makeDetailObjectFromStackLine(line, exceptionType, pid))
              : null;
            if (mobileOriginStackObjList) {
              soNameToUuid = Object.assign({}, ...mobileOriginStackObjList.map((originStackObj) => {
                return { [originStackObj.soNameWithoutPath]: originStackObj.soUuid };
              }));
            }
          }

          // 填充extraInfo
          stack = stack.map((lineObj, i) => {
            const lineNumber = i + 1;
            // 填充哪些行是堆栈分类关键行的信息
            let extraInfo = {};
            if (isNotNullish(definitiveStackIndexList)) {
              extraInfo.isDefinitive = definitiveStackIndexList.includes(i);
            } else {
              extraInfo.isDefinitive = false;
            }
            extraInfo.isSkipped = !extraInfo.isDefinitive;



            // 填充是否匹配到了已上传过的符号表（移动端限定）
            let isSymbolFileUploaded = false;
            if (PlatformUtil.isMobileOrMobileLike(pid) && !symbolInfoLoading && isNotNullish(symbolInfoList)) {
              const { soNameWithoutPath: soName } = lineObj;
              const soUuid = soNameToUuid[soName];
              if (hasSymbolFileSoNameList.includes(soName) || hasSymbolFileUuidList.includes(soUuid)) {
                isSymbolFileUploaded = true;
              }
              extraInfo.isSymbolFileUploaded = isSymbolFileUploaded;
            }

            // 是否是系统模块
            extraInfo.isSystemModule = CrashModuleUtil.getIsSystemModule(lineObj.soNameWithoutPath, lowerSystemSoNameList, lowerAppSoNameList);

            // 填充堆栈行的还原状态
            if (retraceResultInfo) {
              const lowerKeyRetraceResultInfo = Object.keys(retraceResultInfo).reduce((acc, key) => {
                acc[key.toLowerCase()] = retraceResultInfo[key];
                return acc;
              }, {});

              const { soNameWithoutPath: soName } = lineObj;
              const lowerSoName = (soName || '').toLowerCase();
              const retraceStatus = !extraInfo.isSystemModule // 系统so不提示还原状态
                ? lowerKeyRetraceResultInfo[lowerSoName]
                : 0;
              extraInfo.stackLineRetraceStatus = retraceStatus;
            }

            // 填充被跳过的非分类关键行的跳过原因信息
            if (skipStackInfos) {
              const lineNumber = i + 1;
              const matchedSkipStackInfo = skipStackInfos.find(x => x.lineNum === lineNumber);
              extraInfo.nullableSkipStackInfo = matchedSkipStackInfo;
            }

            // 判断是否疑似外挂
            if (notInLoadModuleSoList && notInLoadModuleSoList.length) {
              const { soNameWithoutPath: soName } = lineObj;
              extraInfo.isSuspectedHacking = notInLoadModuleSoList.some((so) => so === soName)
            }

            extraInfo.isSymbolicationFailure = isNotNullish(extraInfo.stackLineRetraceStatus) && extraInfo.stackLineRetraceStatus !== 0;

            const hasLibName = !!lineObj.libname;
            extraInfo.needFormat = (!isOriginalStack) && (!!lineObj.filePath || hasLibName);

            const nullableDetailStackMemoryInfo = detailStackMemoryInfoList.find(x => {
              return x.lineNumber === lineNumber && (x.lineStack || '').trim() === (lineObj.rawLine || lineObj.line || '').trim();
            });

            extraInfo.nullableDetailStackMemoryInfo = nullableDetailStackMemoryInfo;

            if (Object.keys(extraInfo).length > 0) {
              return {
                ...lineObj,
                extraInfo,
              }
            }
            return lineObj;
          });

          stack = stack.filter(x => x.line && x.line.trim());
        }

        stack = enrichShrunkFilePathOnStackLineObj(stack);

        const hasDefinitiveLines = stack.some(x => x.extraInfo?.isDefinitive);
        const hasSymbolicationFailureLines = stack.some(x => x.extraInfo?.isSymbolicationFailure);
        const hasUnknownModuleLines = stack.some(x => x.libname === StackUtil.UNKNOWN_MODULE);
        const hasSuspectedHackingLines = stack.some(x => x.extraInfo?.isSuspectedHacking)

        const hasFormattedLines = stack.some(x => x.extraInfo?.needFormat);

        const allLinesInfo = {
          hasFormattedLines,
          hasDefinitiveLines,
          hasSymbolicationFailureLines,
          hasUnknownModuleLines,
          hasSuspectedHackingLines,
        };

        const isPcAndHasUnwindScan = isPc(pid, appId)
          && (stack || []).some(x => {
            const { nullableDetailStackMemoryInfo, isSystemModule } = x.extraInfo;
            const { unwindType } = nullableDetailStackMemoryInfo || {};
            return !isSystemModule && (unwindType === MinidumpUnwindTypeInt.SCAN || unwindType === MinidumpUnwindTypeInt.CFI_SCAN);
          });

        const symbolPageUrl = `/crash-reporting/preferences/dsyms/${appId}?pid=${pid}`;

        return <>
          { isPcAndHasUnwindScan && <div className={dataDetailStyle.unwindScanAlert}>
            <div style={{transform: 'rotate(180deg)', display: 'flex', alignItems:'center'}}><NoticeIcon /></div>
            <div>
              <span>{ ze('当前堆栈回溯基于栈扫描机制，回溯的堆栈可能与真实的堆栈有出入，请', 'Current stack trace was unwound based on stack scanning mechanism, in this case, the stack trace may be inaccurate. Please ') }</span>
              <a target='_blank' rel='noreferrer noopener' href={symbolPageUrl}>{ze('上传符号表', 'upload symbol files')}</a>
              <span>{ ze('以便得到精确的回溯堆栈。', ' to obtain accurate stack traces.') }</span>
            </div>
          </div> }
          <div
            className={dataDetailStyle.stackList}
          >
            {
              renderStackHeader(detailStackMemoryInfoList, isShowingMinidumpStack, allLinesInfo)
            }
          {
            stack.map((x, i) => renderStackLine(x, i, stack, detailStackMemoryInfoList, isShowingMinidumpStack, allLinesInfo))
          }</div>
        </>;
      }
      case 'log': {
        let log = [];
        let originSize = 0;
        let logTypeTmp;

        const hasNoSystemLog = !PlatformUtil.isSystemLogEnabled(pid);
        if (issue) {
          const {
            sysLogs, userLogs, logLevel: rawLogLevel, ideChecked, logKey,
          } = issue.toJS();

          const finalLogLevel = PlatformUtil.isLogLevelEnabled(pid, logType) ? rawLogLevel : 'verbose';

          if (logType === 'userLog' && userLogs) {
            log = userLogs.filter((userLog) => !!userLog);
          } else if (logType === 'sysLog' && sysLogs && !hasNoSystemLog) {
            log = sysLogs.filter((sysLog) => !!sysLog);
          }
          originSize = log.length;
          logTypeTmp = logType;
          log = handleLog(log, finalLogLevel, logKey, ideChecked);
        }

        let tip = ze('筛选无结果，请尝试改变筛选条件。', 'No filtered logs.');
        if (originSize <= 0) {
          tip = logTypeTmp === 'userLog'
            ? ze('暂无自定义日志信息', 'No custom log information available')
            : ze('暂无系统日志信息','No system log available');
        }
        const body = (
          log.length > 0 ? (
            <table className={`${style.data_detail} ${style.data_detail_log}`}>
              <tbody>
                {renderLog(log) }
              </tbody>
            </table>
          ) : <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, opacity: 0.6 }}>{tip}</div>
        );

        return <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {/* logType === 'sysLog' && SYSTEM_LOG_PLATFORM_ID_TO_DESC[pid] && <div
            style={{ color: '#89008C', fontSize: '12px' }}
          >
            * { SYSTEM_LOG_PLATFORM_ID_TO_DESC[pid] }
          </div> }
          { logType === 'userLog' && <div
            style={{ color: '#89008C', fontSize: '12px' }}
          >
            { ze('数据来源是SDK的API: printLog', 'The data source is SDK API: printLog.') }
          </div> */}
          <div>{ body }</div>
        </div>;
      }
      case '加载模块信息IOS':
      case '崩溃线程寄存器信息IOS':
      case '加载模块信息':
      case '崩溃线程寄存器信息':
      case 'FD信息':
      case 'anrMessage':
      case 'anrTrace':
      case 'otherMsg':
      case 'crashInfos':
      case 'consolelog':
      case 'meminfo':
        return othercase(FILE_OPTIONS[dataType]);
      default: return null;
    }
  }

  return (
    <div>
      { renderTableByType() }
    </div>
  );
};

DataDetail.propTypes = {
  reduxState: PropTypes.object,
  issue: PropTypes.object,
  // 如果传了stackLineDetailObjectList，那么就直接用这个作为堆栈信息，不去从issue取数据了
  // 这个prop是给堆栈重分类用的
  stackLineDetailObjectList: PropTypes.array,
  pid: PropTypes.string,
  style: PropTypes.object,
  dataType: PropTypes.string,
  exceptionType: PropTypes.string,
  // LGAME需求，混淆表信息
  obfuscationFileInfo: PropTypes.object,
  // PC堆栈来源。取值范围是PcStackSourceEnum
  pcStackSource: PropTypes.string,
  // 是否是还原后的堆栈。（影响显示的样式）
  isRetracedStack: PropTypes.bool,
  // 符号表信息，用于堆栈上提示是否缺符号表
  symbolInfoList: PropTypes.array,
  ignoreSymbolInfoList: PropTypes.array,
  symbolInfoLoading: PropTypes.bool,
  isShowingMinidumpStack: PropTypes.bool,
  logType: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(DataDetail);
