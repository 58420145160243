import React, { useEffect, useState } from 'react';
import scss from './ChatBotPopup.scss';
import { Spin } from 'antd';
import { CompressionUtil } from 'utils/compression-util';
import { ze } from 'utils/zhEn';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import { selectFrontendWebVolatileConfigValueByKey } from 'utils/selectors/selectors';

function getBotHost() {
  if (window.location.host.endsWith('crashsight.qq.com')) {
    return 'chatbot.crashsight.qq.com';
  } else if (window.location.host.endsWith('crashsight.woa.com')) {
    return 'chatbot.crashsight.woa.com';
  } else {
    return '';
  }
}

function makeBotUrl(chatBotPathname, usernameGzipBase64) {
  const botHost = getBotHost();
  if (!chatBotPathname || !botHost || !usernameGzipBase64) {
    return '';
  }
  if (!chatBotPathname.startsWith('/')) {
    chatBotPathname = '/' + chatBotPathname;
  }
  const query = new URLSearchParams({
    name: usernameGzipBase64,
  }).toString();
  return `https://${botHost}${chatBotPathname}?${query}`;
}

async function gzipAndBase64(str) {
  const uint8Array = await CompressionUtil.gzipCompress(str);
  return window.btoa(String.fromCharCode(...uint8Array));
}

const ChatBotPopup = (props) => {
  const {
    visible,
    onClose,
    username,
  } = props;

  const chatBotPathname = useSelector(state => selectFrontendWebVolatileConfigValueByKey(state, FrontendWebVolatileConfigKeys.CHAT_BOT_PATHNAME)) || '';

  const [openedOnce, setOpenedOnce] = useState(false);
  const [usernameGzipBase64, setUsernameGzipBase64] = useState('');

  useEffect(() => {
    if (visible) {
      setOpenedOnce(true);
    }
  }, [visible]);

  useEffect(() => {
    if (username) {
      gzipAndBase64(username).then(setUsernameGzipBase64);
    }
  }, [username]);

  const url = makeBotUrl(chatBotPathname, usernameGzipBase64);

  return openedOnce && <div
    className={scss.chatBotPopup}
    style={{ visibility: visible ? 'visible' : 'hidden' }}
  >
    <Spin spinning={true} className={scss.loadingPlaceholder}/>
    { !url && <div className={scss.chatBotIframeContainer}>ChatBot is not available.</div> }
    { url && <div
      className={scss.chatBotIframeContainer}
    ><iframe
      className={scss.chatBotIframeInner}
      src={url}
      frameBorder="0"
      allow="microphone">
    </iframe></div> }
    { url && <div className={scss.chatBotHeader}>
      <div>{ ze('CrashSight AI助手（Beta）', 'CrashSight AI Assistant (Beta)') }</div>
      <div style={{ flexGrow: 1 }}/>
      <CloseOutlined
        style={{ color: '#fff', cursor: 'pointer' }}
        onClick={() => onClose()}
      />
    </div> }
  </div>;
};

export default ChatBotPopup;
