import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Button,
  Input,
  InputNumber,
  Divider,
  Radio,
  Dropdown,
  Spin,
  Menu,
  Modal,
  Alert,
  Space,
  Checkbox,
  message, Tooltip
} from 'antd';
import scss from './CrashSightHeader.scss';
import Icon from '@ant-design/icons';
import { PLATFORM_ID, WXWORK_BOT_LAUNCH_URL } from 'utils/constants';
import { AndroidFilled, AppleFilled, WindowsFilled, DownOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { selectFrontendWebVolatileConfigValueByKey } from 'utils/selectors/selectors';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import HeaderClock from './HeaderClock';
import CanaryPopover from './CanaryPopover';
import { BillingTeamType, ENABLE_BILLING } from 'utils/constants/billing';
import { ze } from 'utils/zhEn';
import { DocsUtil } from 'utils/docs-util';
import NoticeButton from './NoticeButton';
import { PlatformIconUtil } from 'utils/platform-icon';
import { isNullish } from 'utils/nullish';
import { EnvUtil } from 'utils/env-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import { isLinux, isPc } from 'utils/platform';
import RestHelper from "utils/RestHelper";
import { ApiErrorCode } from 'utils/api-error-code';
import { RolePermission } from 'utils/constants/role-permission';
import { IoDocumentTextOutline } from "react-icons/io5";
import MyprojectIconNormal from 'svg/v2/newcs_dashboard_topbar_myproject_icon_normal.svg';
import ChatBotIcon from 'svg/v2/newcs_dashboard_topbar_chatbot_icon_normal.svg';
import LaugugeCh from 'svg/v2/newcs_dashboard_lauguge_ch.svg';
import LaugugeEn from 'svg/v2/newcs_dashboard_lauguge_en.svg';
import TopbarChIcon from 'svg/v2/newcs_dashboard_topbar_ch_icon_white.svg';
import TopbarEnIcon from 'svg/v2/newcs_dashboard_topbar_en_icon_white.svg';
import WecomIcon from 'svg/v2/newcs_dashboard_topbar_wecom_icon_normal.svg';
import CsLogo from 'svg/v2/newcs_dashboard_textlogo2.svg';
import PlatformMenu from './PlatformMenu';
import DropdownArrow from 'svg/v2/newcs_dashboard_dropdown_arrow.svg';
import SubModuleIdSelect from 'components/exception/ProductSummaryPage/SubModuleIdSelect';
import {
  routeReclick, getStatus, parseSearch, generateSearch,
} from 'utils/helper';
import { ServerAppSettings } from 'utils/server-app-settings';
import { useScroll } from 'ahooks';
import classnames from 'classnames';
import { actionGetAppList } from 'reducers/app/appActions';
import ChatBotPopup from 'components/top/ChatBotPopup';

const CrashSightHeader = ({
  reduxState,
  style,
  isAppView,
  isScreenshotVisit,
  currentUser,
  currentApp,
  appList,
  selectApp,
  actions,
}) => {
  const { i18n, t } = useTranslation();

  const ref = useRef(null);

  const dispatch = useDispatch();

  const isAppListInitDone = reduxState.app.get('init');
  const {
    appId,
    platformId,
    appGroupId,
    sameGroupAppList,
    teamType,
  } = currentApp.toJS();
  const isSuper = !!currentUser.get('isSuper');
  const userId = currentUser.get('userId');
  const username = currentUser.get('nickname');
  const isSuperAdmin = !!currentUser.get('isSuper');
  const rtx = currentUser.get('rtx');
  const location = reduxState.router.location;
  const {pathname} = location;
  const scroll = useScroll(document);

  const isInTencentLocalNetwork = reduxState.user.get('isInTencentLocalNetwork');

  const enableChatBotStr = useSelector(state => selectFrontendWebVolatileConfigValueByKey(state, FrontendWebVolatileConfigKeys.ENABLE_CHAT_BOT)) || '';
  const enableChatBot = (Number(enableChatBotStr) > 0 || enableChatBotStr.toLowerCase() === 'true');
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  const chatBotAvailable = isInTencentLocalNetwork && enableChatBot && EnvUtil.isChinaEnv();

  const isAppCreate = pathname.includes('/workbench/create-app') || false;
  const isAppListPage = pathname.includes('/workbench/apps');
  const isDashboardPage = pathname.startsWith('/crash-reporting/dashboard');

  const searchParams = reduxState.summary.get('searchParams');

  const appOptions = useMemo(() => {
    let options = appList.toJS()
      .filter((x) => x.isActive && !x.isDeleted)
      .map(x => ({
        ...x,
        label: <div className={scss.platformLabel}>
          <div className={scss.platformIcon}>{ PlatformIconUtil.getIcon(x.appId, x.platformId) }</div>
          <div >{ x.appName }</div>
        </div>,
        value: x.appId,
      }));
    const hasNonDemoApp = options.filter(x => !x.demoApp);
    if (hasNonDemoApp && !isSuperAdmin) {
      options = options.filter(x => !x.demoApp || x.appId === appId);
    }
    return options;
  }, [appId, appList, isSuperAdmin]);

  const langOptions = [
    { label: '中文', value: 'zh' },
    { label: 'English', value: 'en' },
  ];
  const currentLangLabel = (langOptions.find(x => x.value === i18n.language) || { label: t('HEAD.lang') }).label;

  const langDropdownMenu = <Menu>{
    langOptions.map(x => <Menu.Item key={x.value}>
      <div onClick={() => {changeLang(x.value)}}>{x.label}</div>
    </Menu.Item>)
  }</Menu>;

  function changeLang(lang) {
    // 因为目前有不少文本是一次性通过i18n计算出来的，变更语言后无法做到响应，所以通过保存语言设定+刷新页面实现语言切换
    Cookies.set('languageSet', lang);
    window.location.reload();
  }

  function reportUserEvent({event, url}) {
    reportEvent({
      tp1: '产品说明文档',
      tp6: url,
      action: EVENT_ACTIONS.CLICK,
    });
  }

  const userDropdownMenu = <Menu className={scss.userDropdownMenu}>
    <Menu.Item><Link to={`/account/profile`}>{ t('HEAD.info') }</Link></Menu.Item>
    { ENABLE_BILLING && <Menu.Item><Link to={`/billing`}>{ t('HEAD.billingAccount') }</Link></Menu.Item> }
    <Menu.Item><a href='/logout'>{ t('HEAD.logout') }</a></Menu.Item>
  </Menu>;

  const nowMillis = +new Date(); // 加个时间戳query参数，防止2.0用户点logo看到了缓存的1.0首页
  const logoClickHref = `/welcome?t=${nowMillis}`;

  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  const isEnableSubModuleId = !!serverAppSettings[ServerAppSettings.keys.enableSubModuleId];

  let containerClassName = scss.crashSightHeader;
  if (!isAppView) {
    if (isAppCreate || (isAppListPage && scroll && scroll.top < 72)) {
      containerClassName = [scss.crashSightHeader, scss.appCreate].join(' ');
    } else {
      containerClassName = [scss.crashSightHeader, scss.notAppCreate].join(' ');
    }
  }

  const innerDiv = <div
    className={containerClassName}
  >
    {/* { !isAppView && <a href={logoClickHref}><img
        style={{ display: 'block', height: '44px', objectFit: 'cover', marginLeft: '10px' }}
        src={`https://${EnvUtil.getCdnHost()}/images/CrashSightLogo.png`}
        alt='CrashSight'
      /></a> } */}
    { !isAppView && <a href={logoClickHref}>
        <span style={{ display: 'block', objectFit: 'cover', marginLeft: '66px' }}>
          {<CsLogo width="120" height="24" viewBox="0 0 120 24" style={{color:'#fff'}}/>}
        </span>
      </a> }
    {isAppView && <div className={scss.selectApp}>
      <Select
        className={scss.appSelect}
        getPopupContainer={() => document.body}
        popupClassName="cs-global-header-dropdown"
        value={appId}
        options={appOptions}
        bordered={false}
        suffixIcon={<DropdownArrow />}
        showSearch
        optionFilterProp='appName'
        onChange={(v, option) => { selectApp(option.appId, option.platformId) }}
        onDropdownVisibleChange={(open) => {
          if (open) {
            dispatch(actionGetAppList({ skipIfInitDone: true }));
          }
        }}
        dropdownRender={(originNode) => {
          return <Spin
            spinning={!isAppListInitDone}
          >{ originNode }</Spin>;
        }}
      />
      {appId && appGroupId
        && <PlatformMenu
          onSelectPlatformChange={selectApp}
          hideIfOnlyOneApp={true}
        />
      }
      {
        (isEnableSubModuleId && isDashboardPage) && <SubModuleIdSelect
          value={searchParams.get('subModuleId')}
          onChange={v => {
            const query = {
              subModuleId: v,
            };
            actions.pushState({
              pathname: location.pathname,
              search: generateSearch(location.search, query),
            });
          }}
        />
      }
    </div>}
    { !isScreenshotVisit && <React.Fragment>
      <div style={{ flexGrow: '1' }} />
      { /* <Dropdown placement="bottom" dropdownRender={()=>helperCenterMenu}>
        <div style={{ cursor: 'pointer' }}>
          {t('HEAD.帮助中心')}
          {isAppView ? <DownOutlined style={{ marginLeft: '4px'}}/> : <DownOutlined style={{color:'#fff', marginLeft: '4px'}}/>}
        </div>
      </Dropdown> */ }
      { /* <div><a
        href={DocsUtil.makeDocsUrl('/')}
        target='_blank'
        rel='noopener noreferrer'
        onClick={(e) => reportUserEvent({event: e, url: DocsUtil.makeDocsUrl('/')}) }
      >{ t('HEAD.说明文档') }</a></div> */}
      <CanaryPopover isSuperAdmin={isSuperAdmin}>
        <HeaderClock />
      </CanaryPopover>
      <Tooltip
        title={t('HEAD.说明文档')}
        placement='bottom'
      ><div><a
        style={{ display: 'flex' }}
        href={DocsUtil.makeDocsUrl('/')}
        target='_blank'
        rel='noopener noreferrer'
        onClick={(e) => reportUserEvent({event: e, url: DocsUtil.makeDocsUrl('/')}) }
      ><IoDocumentTextOutline size={24} className={classnames(scss.docsIcon, !isAppView && scss.docsIconNonAppView)}/></a></div></Tooltip>
      <Tooltip title={t('HEAD.products')} placement='bottom'><div >
        <Link to='/workbench/apps'>
          {
            isAppView ? <div className={scss.myproject}><MyprojectIconNormal /></div>
            : <div className={`${scss.myproject} ${scss.darkBackground}`}><MyprojectIconNormal /></div>
          }
        </Link>
      </div></Tooltip>

      {isAppView && <div><NoticeButton appId={appId}/></div>}
      {rtx && <Tooltip title={ze('联系CrashSight小助手', 'Contact Us Via WeCom')} placement='bottom'><div><a href={WXWORK_BOT_LAUNCH_URL}>
        <span style={{display:'flex',alignItems:'center'}}>{isAppView ? <WecomIcon /> : <span className={scss.WecomIconIsAppView}>{<WecomIcon />}</span>}</span>
      </a></div></Tooltip> }

      { chatBotAvailable && <Tooltip title={ze('AI助手', 'AI Assistant')} placement='bottom'><ChatBotIcon
        className={classnames(scss.docsIcon, !isAppView && scss.docsIconNonAppView)}
        onClick={() => setIsChatBotVisible(!isChatBotVisible)}
      /></Tooltip> }

      <div style={{ cursor: 'pointer' }}>
        <Dropdown overlay={langDropdownMenu} getPopupContainer={() => document.body}
        overlayClassName="cs-global-header-dropdown">
          { isAppView
              ? <div className={scss.langDropdownContainer}>
                  <div style={{width:'22px',height:'22px'}}> {currentLangLabel === '中文' ? <LaugugeCh/> : <LaugugeEn/>}</div>
                  <DownOutlined/>
                </div>
              : <div className={scss.langDropdownContainer}>
                  <div className={scss.langDropdownMenu}>{currentLangLabel === '中文' ? <TopbarChIcon/> : <TopbarEnIcon/>} </div>
                  <DownOutlined style={{color:'#fff'}}/>
               </div>
          }
        </Dropdown>
      </div>
      {/* <div><Link to='/workbench/ladder-page' >{ ze('崩溃指数风向标','Crash Ranking') }</Link></div> */ /** 风向标没用，全部去掉 */}
      {/* <div><a
        href={DocsUtil.makeDocsUrl('/')}
        target='_blank'
        rel='noopener noreferrer'
      >{ t('HEAD.说明文档') }</a></div> */}
      <div className={isAppView ? '' : 'userDropdownMenuAppView'}>
        <Dropdown overlay={userDropdownMenu} getPopupContainer={() => document.body}
        overlayClassName="cs-global-header-dropdown">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <UserOutlined style={{ fontSize: '20px' }}/> */}
            <div className={isAppView ?'appManageUserDropdown':'userDropdown'} >{username}</div>
            {isAppView ? <DownOutlined style={{ marginLeft: '4px' }}/> : <DownOutlined style={{color:'#fff', marginLeft: '4px' }}/>}
          </div>
        </Dropdown>
      </div>
    </React.Fragment>}

    <ChatBotPopup
      visible={isChatBotVisible}
      onClose={() => setIsChatBotVisible(false)}
      username={username}
    />
  </div>;

  const containerStyle = {...style};
  if (isAppView) {
    if (scroll?.top) {
      Object.assign(containerStyle, {
        boxShadow: '0px 1px 10px 0px #E9E9E9',
      });
    } else {
      Object.assign(containerStyle, {
        borderBottom: '1px solid rgba(128, 121, 151, 0.15)',
      });
    }
  }

  return <div
    ref={ref}
    style={containerStyle}
  >{ innerDiv }</div>;
};

CrashSightHeader.propTypes = {
  isAppView: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  currentApp: PropTypes.object.isRequired,
  appList: PropTypes.object.isRequired,
  selectApp: PropTypes.func.isRequired,
};

const mapStateToProps = (state, router) => ({
  reduxState: state,
});

export default connect(mapStateToProps)(CrashSightHeader);
